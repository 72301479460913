import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
// import { contactList } from '../types';

export const getContactList = async (payload={})=> {
  const response = await axios.post(`/api/v1/user/enquiry`, payload)
   const data = response.data;
  return data;
};
type QueryFnType = typeof getContactList;
type UseContactListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useContactList = ({  config }: UseContactListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getContactList(),
    ...config,
  });
};


