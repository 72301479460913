import storage from '@/utils/storage';
import snsWebSdk from '@sumsub/websdk';
import axios from 'axios';
import { useEffect, useState } from 'react';
export default function KycFaceVerification() {
    const [sumSubToken, setSumSubToken] = useState({ token: "", userId: "" })
    const [userDetail, setUserDetail] = useState({ userId: "", email: "", mobileNumber: 0 })
    const [webHook, setWebHook] = useState()
    const [type, setType] = useState("")
    const [accessToken, setAccessToken] = useState({applicantId:"",externalUserId:""})
    const [applicantId, setApplicantId] = useState()
    const launchWebSdk = async (accessToken, applicantEmail, applicantPhone) => {
        let snsWebSdkInstance = snsWebSdk.init(
            accessToken,
            () => getNewAccessToken()
        )
            .withConf({
                lang: 'en',
                email: applicantEmail,
                phone: applicantPhone,
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.onStepCompleted', (payload) => {
       
            })
            .on('idCheck.onError', (error) => {

            })
            .onMessage((type, payload) => {
          
                // getWebHook()
                setType(type)
            })
            .build();
        snsWebSdkInstance.launch('#sumsub-websdk-container')

    }
    const getaccessTokenDetail = async () => {
        let token = storage.getToken();
        try {
            let response = await axios
                .post(
                    `${process.env.API_URL_PREFIX}/api/v1/user/sumsubAPI?fileType=getAccessTokenApp`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
            setAccessToken({applicantId:response.data.data.id,externalUserId:response.data.data.externalUserId})
        }
        catch (error) {

        }
    };
    const updateApplicantId = async () => {
        let token = storage.getToken();
        try {
            let formData = new FormData();    //formdata object

            formData.append('applicantId', accessToken.applicantId);
            formData.append('externalUserId', accessToken.externalUserId); 
            let responseProfile = await axios
                .patch(
                    `${process.env.API_URL_PREFIX}/api/v1/user/profile`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
            setApplicantId(responseProfile.data.data)
        }
        catch (error) {
            console.log("token error", error);
        }
    };
    function getNewAccessToken() {
        return Promise.resolve(sumSubToken?.token)
    }
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        getaccessTokenDetail()
    },[type]);
    useEffect(() => {
        updateApplicantId()
    }, [accessToken]);
    const getWebHook = async () => {
        let token = storage.getToken();
        try {
            let webHookDetails = await axios
                .post(
                    `${process.env.API_URL_PREFIX}/api/v1/user/webhook`, {}
                )
            setWebHook(webHookDetails.data)
        }
        catch (error) {
            console.log("token error", error);
        }
    };

    const getData = async () => {
        let token = storage.getToken();
        try {
            let response = await axios
                .post(
                    `${process.env.API_URL_PREFIX}/api/v1/user/sumsubAPI?fileType=createAccessToken`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
            setSumSubToken(response.data.data)
            let userDetails = await axios
                .get(
                    `${process.env.API_URL_PREFIX}/api/v1/user/userId`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                )
            setUserDetail(userDetails.data.data);
        }
        catch (error) {
            console.log("token error", error);
        }
    };
    return (
        <>
            <html>
                <head>
                    <title>WebSDK CDN Example</title>
                </head>
                <body>
                    <script src="https://static.sumsub.com/idensic/static/sns-websdk-builder.js"></script>
                    <div id="sumsub-websdk-container"></div>
                </body>
            </html>
            <div>
                <button onClick={() => launchWebSdk(sumSubToken?.token, userDetail?.email, userDetail?.mobileNumber)} className="button-7" role="button"
                    style={{ marginTop: "42px", padding: "15px", fontSize: "16px", marginLeft: "40px" }}>KYC Verification</button>
            </div>

        </>
    )
}

