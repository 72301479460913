import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { Landing } from '@/features/misc';
import { useAuth } from '@/lib/auth';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { Register } from '@/features/auth/routes/Register';
import { useNavigate } from 'react-router-dom';
import KYCform from '@/features/KYCform/components/KYCform';
import Licensing from '@/features/staticPages/component/Licensing';
import Donation from '@/features/staticPages/component/Donation';
import TermsOfUse from '@/features/staticPages/component/TermsOfUSe';
import PrivacyNotice from '@/features/staticPages/component/PrivacyNotice';
import Rules from '@/features/staticPages/component/Rules';
import MainAbout from '@/features/AboutUS/Components/MainAbout';
import ContactUS from '@/features/ContactUs/component/contactUs';
import ICO from '@/features/ICO/components/ico';
import { FaqMain } from '@/features/Faq/routes/FaqMain';
import Promotions from '@/features/promotions/components/promotions';
import { AuthRoutes } from '@/features/auth';
import PromotionDetail from '@/features/promotions/components/promotionDetail';
import PromotionFreeTicket from '@/features/promotions/components/promotionFreeTkt';
import Kyc from '@/features/staticPages/component/Kyc';
import ResponsibleGaming from '@/features/staticPages/component/responsibleGaming';




import  { redirect } from 'react-router-dom'
export const AppRoutes = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const commonRoutes = [
    {
      path: '/auth/*',
      element: <AuthRoutes />,
    },
    {
      path: '/licensing',
      element: <Licensing />,
    },
    { path: '/aboutus', element: <MainAbout /> },
    { path: '/Faq', element: <FaqMain /> },
    {
      path: '/donations',
      element: <Donation />,
    },
    {
      path: '/rules',
      element: <Rules />,
    },
    {
      path: '/termsofUse',
      element: <TermsOfUse />,
    },
    {
      path: '/privacyNotice',
      element: <PrivacyNotice />,
    },
    {
      path: '/KYCform',
      element: <KYCform />,
    },
    {
      path: '/contactUs',
      element: <ContactUS />,
    },
    {
      path: '/ICO',
      element: <ICO />,
    },
    {
      path: '/PromotionsDetail',
      element: <PromotionDetail />
    },
    {
      path: '/KYC',
      element: <Kyc/>
    },
    {
      path: '/responsibleGaming',
      element: <ResponsibleGaming/>
    },
  ];

  // const routes = publicRoutes;
  // console.log(auth,"authhhh")

  
 
   const routes = auth.user ? protectedRoutes : publicRoutes;

   const allRoutes = [...routes, ...commonRoutes];

   const element = useRoutes(allRoutes);
 

   if (!auth.user && location.pathname.startsWith('/app') && location.pathname !== '/auth/*') {
    return <Navigate to="/auth/login" replace />;
  }
 

  return <>{element}</>;
};