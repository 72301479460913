import React from 'react';
import { Box, Typography } from '@mui/material';
import LandscapeIcon from '@mui/icons-material/ScreenRotationAlt';

const LandscapeModeComponent = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(5, 5, 24, 1)', 
        color: '#ffffff',
        textAlign: 'center',
      }}
    >
     
      <LandscapeIcon sx={{ fontSize: 100, mb: 2 }} />
      
     
      <Typography variant="h4" sx={{ mb: 1 }}>
        Please rotate your device
      </Typography>

    
      <Typography variant="body1">
        We don't support portrait mode yet. Please switch to landscape mode for the best experience.
      </Typography>
    </Box>
  );
};

export default LandscapeModeComponent;
