import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import { makeStyles } from "@material-ui/core/styles";
import "../../Dashboard/components/owl-crousel.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import upcomingImg from "@/assets/logo.png";
import { Key } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import yellowt from "@/assets/yellow-t.png";
import whitet from "@/assets/white-t.svg";
import Swal from "sweetalert2";

const useStyles = makeStyles(
  (theme: { breakpoints: { down: (arg0: string) => any } }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      backgroundColor: "#FFFFFF1A",
      borderRadius: "15px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    media: {
      width: "40% !important",
      marginRight: "1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: "1rem",
        marginRight: 0,
      },
    },
  })
);

function UpcomingLotteryCrousel({ dataRecent }) {
  const classes = useStyles();
  const [result, setResult] = React.useState(dataRecent);
  let navigate = useNavigate();
  const options = {
    loop: false,
    margin: 10,
    dots: false,
    nav: true,
    navText: [
      '<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.4584 21.6418C14.1174 21.6418 16.6676 20.5778 18.5478 18.6837C20.428 16.7897 21.4843 14.2209 21.4843 11.5423C21.4843 8.86377 20.428 6.29493 18.5478 4.40091C16.6676 2.50689 14.1174 1.44284 11.4584 1.44284C8.79933 1.44284 6.24919 2.50689 4.36896 4.40091C2.48873 6.29493 1.43243 8.86377 1.43243 11.5423C1.43243 14.2209 2.48873 16.7897 4.36896 18.6837C6.24919 20.5778 8.79933 21.6418 11.4584 21.6418ZM11.4584 5.34058e-05C14.4973 5.34058e-05 17.4117 1.21611 19.5606 3.3807C21.7094 5.5453 22.9166 8.48112 22.9166 11.5423C22.9166 14.6035 21.7094 17.5393 19.5606 19.7039C17.4117 21.8685 14.4973 23.0846 11.4584 23.0846C8.41947 23.0846 5.50502 21.8685 3.35619 19.7039C1.20736 17.5393 0.000152588 14.6035 0.000152588 11.5423C0.000152588 8.48112 1.20736 5.5453 3.35619 3.3807C5.50502 1.21611 8.41947 5.34058e-05 11.4584 5.34058e-05ZM16.4713 10.8209C16.6613 10.8209 16.8434 10.8969 16.9777 11.0322C17.112 11.1675 17.1875 11.351 17.1875 11.5423C17.1875 11.7336 17.112 11.9171 16.9777 12.0524C16.8434 12.1877 16.6613 12.2637 16.4713 12.2637L8.17416 12.2637L11.2493 15.3599C11.3837 15.4954 11.4593 15.6791 11.4593 15.8707C11.4593 16.0622 11.3837 16.246 11.2493 16.3814C11.1148 16.5169 10.9324 16.593 10.7422 16.593C10.5521 16.593 10.3697 16.5169 10.2352 16.3814L5.93838 12.0531C5.87169 11.9861 5.81877 11.9065 5.78267 11.8188C5.74657 11.7312 5.72798 11.6372 5.72798 11.5423C5.72798 11.4474 5.74657 11.3535 5.78267 11.2658C5.81877 11.1782 5.87169 11.0986 5.93838 11.0316L10.2352 6.70323C10.3697 6.56777 10.5521 6.49167 10.7422 6.49167C10.9324 6.49167 11.1148 6.56777 11.2493 6.70323C11.3837 6.83869 11.4593 7.02241 11.4593 7.21397C11.4593 7.40554 11.3837 7.58926 11.2493 7.72472L8.17416 10.8209L16.4713 10.8209Z" fill="white"/></svg>',
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.4554 21.6417C9.7963 21.6417 7.24617 20.5777 5.36594 18.6837C3.48571 16.7897 2.42941 14.2208 2.42941 11.5423C2.42941 8.86371 3.48571 6.29487 5.36594 4.40085C7.24617 2.50683 9.7963 1.44278 12.4554 1.44278C15.1144 1.44278 17.6645 2.50683 19.5448 4.40085C21.425 6.29487 22.4813 8.86371 22.4813 11.5423C22.4813 14.2208 21.425 16.7897 19.5448 18.6837C17.6645 20.5777 15.1144 21.6417 12.4554 21.6417ZM12.4554 -7.62939e-06C9.41644 -7.62939e-06 6.502 1.21605 4.35316 3.38064C2.20433 5.54524 0.997128 8.48106 0.997128 11.5423C0.997128 14.6035 2.20433 17.5393 4.35316 19.7039C6.502 21.8685 9.41644 23.0845 12.4554 23.0845C15.4943 23.0845 18.4087 21.8685 20.5575 19.7039C22.7064 17.5393 23.9136 14.6035 23.9136 11.5423C23.9136 8.48106 22.7064 5.54524 20.5575 3.38064C18.4087 1.21605 15.4943 -7.62939e-06 12.4554 -7.62939e-06ZM7.44238 10.8209C7.25245 10.8209 7.07029 10.8969 6.93599 11.0322C6.80169 11.1674 6.72624 11.3509 6.72624 11.5423C6.72624 11.7336 6.80169 11.9171 6.93599 12.0524C7.07029 12.1877 7.25245 12.2637 7.44238 12.2637L15.7396 12.2637L12.6645 15.3599C12.53 15.4953 12.4544 15.679 12.4544 15.8706C12.4544 16.0622 12.53 16.2459 12.6645 16.3814C12.7989 16.5168 12.9813 16.5929 13.1715 16.5929C13.3617 16.5929 13.544 16.5168 13.6785 16.3814L17.9753 12.053C18.042 11.986 18.095 11.9064 18.1311 11.8187C18.1672 11.7311 18.1857 11.6372 18.1857 11.5423C18.1857 11.4474 18.1672 11.3534 18.1311 11.2658C18.095 11.1781 18.042 11.0985 17.9753 11.0315L13.6785 6.70317C13.544 6.56771 13.3617 6.49161 13.1715 6.49161C12.9813 6.49161 12.7989 6.56771 12.6645 6.70317C12.53 6.83862 12.4544 7.02234 12.4544 7.21391C12.4544 7.40548 12.53 7.5892 12.6645 7.72466L15.7396 10.8209L7.44238 10.8209Z" fill="white"/></svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1400: {
        items: 5,
      },
      1800: {
        items: 6,
      },
    },
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#3C37FF",
    borderRadius: "10px",
  };
  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var button = {
    background: "rgba(255, 255, 255, 0.13)",
    borderRadius: "15.507px",
    color: "#FFFFFF",
  };
  console.log(result);

  React.useEffect(() => {
    setResult(result);
  }, [result]);

  const handleSoldOut = (soldStatus, cardId) => {
    if (!soldStatus) {
      navigate(`/app/Lotteries/${cardId}`);
    } else {
      Swal.fire({
        icon: "error",
        text: "Tickets are sold-out, please buy tickets in another lottery",
        background: "black",
        color: "#ffff",
      });
    }
  };

  return (
    <>
      {result?.upCommingLotteries?.length > 0 ? (
        <Typography
          component="h4"
          variant="h4"
          sx={{
            zIndex: "999999999",
            ml: { xs: "10px", md: "0px" },
            mt: { xs: "21px", md: "41px" },
          }}
        >
          Available Lotteries
        </Typography>
      ) : (
        ""
      )}
      {result?.upCommingLotteries?.length > 0 ? (
        <>
          <OwlCarousel
            className="owl-theme"
            {...options}
            style={{ marginTop: "20px" }}
          >
            {result?.upCommingLotteries.map(
              (card: any, index: Key | null | undefined) => (
                <Grid container key={index} spacing={3}>
                  <Grid item key={index} xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Card
                      style={content}
                      onClick={() => handleSoldOut(card?.soldStatus, card?._id)}
                      sx={{
                        width: "190px",
                        "&:hover": {
                          backgroundColor: "#fff !important",
                          color: "black !important",
                          boxShadow: 20,
                          minHeight: "182px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          cursor: "pointer",
                          backgroundPosition: "50% 0%",
                          "& div div .MuiDivider-root": {
                            borderBottomColor: "black !important", // Change the color of the Divider component
                          },
                          "& div h5": {
                            backgroundColor: "#fff !important",
                            color: "black !important",
                          },
                          "& div div .MuiCardContent-root": {
                            backgroundColor: "#fff !important",
                            color: "black !important",
                          },
                          "& div div div div.MuiPaper-root": {
                            border: "1.23px solid #00000038 !important",
                            background: "#fff",
                            boxShadow: "none",
                          },
                          "& div": {
                            background: "rgba(255, 255, 255, 0.13)",
                            borderRadius: "15.507px",
                            color: "#FFFFFF",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "50% 0%",
                            borderTopLeftRadius: "5px",
                            borderTopRightRadius: "5px",
                            borderBottomLeftRadius: "0px !important",
                            borderBottomRightRadius: "0px !important",
                          },
                          "& .MuiCardActions-root > .MuiButtonBase-root-MuiButton-root":
                            {
                              background:
                                "rgba(255, 255, 255, 0.13) !important",
                              borderRadius: "15.507px",
                              color: "black",
                            },
                        },
                      }}
                    >
                      <div style={{ position: "relative" }}>
                        <CardMedia
                          sx={{
                            height: 180,
                            backgroundPosition: "50% 0%",
                          }}
                          image={
                            card.image
                              ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${card.image}`
                              : upcomingImg
                          }
                          title="lottery name"
                        />
                        {card?.soldStatus ? (
                          <>
                            <Chip
                              label="Sold Out"
                              sx={{
                                position: "absolute",
                                transform: "rotate(45deg)",
                                top: "2px",
                                right: "-31px",
                                backgroundColor: "#fff",
                                color: "Red",
                                borderRadius: "4px",
                                padding: "4px 17px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                zIndex: 1,
                              }}
                            />
                            {/* <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 0,
            height: 0,
            borderTop: '20px solid transparent', // Customize the size of the slanting line
            borderLeft: '20px solid #FF0000', // Match the background color of the label
            zIndex: 0,
          }}
        /> */}
                          </>
                        ) : (
                          ""
                        )}
                        {card?.soldStatus ? (
                          <>
                            <Chip
                              label="Sold Out"
                              sx={{
                                position: "absolute",
                                transform: "rotate(45deg)",
                                top: "2px",
                                right: "-31px",
                                backgroundColor: "#fff",
                                color: "Red",
                                borderRadius: "4px",
                                padding: "4px 17px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                zIndex: 1,
                              }}
                            />
                            {/* <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 0,
            height: 0,
            borderTop: '20px solid transparent', // Customize the size of the slanting line
            borderLeft: '20px solid #FF0000', // Match the background color of the label
            zIndex: 0,
          }}
        /> */}
                          </>
                        ) : (
                          ""
                        )}
                        {card?.numTktUserPurchase > 0 ? (
                          <>
                            {" "}
                            <Chip
                              label={card?.numTktUserPurchase + " Tickets"}
                              sx={{
                                position: "absolute",
                                transform: "rotate(45deg)",
                                top: "7px",
                                right: "-42px",
                                backgroundColor: "blue",
                                color: "#FFFFFF",
                                borderRadius: "4px",
                                padding: "8px 26px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                zIndex: 1,
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                width: 0,
                                height: 0,
                                borderTop: "20px solid transparent", // Customize the size of the slanting line
                                borderLeft: "20px solid #FF0000", // Match the background color of the label
                                zIndex: 0,
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <CardContent
                        style={center}
                        sx={{
                          flexDirection: "column",
                          pb: "0",
                          height: "85px",
                          mt: "10px",
                        }}
                      >
                        {/* <Typography component="h5" variant="h5" gutterBottom>
                {card.lotteryName}
            </Typography> */}
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{ fontSize: "16px", display: "flex" }}
                        >
                          <img
                            style={{
                              height: "auto",
                              width: "20px",
                              marginRight: "10px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                            src={yellowt}
                          />{" "}
                          {card.ticketPrice}
                        </Typography>
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{ fontSize: "16px", display: "flex" }}
                        >
                          <Countdown
                            date={new Date(`${card?.endDate}T${card?.endTime}`)}
                          />
                        </Typography>
                        <Box
                          onClick={() =>
                            handleSoldOut(card?.soldStatus, card?._id)
                          }
                          sx={{
                            px: "3px",
                            py: "3.73px",
                            marginBottom: "19px !important",
                            background: "rgba(255, 255, 255, 0.5)",
                            borderRadius: "15.507px",
                            color: "#3C37FF !important",
                            width: "115px !important",
                            textAlign: "center",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                        >
                          Buy Ticket
                        </Box>
                        <Typography
                          component="h5"
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontSize: "12px !important",
                            display: "flex",
                            textAlign: "center",
                            mb: "5px",
                          }}
                        >
                          {
                            new Date(card?.lotteryDrawDate)
                              .toISOString()
                              .split("T")[0]
                          }{" "}
                          {new Date(card?.lotteryDrawDate)
                            .toISOString()
                            .split("T")[1]
                            .substring(0, 5) + "UTC"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )
            )}
          </OwlCarousel>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default UpcomingLotteryCrousel;
