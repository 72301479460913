import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Input,
  LinearProgress,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import promotionsBackground from "@/assets/promotionsBackground.png";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import promotionSecond from "@/assets/promotionSecond.png";
import promotionimg from "@/assets/promotionimg.png";
import { theme } from "@/components/Elements/theme";
import { url } from "inspector";
import HomeScreenImg from "@/assets/earth.jpg";
import { BuyCoin } from "./buyCoin";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
import Swal from "sweetalert2";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Countdown from "react-countdown";
import storage from "@/utils/storage";
import { useBuyCoins } from "../api/buyCoins";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

// import { UsePromotionByReferList } from "../api/getPromotionByRefer";
// import { UsePromotionByBuyList } from "../api/getPromotionByBuy";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
    [theme.breakpoints.down("md")]: {
      height: "160px",
      marginTop: "10px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridThird: {
    background: `url(${promotionSecond})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "center",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    },
  },
}));

export default function ICO() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [startTimeIco, setstartTimeIco] = useState(0);
  const [endTimeIco, setendTimeIco] = useState("");
  const [formValues, setFormValues] = useState({ no_of_token: 0 });
  const [amount, setTotalAmount] = useState(0);
  const [difference, setDifference] = useState(0);
  const [file, setFile] = useState("");
  const [tokenPrice, setTokenPrice] = useState(0);
  const handleSubstractCount = () => {
    if (count >= 1) {
      let countValue = count;
      setCount(count - 1);
      setFormValues({
        ...formValues,
        no_of_token: countValue - 1,
      });
      let ticketPrice: any = 10; // TO BE UPDATED FROM API
      let amount1 = formValues.no_of_token - 1;
      setTotalAmount(amount1);
    } else return;
  };
  const calculateTimeLeft = () => {
    const diff = +new Date(endTimeIco) - +new Date();
    setDifference(diff);
  };
  useEffect(() => {
    console.log("isLoading", loading);
    if (loading) {
      showLoadingPopup();
    }
  }, [loading]);
  useEffect(() => {
    getFile();
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      calculateTimeLeft();
    }, 1000);
    return () => clearTimeout(timer);
  }, [difference]);
  const getFile = async () => {
    const response = await axios.get(
      `${process.env.API_URL_PREFIX}/api/v1/user/icoList`
    );
    setstartTimeIco(response?.data.data[0].startInvTimeStamp);
    setendTimeIco(response?.data.data[0].endInvTimeStamp);
    setTokenPrice(response?.data.data[0].tokenPrice);
    setFile(
      `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${response?.data.data[0].icoDoc}`
    );
  };

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    background: "rgba(5, 5, 24, 0.8)",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
    margin: "10px 30px", 
  };
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));
  const handleTenSubstractCount = () => {
    if (count >= 10) {
      let countValue = count;
      setCount(count - 10);
      setFormValues({
        ...formValues,
        no_of_token: countValue - 10,
      });
      let ticketPrice: any = 10; // TO BE UPDATED FROM API
      let amount1 = formValues.no_of_token - 10;
      setTotalAmount(amount1);
    } else return;
  };
  const navigate = useNavigate();
  //   const dataValueByRefer = UsePromotionByReferList({});
  //   const dataValueByBuy = UsePromotionByBuyList({});
  const auth = useAuth();
  const createPaymentMutation = useBuyCoins({ amount });

  console.log("count", count);
  var sweet_loader =
    '<div class="circular-progress"><div class="circle"></div><div class="mask full"><div class="fill"></div></div><div class="mask half"><div class="fill"></div><div class="fill fix"></div></div></div>';

  const handleCount = () => {
    let countValue = count;
    setCount(count + 1);
    setFormValues({
      ...formValues,
      no_of_token: countValue + 1,
    });
    let ticketPrice: any = 10; // TO BE UPDATED FROM API
    let amount1 = formValues.no_of_token + 1;
    setTotalAmount(amount1);
  };
  const createCryptoPayment = async (event) => {
    event.preventDefault();
    let token = storage.getToken();
    try {
      setLoading(true);
      console.log("here");
      if (token != null) {
        await createPaymentMutation
          .mutateAsync({
            data: {
              amount,
            },
          })
          .then((res) => {
            Swal.fire({
              background: "black",
              color: "#ffff",
              icon: "success",
              title: "Success!",
              text: "Buy G2F coin successfully",
            });
          });
      } else {
        navigate("/auth/login");
      }
      console.log("here2");
      setLoading(false);
      // window.location.reload();
    } catch (error: any) {
      console.log("here3");
      setLoading(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: `${error?.response?.data?.message}`,
      });
    }
  };
  const handleTenCount = () => {
    let countValue = count;
    setCount(count + 10);
    setFormValues({
      ...formValues,
      no_of_token: countValue + 10,
    });
    let ticketPrice: any = 10; // TO BE UPDATED FROM API
    let amount1 = formValues.no_of_token + 10;
    setTotalAmount(amount1);
  };
  const showLoadingPopup = () => {
    Swal.fire({
      text: "We are processing your purchase, one moment please",
      allowOutsideClick: false,
      showCancelButton: false,
      html: sweet_loader,
      customClass: "buy-now-pop-up",
      background: "black",
      color: "#ffff",
      showLoaderOnConfirm: true,
      timer: 1000000,
      showConfirmButton: false,
      timerProgressBar: true,
    }).then(
      function () {},
      // handling the promise rejection
      function (dismiss) {
        if (dismiss === "timer" && !loading) {
        }
      }
    );
  };
  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Open link in new tab
    link.setAttribute("rel", "noopener noreferrer"); // Security best practice
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {auth.user ? <Header /> : <HeaderValue />}
      <Box
        sx={{
          background: `url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          paddingTop: "110px",
          "@media screen and (max-width:767px)":{
           paddingTop:"80px"
          },
          "@media screen and (max-width:676px)":{
           paddingTop:"40px"
          },
        }}
      >
        <Grid container justifyContent="space-around">
          <Grid
            item
            xs={12}
            md={12}
            className={classes.innerGrid}
            sx={{ background: "none !important" }}
          >
            <Grid item xs={12} justifyContent={"space-around"} p={2}>
              <Typography
                variant="h2"
                align="center"
                sx={{
                  "@media screen and (max-width:992px)":{
                    fontSize:"32px",
                  }, 
                  "@media screen and (max-width:767px)":{
                    fontSize:"28px",
                  }, 
                }}
              >
                ICO
              </Typography>
            </Grid>
            {/* <BuyCoin /> */}
            <Grid item xs={12} md={12}>
              <Box sx={{ p: { xs:"0px", sm: "28px", md: "30px" } }}>
                <Box
                  sx={{
                    backgroundColor: "rgba(5, 5, 24, 0.8)",
                    borderRadius: "10px",
                    padding: {xs:"20px 10px", sm:"30px", md:"40px"},
                  }}
                >
                  <Grid
                    container
                    display={"flex"}
                    direction={"row"}
                    spacing={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ marginBottom: "40px" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      sx={{ color: "white" }}
                    >
                      <Box className="buy-box">
                        <h1 style={{ textAlign: "center" }}>
                          PRE-SALE ROUND 1
                        </h1>
                        <Box className="w-100 d-flex flex-column align-items-center justify-content-start text-center walletBox_top p-3">
                          <div className="timer">
                            <div className="time-card text-center">
                              <div className="value">
                                {Math.floor(difference / (1000 * 60 * 60 * 24))}
                              </div>
                              <div className="indicator">DAY</div>
                            </div>
                            :
                            <div className="time-card text-center">
                              <div className="value">
                                {Math.floor(
                                  (difference / (1000 * 60 * 60)) % 24
                                )}
                              </div>
                              <div className="indicator">HRS</div>
                            </div>
                            :
                            <div className="time-card text-center">
                              <div className="value">
                                {Math.floor((difference / 1000 / 60) % 60)}
                              </div>
                              <div className="indicator">MINS</div>
                            </div>
                            :
                            <div className="time-card text-center">
                              <div className="value">
                                {Math.floor((difference / 1000) % 60)}
                              </div>
                              <div className="indicator">SEC</div>
                            </div>
                          </div>
                          <Typography variant="h1">
                            BUY NOW BEFORE PRICE INCREASES!
                          </Typography>
                          <div className="progress">
                            <div className="bar" style={{ width: "97%" }}></div>
                            {/* <div className="status">Until Next Stage</div> */}
                          </div>
                          {/* <Typography
                              sx={{
                                marginTop: "1rem",
                                marginBottom: "0.5rem",
                                px: "1rem",
                              }}
                            >
                              USDT Raised: $2,897,322.14 / $2,974,083
                            </Typography> */}
                          <Typography 
                            sx={{
                              marginBottom: "0.5rem", 
                              px: "1rem",
                              textAlign: "start",
                              margin: "10px 92px",
                              "@media screen and (max-width:600px)":{
                                margin: "10px auto"
                              },
                              "@media screen and (max-width:400px)":{
                               fontSize:"11px !important"
                              },
                            }}
                          >
                            {/* Your purchased $USDC = {tokenPrice}{" "}<br/> */}
                            Coin price this round &nbsp; &nbsp;
                            &nbsp;:0.10USDT/USDC
                            <br />
                            Coin price next round &nbsp; &nbsp;:0.12USDT/USDC
                            <br />
                            Public list price &nbsp; &nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            :0.20USDT/USDC
                            <img
                              className="my-auto"
                              src="https://etuktuk.io/assets/images/svg-icons/info-icon.svg"
                            ></img>
                          </Typography>
                          {/* <Typography
                              sx={{ marginBottom: "0.5rem", px: "1rem" }}
                            >
                              Your stakeable $TUK = 0{" "}
                              <img
                                className="my-auto"
                                src="https://etuktuk.io/assets/images/svg-icons/info-icon.svg"
                              ></img>
                            </Typography> */}
                        </Box>
                        <Box className="px-3 swapArea" 
                          sx={{
                            "@media screen and (max-width:767px)":{ 
                                  width:"100%",
                                  boxSizing:"border-box"
                                },
                          }}
                          > 
                          <Card
                              style={cardStyle}
                              sx={{
                                p: theme.spacing(0.2), 
                                "@media screen and (max-width:767px)":{
                                  margin:"10px 0px !important", 
                                  padding:"5px !important"
                                },
                              }}
                            >
                              <CardContent>
                                <Stack spacing={1}>
                                  <Item sx={{ display: "contents" }}>
                                    {" "}
                                    <div style={{ color: "white" }}>
                                      <h3>Buy G2F TOKENS</h3>
                                      <p style={{ color: "gray" }}>
                                        Join our community and buy your G2F
                                        Coins now. You stake automatically and
                                        receive a <br />
                                        monthly reword in USDT. We are currently
                                        in audit phase, after the audit is
                                        completed we will go live.
                                      </p>
                                      <h3>How to buy your G2F Tokens</h3>
                                      <p style={{ color: "gray" }}>
                                        Create an account, verify your
                                        email-address, go to your wallet, at
                                        finance select deposit.
                                        <br />
                                        Copy your wallet address or scan the
                                        qr-code. Deposit USDT in your wallet.
                                        Buy Tokens <br />
                                        in pre-sale stages we support USDT
                                        (ERC20).
                                      </p>
                                    </div>
                                  </Item>
                                  <Divider
                                    sx={{
                                      border:
                                        "1px solid rgba(255, 255, 255, 0.2)",
                                    }}
                                  />
                                  <Grid
                                    container
                                    sx={{
                                      display: "flex",
                                      flexDirection: {
                                        xs: "column",
                                        md: "row",
                                      },
                                    }}
                                  >
                                    {/* <Item> */}
                                    <Grid
                                      item
                                      xs={12}
                                      md={6}
                                      sx={{ textAlign: "center" }}
                                    >
                                      {/* <FormControl fullWidth={true}>
                                        <InputLabel
                                          htmlFor="email"
                                          shrink={true}
                                          sx={{ color: "#FFFFFF" }}
                                        >
                                        Enter Coins
                                        </InputLabel>
                                        <TextField
                                        sx={{height:"26px !important"}}
                                          margin="normal"
                                          required
                                          id="coins"
                                          name="coins"
                                          value={amount}
                                          autoComplete="coins"
                                          autoFocus
                                          onChange={e => setAmount(e.target.value)}
                                          placeholder="Enter coins"
                                        />
                                      </FormControl> */}
                                      <Box className={classes.noOfTickets}>
                                        <Typography
                                          variant="h5"
                                          textAlign="center"
                                          sx={{ pt: "26px" }}
                                        >
                                          Select No. of tokens:
                                        </Typography>
                                        <Stack
                                          flexDirection="column"
                                          alignItems="center"
                                          sx={{ mt: "13px" }}
                                        >
                                          <ButtonGroup sx={{ mb: "11px" }}>
                                            <Button
                                              className={classes.substract}
                                              onClick={handleSubstractCount}
                                            >
                                              <RemoveIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </Button>
                                            <Button
                                              className={classes.minimumTicket}
                                              sx={{
                                                borderRadius: "10px !important",
                                                color: "#ffff",
                                                mx: "12px !important",
                                              }}
                                            >
                                              {" "}
                                              <span
                                                style={{
                                                  color: "#ffff !important",
                                                }}
                                              >
                                                1
                                              </span>
                                            </Button>
                                            <Button
                                              className={classes.substract}
                                              onClick={handleCount}
                                            >
                                              <AddIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </Button>
                                          </ButtonGroup>
                                          <ButtonGroup>
                                            <Button
                                              className={classes.substract}
                                              onClick={handleTenSubstractCount}
                                            >
                                              <RemoveIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </Button>
                                            <Button
                                              sx={{
                                                borderRadius: "10px !important",
                                                mx: "12px !important",
                                                background: "#ffff !important",
                                              }}
                                              disabled
                                            >
                                              <span
                                                style={{
                                                  color: "#ffff !important",
                                                }}
                                              >
                                                10
                                              </span>
                                            </Button>
                                            <Button
                                              className={classes.substract}
                                              onClick={handleTenCount}
                                            >
                                              <AddIcon
                                                sx={{ fontSize: "15px" }}
                                              />
                                            </Button>
                                          </ButtonGroup>
                                        </Stack>
                                      </Box>
                                      {/* <Button
                                      type="submit"
                                      // fullWidth
                                      variant="contained"
                                      onClick={createCryptoPayment}
                                      sx={{ mt: 3, mb: 2 }}
                                    >
                                      Buy Coins
                                    </Button>
                                    <Button
                                      type="submit"
                                      // fullWidth
                                      variant="contained"
                                      onClick={createCryptoPayment}
                                      sx={{ mt: 3, mb: 2 }}
                                      disabled={isLoading}
                                    >
                                      Download
                                    </Button> */}
                                    </Grid>
                                    {/* </Item> */}

                                    {/* <Item> */}
                                    {/* <Grid xs={3} > */}
                                    {/* {" "} */}
                                    {/* <Button
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        onClick={createCryptoPayment}
                                        sx={{ mt: 3, mb: 2 }}
                                      >
                                        Buy Coins
                                      </Button>
                                    </Grid> */}
                                    {/* </Item> */}
                                    <Grid item xs={12} md={6}>
                                      <Grid
                                        container
                                        spacing={0}
                                        className={classes.ticketPrize}
                                      >
                                        <Grid item xs={8} md={8}>
                                          {" "}
                                          <Typography
                                            variant="subtitle2"
                                            textAlign="left"
                                          >
                                            Token Price{" "}
                                            <Box
                                              sx={{
                                                fontSize: {
                                                  md: "20px",
                                                  xs: "10px",
                                                },
                                                fontWeight: "700",
                                              }}
                                            >
                                              {" "}
                                              ( {formValues.no_of_token} Tokens
                                              * {tokenPrice} )
                                            </Box>{" "}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} md={4}>
                                          <Typography
                                            variant="h4"
                                            sx={{ fontWeight: "700" }}
                                            textAlign="right"
                                          >
                                            {tokenPrice}
                                            {/* <CurrencyFormat
                                            value={amount}
                                            thousandSeparator={"."}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                          />{" "} */}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          {" "}
                                          <Divider
                                            sx={{
                                              background:
                                                "rgba(43, 44, 68, 0.5)",
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                          <Typography
                                            variant="subtitle2"
                                            textAlign="left"
                                            sx={{ fontWeight: "700" }}
                                          >
                                            Total
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                          <Typography
                                            variant="h4"
                                            sx={{ fontWeight: "700" }}
                                            textAlign="right"
                                          >
                                            {(
                                              Math.round(
                                                amount * tokenPrice * 100
                                              ) / 100
                                            ).toFixed(2)}
                                            {/* <CurrencyFormat
                                            value={amount * tokenPrice} 
                                            thousandSeparator={"."}
                                            displayType={"text"}
                                            decimalSeparator={","}
                                          /> */}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {/* 
                                  <Grid item xs={12} md={2} sx={{ textAlign: "right" }}>
                                    <Link
                                      style={{ color: "white", textDecoration: "none" }}
                                      to={file}
                                      target="_blank"
                                      download
                                    >
                                      {" "}
                                      <Button
                                        variant="contained"
                                        sx={{
                                          mt: { md: 2, xs: 2 },
                                          mb: { md: 2, xs: 10 },
                                          p: { md: "14px 28px", xs: "10px 10px" },
                                        }}
                                        type="submit"
                                        disabled={isLoading}
                                      // onClick={()=>{
                                      // }}
                                      >
                                        Download
                                      </Button>
                                    </Link>
                                  </Grid> */}
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      sx={{
                                        textAlign: "center",
                                        display: "flex",
                                        gap: "10px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Button
                                        type="submit"
                                        style={{ padding: "18px 18px" }}
                                        // fullWidth
                                        variant="contained"
                                        onClick={(e) => createCryptoPayment(e)}
                                        sx={{ mt: 3, mb: 2 }}
                                      >
                                        Buy Coins
                                      </Button>
                                      <Button
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        onClick={() =>
                                          handleDownload(
                                            file,
                                            "Crypto-Lottery-ICO-Whitepaper.pdf"
                                          )
                                        }
                                        style={{ padding: "18px 18px" }}
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={loading}
                                      >
                                        Download
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </CardContent> 
                            </Card>
                           
                        </Box>
                      </Box>
                    </Grid> 
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      sx={{ color: "white" }}
                    >
                      <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{ 
                          marginBottom: "40px", 
                          p: theme.spacing(1),
                          "@media screen and (max-width:1100px)":{
                            marginBottom:"30px",
                            padding:"20px 0px"
                          }, 
                          "@media screen and (max-width:992px)":{
                            marginBottom:"30px",
                            padding:"20px 0px"
                          },
                          "@media screen and (max-width:767px)":{
                            marginBottom:"20px",
                            padding:"10px 5px"
                          },
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography
                            variant="h2"
                            // align="center"
                            sx={{ 
                              mb: 2 , 
                              "@media screen and (max-width:1100px)":{
                              fontSize:"26px !important"
                              },
                            }}
                          >
                            Summary
                          </Typography>
                          <Card
                            sx={{
                              margin: "0 auto",
                              background: "none",
                              display: "flex",
                              color: "white !important",
                              opacity: "0.7",
                            }}
                          >
                            <Box
                              sx={{
                                columnCount: 2,
                                columnFill: "balance",
                                columnGap: "40px",
                                "@media screen and (max-width:767px)":{
                                  columnCount:1,
                                  columnGap:"10px"
                                },
                              }}
                            >
                              The Wheel of Fortune, a game invented by Merv
                              GRIFFIN in 1975, is one of the most popular games
                              in the world. <br />
                              <br /> Licensing our products will open the market
                              of Fiat players. This will increase the total
                              revenue of the platform even more.
                              <br />
                              <br /> We have transformed this game into a new
                              block- chain based lottery system with the highest
                              win chance in the world.To give everyone in the
                              world the chance to play in the G2F CRYPTO FORTUNE
                              lottery we will start a global marketing campaign
                              to attract emerging markets like South America,
                              Asia and Africa.
                              <br />
                              <br />
                              <b>Early investors</b>
                              <br />
                              <br /> Early investors and players will be able to
                              buy and receive a 75% stake of the companies
                              revenue. To create a fair and public lottery, we
                              use block- chain technology. Lottery tickets are
                              sold as NFT’s in smart contracts and paid out in
                              crypto-currency.25% will be blocked and staked to
                              build up the power ball Jackpot. This tokens will
                              never go on the market.
                              <br />
                              <br /> Randomization of ticket-numbers is realized
                              through the RANDAO protocol introduced by
                              Ethereum2.Our goal is to have a monthly draw in
                              the first year. A total of 6 million tickets will
                              be sold, resulting in an annual return of 7.4% for
                              the G2F token holders.
                              <br />
                              <br /> Players can influence the variables of the
                              lottery. All players specify their preferred
                              variables for the lottery, the average outcome of
                              all the players will be the final variables for
                              that particular lottery. This assures transparency
                              and fair play for all players.
                              <br />
                              <br />
                              <b>Global aspirations</b>
                              <br />
                              <br /> MERV CRYPTO FORTUNE LOTTERY is a
                              community-based crypto lottery that offers their
                              players the best online gaming experience
                              possible! At MERV CRYPTO FORTUNE LOTTERY, we are
                              convinced that blockchain will change the online
                              gambling industry forever! To reach our global
                              aspirations we will keep on developing. We plan to
                              embed G2F Crypto Fortune Lottery to a Gambling
                              platform where players can also play in an online
                              Crypto Casino and a new and exiting Egaming
                              betting platform. This will attract more players
                              and will increase demand on the G2F tokens.
                              <br />
                              <br />
                              With a weekly lottery of 500.000 tickets creating
                              a prizepool of 1.000.000 USDT will result in a
                              revenue of 26% on the G2F tokens.
                              <br />
                              <br />
                              Due to the limited number of tickets per draw,
                              many draws can potentially be held per week with a
                              very high return on an annual basis.
                              <br />
                              <br />
                              Early investors can buy the G2F token in pre- sale
                              for 0.10 USDT. Public sale will start at 0.20
                              USDT.
                              <br />
                              <br />
                              Early investors will receive their tokens paid out
                              in 10 equal installments per month. Private
                              investors can market up to 8% of their tokens per
                              year. This proves the confidence of early
                              investors in the success of the MERV crypto
                              fortune lottery.
                              <br />
                              <br />
                              <span style={{ fontWeight: "900" }}>
                                Some people call crypto a gamble, let’s gamble
                                with crypto!
                              </span>
                              <br />
                              {/* <br /> Our goal is to take 0.0001% of the market
                                in the first year. This means that 15 million
                                tickets will be sold resulting in an annual
                                revenue of 7.5% With a weekly lottery of 500.000
                                USDT this will result in a revenue of 13% on the
                                MERV crypto tokens.
                                <br />
                                <br /> Early investors can buy the MERV-Coin for
                                0.08 USDT. Public sale will start at 0.10 USDT */}
                              {/* <img src={"https://cryptolotteryapi.dev.vinove.com/api/v1/admin/uploads/"+DataValue?.data?.[0]?.image} alt="donation image"/> */}
                            </Box>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                maxWidth: "920px",
                                height: "auto",
                                // margin: "50px",
                                overflow: "auto",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "700px",
                                  color: "white",
                                  textAlign: "start",
                                }}
                              >
                                <h1
                                  style={{
                                    fontSize: "23px",
                                    fontWeight: 500,
                                    color: "#3C37FF",
                                    marginTop: "0",
                                  }}
                                ></h1>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    opacity: "0.7",
                                    fontWeight: 400,
                                    lineHeight: "150%",
                                  }}
                                ></p>
                              </div>
                            </div>
                          </Card>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={3}>
                <Box className={classes.innerGridThird}></Box>
              </Grid> */}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}
