/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';

export type forgetPasswordCredentialsDTO = {
  //email: string;
};

export const forgetPasswordWithEmail = (data: forgetPasswordCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/api/v1/user/forgotPassword', data);
};

type forgetPasswordOptions = {
  config?: MutationConfig<typeof forgetPasswordWithEmail>;
};


export const forgetPasswordFnData = ({ config }: forgetPasswordOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Password Updated',
      });
    },
    ...config,
    mutationFn: forgetPasswordWithEmail,
  });
};
