import * as React from 'react';
import Header from '../Elements/header';
import Footer from '../Elements/footer';
import TopContainer from '@/features/Homescreen/Components/topContainer';
import { Box } from '@mui/material';
import { theme } from '../Elements/theme';

type MainLayoutProps = {
  children: React.ReactNode;      
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <main style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </main>
      <Footer />
    </div>
  );
};
