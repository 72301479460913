import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { Wallet } from '../types';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export type CreateWalletDTO = {
  data: {
    walletPassword: string;
  };
};

export const createWallet= ({ data }: CreateWalletDTO): Promise<Wallet> => {
  return axios.post('/api/v1/user/createwallet', data);
};

type UseCreateWalletOptions = {
  config?: MutationConfig<typeof createWallet>;
};

export const useCreateWallet = ({ config }: UseCreateWalletOptions) => {

  return useMutation({
    onMutate: async (newWallet) => {
      await queryClient.cancelQueries([]);

      const previousWallet = queryClient.getQueryData<Comment[]>([]);

      queryClient.setQueryData(
        [],
        [...(previousWallet || []), newWallet.data]
      );

      return { previousWallet };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWallet) {
        queryClient.setQueryData([], context.previousWallet);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries([]);
      Swal.fire({
        icon:"success",
        background:"black",
        color:"#ffff",
        text:"Wallet Created",
      }).then(()=> {
        window.location.assign("/app/wallet/Deposit")
      });
    },
    ...config,
    mutationFn: createWallet,
  });
};