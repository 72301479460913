import { useNavigate } from 'react-router-dom';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { Layout } from '../components/Layout';


export const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Log in to your account">
      <ResetPasswordForm onSuccess={() => navigate('/auth/login')} />
    </Layout>
  );
};