/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { lotteryList } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';

export type postLotteryDrawDTO = {
    lotteryId: string,
    ticketNumber:any,
    lotteryRank:number;
    lotteryWinningPrize:string
};



export const postLotteryDraw = (data: postLotteryDrawDTO): Promise<lotteryList> => {
  return axios.put('/api/v1/user/updateLotteryTicket', data);
};




type postLotteryDrawReferOptions = {
  config?: MutationConfig<typeof postLotteryDraw>;
};


export const lotteryDrawFnData = ({ config }: postLotteryDrawReferOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Letter Sent',
      });
    },
    ...config,
    mutationFn: postLotteryDraw,
  });
};
