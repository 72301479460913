import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';
import { Toast, ToastContainer } from 'react-toastify/dist/components';
import { error } from 'console';

export type RegisterCredentialsDTO = {
  email: string;
  otp:string;
};

export const registerWithEmailAndPassword = (
  data: RegisterCredentialsDTO
): Promise<UserResponse> => {
  return axios.post('/api/v1/user/verifyotp', data);
};

