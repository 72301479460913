/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';

export type verifyOTPCredentialsDTO = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type resendOTPCredentialsDTO = {
  email: string;
};

export const verifyOTPWithOTP = (data: verifyOTPCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/api/v1/user/signup', data);
};

export const resendOTP = (data: resendOTPCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/api/v1/user/resendotp', data);
};


type verifyOTPOptions = {
  config?: MutationConfig<typeof verifyOTPWithOTP>;
};


export const verifyOTPFnData = ({ config }: verifyOTPOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Password Updated',
      });
    },
    ...config,
    mutationFn: verifyOTPWithOTP,
  });
};
