import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { Wallet } from '../types';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export type BuyCoinsDTO = {
  data: {
        amount:string
  };
};

export const BuyCoins = ({ data }: BuyCoinsDTO): Promise<Wallet> => {
  return axios.post('/api/v1/user/investorMervTransfer', data);
};

type UseBuyCoinstOptions = {
    amount: string;
  config?: MutationConfig<typeof BuyCoins>;
};

export const useBuyCoins = ({ config, amount }: UseBuyCoinstOptions) => {

  return useMutation({
    onMutate: async (newWallet) => {
      await queryClient.cancelQueries(['DepositCoins', amount]);

      const previousWallet = queryClient.getQueryData<Comment[]>(['DepositCoins', amount]);

      queryClient.setQueryData(
        ['DepositCoins', amount],
        [...(previousWallet || []), newWallet.data]
      );

      return { previousWallet };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWallet) {
        queryClient.setQueryData(['DepositCoins', amount], context.previousWallet);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['DepositCoins', amount]);
      Swal.fire({
        icon:"success",
        background:"black",
        color:"#ffff",
        text:"Amout deposited",
      });
    },
    ...config,
    mutationFn: BuyCoins,
  });
};