/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { passwordChange } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '@/lib/react-query';
import { useMutation, useQuery } from 'react-query';

// export type changePasswordDTO = {
//         "oldPassword":string,
//         "newPassword":string,
//         "confirmPassword":string,
// };



export const postPasswordChange = ({ data }: { data: any |undefined }): Promise<passwordChange> => {
  return axios.put('/api/v1/user/updatepassword', data);
};



type QueryFnType = typeof postPasswordChange;
type changePasswordDataOption = {
  config?: QueryConfig<QueryFnType>;
  data?:any;
};






export const changePasswordData = ({data, config }: changePasswordDataOption) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['password', data],
    queryFn: () => postPasswordChange({ data }),
    ...config,
  });
};


