import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { privacyList } from '../types';

export const getPrivacyList = async (): Promise<privacyList> => {
  const response = await axios.get(`/api/v1/user/userPrivacy`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getPrivacyList;

type UsePrivacyListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const usePrivacyList = ({  config }: UsePrivacyListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getPrivacyList(),
    ...config,
  });
};