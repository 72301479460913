import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
//import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  const token = storage.getToken();
  const tokenReset = storage.getResetToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
   }
   if(tokenReset){
    config.headers.Authorization = `Bearer ${tokenReset}`;
   }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);


axios.interceptors.response.use(
  (response) => {
    if(response?.data?.message === "Password reset successful"){
    setTimeout(() => {
      window.location.assign('/auth/login');
    }, 1000);
  }
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    if(error?.response?.status === 400 && error?.response?.data?.message === "For security reasons, we locked your account.Please login to continue" ){
      setTimeout(() => {
        storage.clearToken();
        window.location.assign('/auth/login');
      }, 1000);
    }

    Swal.fire({
      icon: 'error',
      text: message,
      color: '#fff',
  background: 'black',
    })
   console.log(message);
    return Promise.reject(error);
  }
);
