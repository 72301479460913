import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Grid,Button } from "@mui/material";

import styles from "./CSS/errorPageStyles";
import { useAuth } from "@/lib/auth";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  const {user} =useAuth();
  const handleRefresh = () => {
    if(user)
    window.location.assign("/app")
    else
    window.location.assign("/auth/login")
  }
  return (
    <div className={classes.contentCenter}>
      <Grid Container>
        <Grid Item md={12}>
          <h1 className={classes.title}>404</h1>
          <h2 className={classes.subTitle}>Page not found :(</h2>
          <h4 className={classes.description}>
            Ooooups! Looks like you got lost.
          </h4>
          <Button onClick={handleRefresh}>Refresh</Button>
        </Grid>
      </Grid>
    </div>
  );
}
