import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Menu,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";

import moment from "moment";
import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import cryptoPayments from "@/assets/cryptoPayments.svg";
import allPayments from "@/assets/allPayments.svg";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
import { MantineProvider, Table, Container } from "@mantine/core";
import { data } from "./makeData";
import { Title } from "@material-ui/icons";
import { DatePickerInput } from "@mantine/dates";
import { useLotteryList } from "../api/getLotteryList";
import { useInvestedCoins } from "../api/getInvestedCoins";
import yellowt from "@/assets/yellow-t.png";
import CurrencyFormat from "react-currency-format";
import MervIcon from "@/assets/mervIcon.svg";
import "./table.css";
import Pagination from "rc-pagination";

export type Payment = {
  endDate: Date;
  endTime: Date;
  lotteryDrawDate: string;
  lotteryImage: string;
  claimStatus?: string;
  transactionId: string;
  LotteryName: string;
  ticketNumber: string;
  createdDate: string;
  amount: number;
  winAmount: string;
  surpriceAmt: number;
  lotteryStatus: string;
};

export const MyLottery = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const dataWallet = useWalletAmount({});
  const coins = useInvestedCoins({});
  const { data, isLoading, isError, isFetching }: any = useLotteryList({
    page,
    limit,
  });
  console.log(isLoading, isError, data);
  const lotteryData = data?.data || [];
  const pageCount = data?.pagination?.totalPages || 0;
  const currentPage = data?.pagination?.currentPage || 0;
  const totalItems = data?.pagination?.totalItems || 0;
  const handlePageChange = async (newPage: number) => {
    setLoading(true); // Show loader
    setPage(newPage); // Set the new page number
    setLoading(false); // Hide loader (automatically once data is fetched)
  };
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: limit,
  });
  const Item = styled(Paper)(() => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  const columns = useMemo<MRT_ColumnDef<Payment>[]>(
    () => [
      {
        accessorKey: "LotteryName",
        filterVariant: "text",
        header: "Lottery Name",
        size: 20,
      },
      {
        accessorKey: "ticketNumber",
        filterVariant: "text",
        header: "Ticket Number",
        size: 20,
      },
      // {
      //   accessorFn: (row) => new Date(row.createdDate),
      //   id: "Date",
      //   header: "Date",
      //   filterFn: "lessThanOrEqualTo",
      //   sortingFn: "datetime",
      //   size: 20,
      //   Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
      //   Header: ({ column }) => <em>{column.columnDef.header}</em>,

      //   Filter: ({ column }) => (
      //     <DatePickerInput
      //       clearable
      //       onChange={(newValue: Date) => {
      //         column.setFilterValue(newValue);
      //       }}
      //       value={column.getFilterValue() as Date}
      //       modalProps={{ withinPortal: true }}
      //     />
      //   ),
      // },

      {
        accessorKey: "createdDate",
        filterVariant: "text",
        header: "Created At",
        size: 20,
        Cell: ({ cell }) => {
          const formattedDate = moment(cell.getValue() as string).format(
            "DD/MM/YYYY"
          ); // Change this format to your desired one
          return <Box>{formattedDate}</Box>;
        },
      },

      {
        accessorKey: "amount",
        header: "Ticket Price",
        size: 70,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: "winAmount",
        header: "Win Amount",
        size: 70,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },

      {
        accessorKey: "surpriceAmt",
        header: "Free Ticket Amount",
        size: 50,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },

      {
        accessorKey: "lotteryStatus",
        header: "Status",
        size: 70,
        Cell: ({ cell }) => (
          <Box
            sx={() => ({
              color:
                cell.getValue<string>() === "Winner"
                  ? "#95D23D"
                  : cell.getValue<string>() === "Loose"
                  ? "#FF4545"
                  : cell.getValue<string>() === "Pending"
                  ? "#FF9900"
                  : "#FF4545",
              borderRadius: "4px",
              border:
                cell.getValue<string>() === "Winner"
                  ? "1px solid rgba(149, 210, 61, 0.41)"
                  : cell.getValue<string>() === "Loose"
                  ? "1px solid rgba(255, 69, 69, 0.4)"
                  : cell.getValue<string>() === "Pending"
                  ? "1px solid rgba(231, 214, 61, 0.5)"
                  : "1px solid rgba(255, 69, 69, 0.4)",
              backgroundColor:
                cell.getValue<string>() === "Winner"
                  ? "rgba(149, 210, 61, 0.19)"
                  : cell.getValue<string>() === "Loose"
                  ? "rgba(255, 69, 69, 0.05)"
                  : cell.getValue<string>() === "Pending"
                  ? "rgba(231, 214, 61, 0.1)"
                  : "rgba(255, 69, 69, 0.05)",
              maxWidth: "9ch",
              padding: "4px",
            })}
          >
            {cell.getValue<string>() === "Pending"
              ? "Pending"
              : cell.getValue<string>()}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container sx={{ mt: { md: theme.spacing(0.5), xs: "10px" } }}>
        <Grid item xs={12} md={12}>
          {" "}
          <Card
            style={cardStyle}
            sx={{
              p: { md: theme.spacing(0.3), xs: "0px" },
              m: theme.spacing(0.2),
            }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Item sx={{alignItems:"center", justifyContent:"space-between"}}>
                  {" "}
                  <Typography
                    component="h4"
                    sx={{
                      fontSize: { xs: "20px", md: "22px" },
                    }}
                  >
                    My Lottery
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{  
                      pt: { xs: "5px", md: "0" },
                    }}
                  >
                    <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                      <img
                        src={MervIcon}
                        className="log-merv-wallet"
                        style={{
                          height: "22px",
                          width: "22px",
                          position: "relative", 
                        }}
                      />{" "}
                      {coins?.data?.data}  
                    </Typography>
                    <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                    <img
                      src={yellowt}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px",
                        position: "relative", 
                      }}
                    />
                    <CurrencyFormat
                      value={dataWallet?.data?.data?.walletAmount}
                      thousandSeparator={","}
                      displayType={"text"}
                      decimalSeparator={"."}
                    />
                    </Typography>
                  </Typography>
                </Item>
                <Grid item sx={{ width: "100%", overflowX: "auto" }}>
                  <MantineProvider
                    withGlobalStyles
                    withNormalizeCSS
                    theme={{
                      breakpoints: {
                        xs: "30em",
                        sm: "48em",
                        md: "64em",
                        lg: "74em",
                        xl: "90em",
                      },
                      fontSizes: { xs: "12px", md: "14px" },
                      colorScheme: "dark",
                    }}
                  >
                    {isFetching ? ( // Show loader while data is loading or empty
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "200px", // Adjust based on the table height
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <MantineReactTable
                        columns={columns}
                        data={lotteryData || []}
                        enablePagination={false}
                        initialState={{ showColumnFilters: true }}
                        positionToolbarAlertBanner="bottom"
                      />
                    )}
                  </MantineProvider>
                </Grid>
                {isFetching ? (
                  <Box className="paginationBox">""</Box>
                ) : (
                  <Box className="paginationBox">
                    <Pagination
                      pageSize={pageCount}
                      onChange={handlePageChange}
                      current={currentPage}
                      total={totalItems}
                    />
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
