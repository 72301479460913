import { Close } from "@mui/icons-material";
import {
  Box,
  Stack,
  IconButton,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import { CustomModel } from "@/components/Elements/custom-modalAfterLogin";
import winnerLogo from "@/assets/winnerLogo.svg";
import closeModal from "@/assets/closeModal.svg";
import { makeStyles } from "@material-ui/core/styles";
import arrow from "@/assets/arrow.png";
import { count } from "console";
import { useEffect } from "react";
import jackpotWinner from "../../../assets/Powerball Jackpot winner.gif";
interface jackpotwinModalProps {
  open: boolean;
  onClose: () => void;

  JackpotPrize: any;
  jackpotRound: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
  first: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 467,
  },
  second: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 150,
  },
  parentPaper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 1600,
  },
  availableTickets: {
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    height: 110,
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
  },
  table: {
    minWidth: 280,
  },
  tableHeader: {
    color: "#fff",
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  logo: {
    width: 50,
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      minHeight: "200px",
      minWidth: "200px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
  },
  ticketContainer: {
    width: "134px",
    height: "60px",
    background: "#FFFF",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "45px",
    },
    position: "relative",
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    padding: "12.86px",
    [theme.breakpoints.down("sm")]: {
      padding: "7.86px",
    },
  },
  detailLabel: {
    height: "11px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "11px",
    color: "rgba(27, 28, 45, 0.6)",
    margin: "0",
  },
  detailNumber: {
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "0",
  },
  detailTime: {
    width: "82px",
    height: "16px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#1B1C2D",
    margin: "0",
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(180deg, #3b97bb 0%, #aed1db 99%)",
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      borderRadius: "100px",
    },
  },
  jackpotContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    height: "38px",
    background: "rgba(60, 55, 255, 0.15)",
    marginBottom: "14px",
  },
  jackpotText: {
    width: "99px",
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.2em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "auto",
  },
  arrowImage: {
    position: "absolute",
    width: "30px",
    height: "30px",
    left: "74%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      left: "68%",
    },
  },
  polyup: {
    top: "-15px",
    left: "86%",
    [theme.breakpoints.down("sm")]: {
      height: "30px ! important",
      width: "30px !important",
    },
  },
  polydown: {
    bottom: "-15px",
    transform: "rotate(180deg)",
  },
  winnerModalImg: {
    [theme.breakpoints.down("sm")]: {
      height: "60px ! important",
      width: "60px !important",
    },
  },
}));

const JackpotWinModal = ({
  open,
  onClose,
  jackpotRound,
  JackpotPrize,
}: jackpotwinModalProps) => {
  const classes = useStyles();
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [open, onClose]);
  return (
    <CustomModel
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Stack direction="row" height="100%" sx={{ p: 0 }}>
            <Box
              sx={{ width: "100%", overflow: "hidden" }}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                src={winnerLogo}
                alt="mailIcon"
                style={{ textAlign: "center" }}
                className={classes.winnerModalImg}
                height="100px"
                width="100px"
              />

              <Paper
                elevation={3}
                className={classes.ticketContainer}
                sx={{
                  marginTop: "0px",
                  marginBottom: "20px",
                  background: "#fff !important",
                  color: "#ffff",
                  display:"flex",
                  alignItem:"center",
                  justifyContent:"center",
                  width:"100% !important",
                  maxWidth:"220px !important",
                  height:"auto !important",
                }}
              >

                 <img
                  src={jackpotWinner}
                  alt="You Win the Jackpot"
                   style={{ width: "auto", maxWidth: "200px" }}
                />
                {/* <Typography variant="h6" className="youwin-popup">
                  YOU WIN JACKPOT
                </Typography> */}

                {/* <div className={classes.detailContainer}>
                  <div>
                    <Typography
                      variant="body2"
                      className={classes.detailLabel}
                      sx={{ color: "#fff !important" }}
                    >
                      Jackpot Amount
                    </Typography>
                    <Typography
                      variant="h6"
                      className={classes.detailNumber}
                      sx={{ color: "#fff !important" }}
                    ></Typography>
                  </div>
                  <Divider
                    sx={{
                      borderRight: "0.46px black dotted",
                      position: "absolute",
                      left: "85%",
                      top: "2%",
                    }}
                    orientation="vertical"
                  />
                </div>
                <img
                  src={arrow}
                  alt="Up Arrow"
                  className={`${classes.arrowImage} ${classes.polyup}`}
                />

                <img
                  src={arrow}
                  alt="Down Arrow"
                  className={`${classes.arrowImage} ${classes.polydown}`}
                /> */}
              </Paper>
              <Typography
                component="h6"
                variant="h6"
                sx={{ textAlign: "center" }}
              >
                Winning Amount
              </Typography>
              <Typography
                component="h5"
                variant="h4"
                sx={{
                  mb: { xs: "5px", md: "20px" },
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                $ {JackpotPrize}
              </Typography>
            </Box>
            <Box></Box>
          </Stack>
        </>
      }
    />
  );
};

export default JackpotWinModal;
