import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  Grid,
} from "@mui/material";
import clsx from "clsx";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import promotionSecond from "@/assets/promotionSecond.png";
import { theme } from "@/components/Elements/theme";
import { url } from "inspector";
import HomeScreenImg from "@/assets/earth.jpg";

import Countdown from "react-countdown";
import { usePrivacyList } from "../api/privacyNotice";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
}));



export default function PrivacyNotice() {
  const classes = useStyles();
  //const DataValue = usePrivacyNotice({});
  const DataValue = usePrivacyList({});
  const auth = useAuth();
  return (
    <>
      {auth.user? <Header />: <HeaderValue />}
      <Box
        sx={{
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          padding: 0,
          pt: "70px",
          pb:"40px",
        }}
      >
        <Box
          sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
        />
        <Box sx={{ p: { xs: "0 15px",sm:"0 30px", md: "50px" } }}>
          <Box 
            sx={{
              backgroundColor: "rgba(5, 5, 24, 0.8)",
              borderRadius: "10px",
              display:"flex",
              flexFlow:"column",
              marginTop: "40px", 
              p: {xs: "15px",sm:"30px",md:"64px"}
            }}
          >
            <h1 style={{ fontSize: "23px", fontWeight: 500,color: "#3C37FF", marginTop:"0px" }}>
                        {DataValue?.data && DataValue?.data?.[0]?.title}
                        </h1>
            <Grid
                container
                display={"flex"}
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"} 
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card sx={{ margin: "0 auto", background: "none" }}>
                  <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // maxWidth:"920px",
                        height:"500px",
                        margin:"auto",
                        overflow:"auto",
                      }}
                    >
                      <div
                        style={{
                          // maxWidth: "700px",
                          color: "white",
                          textAlign: "start",
                        }}
                      >
                        
                        <p
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          {DataValue?.data?.[0]?.detail!== undefined && (
                          <div dangerouslySetInnerHTML={{ __html: DataValue?.data && DataValue?.data?.[0]?.detail}} />
                          )}
                         {}
                      </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
          </Box>
        </Box>
      </Box>
      <Footer/>
    </>
  );
}
