import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import { Box, Paper, Typography, Grid, Divider } from "@mui/material";
import staticImage1 from "@/assets/staticImage1.svg";
import dontMissChance from "@/assets/dontMissChance.svg";
import claimLotteryPrize from "@/assets/claimLotteryPrice.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "50px"
  },

  image: {

  },
}));

const StyledImg = styled("img")({
  maxWidth: "100%",
});

export default function StaticFAQ() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} sx={{ py: "30px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Divider sx={{ borderBottom: " 1px solid rgba(255, 255, 255, 0.1)" }} />
            </Grid>
            <Grid item xs={12} sm={2} display='flex' justifyContent='center' alignItems='center'>
              <StyledImg src={staticImage1} alt="" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ color: "black", py: "30px" }} display='flex' justifyContent='flex-start' alignItems='flex-start' flexDirection='column'>
              <Typography variant="h4">How do you play MERV Crypto Fortune?</Typography>
              <Typography
                component="h6"
                variant="caption"
                sx={{ color: "#FFFFFF" }}
              >
                <ul>
                  <li>Choose the lottery you want to play in</li>
                  <li>Select the number of tickets you want to buy. </li>
                  <li>Set the speed and deceleration of the wheel ( not required ). <br />
                    <li> The average of the outcome decides the speed and deceleration of the wheel in the Spin.  </li>
                  </li>
                  <li>Click the buy button and pay for the tickets. </li>
                  <li>One hour before the Spin the ticket sale will be closed. </li>
                  <li>A random program will now assign your ticket number.  </li>
                  <li>The Spin will always start with the number 000000.</li>
                  <li>The 2e Spin will start at the last winning number. etc.</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
              <Divider sx={{ borderBottom: " 1px solid rgba(255, 255, 255, 0.1)" }} />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ py: "30px" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12}>
            </Grid>
            <Grid item xs={12} sm={2} display='flex' justifyContent='center' alignItems='center'>
              <StyledImg src={claimLotteryPrize} alt="trophey" height={100} width={100} className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ color: "black", py: "30px" }} display='flex' justifyContent='flex-start' alignItems='flex-start' flexDirection='column'>
              <Typography variant="h4">How to claim your lottery price?</Typography>
              <Typography
                component="h6"
                variant="caption"
                sx={{ color: "#FFFFFF" }}
              >
                Congratulations, you are a winner, you will be redirected to the winners page. Here you can claim your price. After a KYC (Know Your customer)  check we transfer the winning price in crypto-currency to your player's wallet. Here you can request a withdrawal to the wallet of your choice or get paid out in FIAT money.
                note: KYC applies only to prices greater than $ 5.000
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Divider sx={{ borderBottom: " 1px solid rgba(255, 255, 255, 0.1)" }} />
            </Grid>
            <Grid item xs={12} sm={2} display='flex' justifyContent='center' alignItems='center' flexDirection='column' sx={{ py: "30px" }}>
              <StyledImg src={dontMissChance} alt="" className={classes.image} />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ color: "black", py: "30px" }} >
              <Typography variant="h4">Never miss a lottery draw.</Typography>
              <Typography
                component="h6"
                variant="caption"
                sx={{ color: "#FFFFFF" }}
              >The excitement of the live draw is an important element of a lottery. The system has been developed in such a way that even if you miss the live draw you can watch it afterwards as if it were live for you.
                When you have bought tickets and want to know if you have won you will have to play the lottery.
                In the history section you can view every lottery draw as if you see the live draw.

              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
