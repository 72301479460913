import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { promotionResponse } from '../types';
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '@/lib/react-query';


export const getPromotionByRefer = async (): Promise<promotionResponse> => {
  return await axios.get(`/api/v1/user/referpromotionlist`, {
  })
    //return response?.data;
    //  const data = response.data;
    // return data;
}



type QueryFnType = typeof getPromotionByRefer;

type UsePromotionByReferListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const UsePromotionByReferList = ({  config }: UsePromotionByReferListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getPromotionByRefer(),
    ...config,
  });
};