import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import cryptoPayments from "@/assets/cryptoPayments.svg";
import allPayments from "@/assets/allPayments.svg";
import { useDepositCryptoPayment } from "../api/depositCryptoPayment";
import Swal from "sweetalert2";

export const CryptoPaymentsModule = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  const createPaymentMutation = useDepositCryptoPayment({ amount });
  var sweet_loader =
    '<div class="circular-progress"><div class="circle"></div><div class="mask full"><div class="fill"></div></div><div class="mask half"><div class="fill"></div><div class="fill fix"></div></div></div>';
  const showLoadingPopup = () => {
    Swal.fire({
      text: "We are processing your purchase, one moment please",
      allowOutsideClick: false,
      showCancelButton: false,
      html: sweet_loader,
      customClass: "buy-now-pop-up",
      background: "black",
      color: "#ffff",
      showLoaderOnConfirm: true,
      timer: 1000000,
      showConfirmButton: false,
      timerProgressBar: true,
    }).then(
      function () {},
      // handling the promise rejection
      function (dismiss) {
        if (dismiss === "timer" && !isLoading) {
          //console.log('I was closed by the timer')
        }
      }
    );
  };

  const createCryptoPayment = async (event) => {
    //bytes= CryptoJS.AES.encrypt("e7af217b465dfe47f6cf981de4c0d108c3512f5d54d2c3d01acfa8027faa33fd","Nikita").toString();
    try {
      if (event.target) {
        event.target.blur();
      }
      
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.blur();
        }
      }, 100);
      setIsLoading(true);
      await createPaymentMutation
        .mutateAsync({
          data: {
            amount,
          },
        })
        .then((res) => {
          Swal.fire({
            background: "black",
            color: "#ffff",
            icon: "success",
            title: "Success!",
            text: "Payment successful",
          }).then(() => {
            window.location.assign("/");
          });
        });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: "No Payment Done",
      });
    }
  };

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };

  return (
    <Grid item xs={6} md={6}>
      {isLoading && <>{showLoadingPopup()}</>}{" "}
      <Card
        style={cardStyle}
        sx={{ p: theme.spacing(0.2), }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Item sx={{ display: "contents" }}>
              {" "}
              <Typography variant="h5">Select Deposit Amount</Typography>
              <Typography
                variant="h6"
                sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
              >
                Enter details to proceed
              </Typography>
            </Item>
            <Divider sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }} />
            <Item>
              {" "}
              <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="email"
                    shrink={true}
                    sx={{ color: "#FFFFFF" }}
                  >
                    Amount (USD)
                  </InputLabel>
                  <TextField
                   inputRef={inputRef}
                    sx={{ height: "26px !important", textAlign:"start" , "& .MuiInputBase-input":{textAlign:"start"}}}
                    margin="normal"
                    required
                    id="amount"
                    name="amount"
                    value={amount}
                    autoComplete="amount"
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    autoFocus
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter Amount"
                  />
                </FormControl>
              </Grid>
            </Item>

            <Item>
              <Grid xs={12} md={12}>
                {" "}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={createCryptoPayment}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Confirm Now
                </Button>
              </Grid>
            </Item>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};
