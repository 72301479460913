import React, { useEffect } from "react";
import { Box, Paper, Typography, Grid, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import rightAgleArrow from "../../../assets/rightAgleArrow.png";
// import homescreenBackground from "../../../assets/homescreenBackground.png";
import homescreenBackground from "@/assets/earth.jpg";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import { useState } from "react";
import { getContactList } from "../api/contactus";
import { getAdminContact, useAdminContact } from "../api/adminContactUs";
import ContactUSDetail from "./contactusDetail";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
// import { getContactList } from '../api/contactus';
import "./contact.css";
import Swal from "sweetalert2";
import { Padding } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  flexWrap: {
    flexWrap: "wrap",
  },
  dFlex: {
    display: "flex",
  },
  justifyEnd: {
    justifyContent: "end",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  heading1: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    color: "#FFFCFC",
  },
  para1: {
    fontFamily: "'Lato'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: "#FFFFFF",
    opacity: 0.9,
    margin: "20px auto",
  },
  blueColor: {
    color: "#3C37FF !Important",
  },
  heading2: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "25px",
    lineHeight: "140%",
    color: "#FFFFFF",
  },
  para2: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 252, 252, 0.6)",
    opacity: 0.6,
  },
  breadcrum: {
    margin: "auto 40px",
    paddingTop: "20px",
    paddingBottom: "20px",
    [theme.breakpoints.down("md")]: {
      margin: "auto 5px",
    },
  },
  blueColorOpacity: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "35px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    marginTop: "80px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
  mainBox: {
    backgroundImage: `url(${homescreenBackground})`,
    backgroundRepeat: "no-repeat",
    "background-size": "cover",
    "background-repeat": "no-repeat",
    "min-height": "100%",
    "background-attachment": "fixed",
    "background-position": "center",
    "padding-top": "50px",
  },
  darkBlue: {
    background: "#050518",
    padding: "60px",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: { 
      marginTop: "20px",
      padding:"20px"
    },
  },
  width50: {
    width: "50%",
  },
  blueButton: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FFFCFC",
    background: "#3C37FF",
    borderRadius: "9.15069px",
    border: "none",
    width: "100%",
    height: "50px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  heading3: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
  },
  para3: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    display: "flex",
    alignItems: "center",
    textDecorationLine: "underline",
  },
  para4: {
    fontFamily: "'Ubuntu'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    color: "rgba(255, 252, 252, 0.6)",
  },
  removePadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  sendBnt:{ 
    alignSelf:"end",
    padding: "20px 50px 20px 50px",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.23)",
    border: "1px solid black",
    borderRadius: "7px",
    margin: "20px 20px",
    fontSize: "large",
    color: "white",
    cursor: "pointer",
    "@media screen and (max-width:767px)":{
      padding:"10px 20px",
      margin:"15px 20px"
    }
  },
}));

function ContactUS() {
  const auth = useAuth();
  const [contactUs, setContactUs] = useState();
  const [hover, setHover] = useState(false);
  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };
  const [data, setData] = useState({
    firstName: "",
    emailAddress: "",
    topic: "",
    subject: "",
    detail: "",
  });

  const classes = useStyles();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    try {
      // Make the API request using lotteryDataValue function
      await getContactList(data).then((res) => {
        Swal.fire({
          background: "black",
          color: "#ffff",
          icon: "success",
          title: "Success!",
          text: "Enquiry sent successfully",
        }).then(() => {
          setData({
            firstName: "",
            emailAddress: "",
            topic: "",
            subject: "",
            detail: "",
          });
        });
      });
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        background: "black",
        color: "#ffff",
        text: `${error?.response?.data?.message}`,
      });
    }
  };
  const adminContact = useAdminContact({});

  return (
    <>
      {auth.user ? <Header /> : <HeaderValue />}
      <Box className={classes.mainBox} p={4}>
        {/* <Box className={`${classes.dFlex} ${classes.breadcrum}`}> */}
        {/* <Typography className={classes.para2}>HOME</Typography>
            <img style={{ height: "12px", margin: "auto 7px" }} src={rightAgleArrow} alt="" />
            <Typography className={`${classes.para2} ${classes.blueColor}`}>CONTACT US</Typography> */}
        {/* </Box> */}

        <Box className={`${classes.blueColorOpacity} ${classes.breadcrum}`}>
          <Grid 
            container
            spacing={3}
          >
            <Grid item xs={12} md={7} className={classes.removePadding}>
              <Box className={classes.darkBlue} sx={{display:"flex", flexFlow:"column"}} >
                <Typography className={classes.heading1}>
                  SEND US A MESSAGE
                </Typography>
                <Grid
                  classes={`${classes.dFlex} ${classes.justifyCenter}`}
                  container
                  spacing={1}
                >
                  <Grid item xs={12} sm={6}>
                    <Typography 
                      className={classes.para1}
                    >
                      First Name
                    </Typography>
                    <TextField
                      sx={{ width: "100%", }}
                      id="outlined-multiline-flexible"
                      placeholder="Enter your name"
                      multiline
                      maxRows={4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PermIdentityIcon style={{ color: "white" }} />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        inputMode: "text", // Ensures the text keyboard is displayed on mobile
                      }}
                      name="firstName"
                      value={data?.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.para1}>
                      Email Address
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      placeholder="Enter your email Id"
                      multiline
                      maxRows={4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <MailOutlineIcon style={{ color: "white" }} />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        inputMode: "text", // Ensures the text keyboard is displayed on mobile
                      }}
                      name="emailAddress"
                      value={data?.emailAddress}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography 
                      className={classes.para1}
                    >
                      Topic
                    </Typography>
                    <TextField
                      sx={{ width: "100%",}}
                      id="outlined-multiline-flexible"
                      placeholder="Enter your topic"
                      multiline
                      maxRows={4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PermIdentityIcon style={{ color: "white" }} />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        inputMode: "text", // Ensures the text keyboard is displayed on mobile
                      }}
                      name="topic"
                      value={data?.topic}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography 
                      className={classes.para1}
                    >
                      Subject
                    </Typography>
                    <TextField
                      sx={{ width: "100%", marginLeft: "5px" }}
                      id="outlined-multiline-flexible"
                      placeholder="Enter your subject"
                      multiline
                      maxRows={4}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PermIdentityIcon style={{ color: "white" }} />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        inputMode: "text", // Ensures the text keyboard is displayed on mobile
                      }}
                      name="subject"
                      value={data?.subject}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.para1} sx={{textAlign:"center"}}>Detail</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-multiline-flexible"
                      placeholder="Your Message(1,000 characters maximum)"
                      multiline
                      rows={5}
                      name="detail"
                      value={data?.detail}
                      onChange={handleChange}
                      inputProps={{
                        inputMode: "text", // Ensures the text keyboard is displayed on mobile
                      }}
                    />
                  </Grid>
                </Grid> 
                <button
                  className={`${classes.sendBnt}`}
                  onClick={handleSubmit}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    backgroundColor: hover ? "blue" : "mediumblue",
                  }}
                >
                  Send
                </button>

                <Typography className={classes.para2}>
                  Looking for more information? Consult our <span> &nbsp;</span>
                  <a
                    href="/app/faq"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    FAQ ,<span> &nbsp;</span>
                  </a>
                  <a
                    href="/termsofUse"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Terms of Use
                  </a>
                  <span> &nbsp;</span>, or <span> &nbsp;</span>
                  <a
                    href="/privacyNotice"
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    Privacy Notice
                  </a>
                  <span> &nbsp;</span>
                  to learn{" "}
                </Typography>
                <Typography className={classes.para2}>
                  about our site and online lotteries.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} >
              <ContactUSDetail dataValue={adminContact} />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default ContactUS;
