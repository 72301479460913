import React from 'react';
import KYCForm from '../components/KYCform';

export const KYCform = () => {
    return (
        <div>
            <KYCForm/>
        </div>
    )
}
