import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import lotteryspinner from "@/assets/lotteryspinner.svg";
import RecentActivities from "@/features/Dashboard/components/recentActivities";
import Logo from "@/assets/tableLogo.svg";
import readme from "@/assets/readme.svg";
import { theme } from "@/components/Elements/theme";
import comingsoon from "@/assets/coming_soon_logo.png";
import { useNavigate } from "react-router-dom";
import yellowt from "@/assets/yellow-t.png";
import UpcomingLotteryCrousel from "@/features/Homescreen/Components/upcomingLotteryCrousel";
import UpComingLotteryDraws from "@/features/Homescreen/Components/upcomingLotteryDraws";
import WinningHistory from "@/features/Homescreen/Components/winningHistory";
import { useLotteries } from "../api/getDashboardList";
import whitet from "@/assets/white-t.png";
import Countdown from "react-countdown";
import { any } from "zod";
const useStyles = makeStyles((_theme) => ({
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    maxHeight: "282px",
    maxWidth: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      minHeight: "200px",
      minWidth: "200px",
    },
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  tableContainer: {
    background: "#050518",
    borderRadius: 8,
  },
  table: {
    minWidth: 650,
  },
  tableHeader: {
    color: "#fff",
    padding: "22px !important",
    [theme.breakpoints.down("lg")]: {
      padding: "18px !important",
    }, 
    [theme.breakpoints.down("md")]: {
      padding: "15px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px !important",
    },
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  logo: {

    [theme.breakpoints.down('md')]: {
      height: "45px",
      width: "45px"
    },
  },
}));

export const TableDynamic = ({ dataRecent }) => {
  const classes = useStyles();
  const LotteryQuery = useLotteries({});
  const upcomingArray = LotteryQuery?.data;
  let navigate = useNavigate();

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer} sx={{maxHeight:{ xs: "300px !important", sm: "255px" }, minHeight:"100%",}}>
        {dataRecent?.firstupComRecords[0]?.drawPrize?.length > 0 ? (
          <Table
            className={classes.table}
            aria-label="custom table"
            sx={{ p: { xs: "10px", sm: "25px" }, minWidth: "290px !important" }}
          >
            <TableHead sx={{ backgroundColor: "#0D0D36 !important" }}>
              <TableRow>
                <TableCell className={classes.tableHeader}>
                  <img src={Logo} alt="table logo" className={classes.logo} />
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    sx={{
                      color: "#FFFFFF",
                      fontSize: "13px",
                      p: {
                        sm: "0px !important",
                        md: "0px !important",
                        lg: "0px !important",
                      },
                      textAlign: "center",
                    }}
                  >
                    Total Prize-pool:
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  <Typography
                    variant="h1"
                    gutterBottom
                    sx={{
                      color: "#FFFFFF",
                      p: {
                        sm: "16px !important",
                        md: "0px !important",
                        lg: "0px !important",
                      },
                      textAlign: "end",
                      float: "right",
                      display: "flex",
                    }}
                  >
                    {dataRecent?.firstupComRecords[0]?.drawPrize.length > 0 && (
                      <img
                        style={{
                          height: "auto",
                          width: "31px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginRight: "7px",
                        }}
                        src={whitet}
                      />
                    )}
                    <CurrencyFormat
                      value={
                        dataRecent?.firstupComRecords[0]?.drawPrize.length > 0
                          ? dataRecent?.firstupComRecords[0]?.drawPrize.reduce(
                              (acc, curr) => acc + parseInt(curr),
                              0
                            )
                          : 0
                      }
                      thousandSeparator={","}
                      displayType={"text"}
                      decimalSeparator={"."}
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRecent?.firstupComRecords[0]?.drawPrize.map((row, index) => (
                <TableRow key={index + 1}>
                  <TableCell
                    colSpan={2}
                    className={classes.tableData}
                    sx={{ p: "4px" }}
                  >
                    <ul style={{ margin: "0px" }}>
                      <li>{index + 1 + "th spin"}</li>
                    </ul>
                  </TableCell>
                  <TableCell
                    className={classes.tableData}
                    sx={{ textAlign: "center", p: "4px" }}
                  >
                    <span>
                      <img
                        style={{
                          height: "auto",
                          width: "17px",
                          marginRight: "4px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        src={yellowt}
                      />
                      <CurrencyFormat
                        value={
                          dataRecent?.firstupComRecords[0]?.drawPrize[index]
                        }
                        thousandSeparator={","}
                        displayType={"text"}
                        decimalSeparator={"."}
                      />
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div
            style={{
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              border: "1px solid",
              textAlign: "center",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={comingsoon}
              alt="Coming Soon"
              style={{
                height: "175px",
                width: "150px",
              }}
            />
          </div>
        )}
      </TableContainer>
    </>
  );
  
};

export default TableDynamic;
