import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { termsList } from '../types';

export const getDonationList = async (): Promise<termsList[]> => {
  const response = await axios.get(`/api/v1/user/userDonation`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getDonationList;

type UseDonationListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useDonationList = ({  config }: UseDonationListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getDonationList(),
    ...config,
  });
};