/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { emailList } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';

export type postEmailDTO = {
    emailId: string;
  confirmEmailId: string;
};



export const postEmailVerify = (data: postEmailDTO): Promise<emailList> => {
  return axios.post('/api/v1/user/changeProfileEmail', data);
};




type postEmailVerifyOptions = {
  config?: MutationConfig<typeof postEmailVerify>;
};


export const verifyEmailFnData = ({ config }: postEmailVerifyOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      addNotification({
        type: 'success',
        title: 'Email changed',
      });
    },
    ...config,
    mutationFn: postEmailVerify,
  });
};
