import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { getLotteryById } from '../types';

export const getLotteryByIds = ({ id }: { id: string |undefined }): Promise<getLotteryById> => {
  return axios.get(`/api/v1/user/lotteryDetailById/`+ id, {
  });
};

export const getLotteryCountById = ({ id }: { id: string |undefined }): Promise<getLotteryById> => {
  return axios.get(`/api/v1/user/ticketcount/`+ id, {
  });
};

type QueryFnType = typeof getLotteryByIds;


type QueryFnCountType = typeof getLotteryCountById;

type UseLotteryCountByIdOptions = {
    id: string | undefined;
  config?: QueryConfig<QueryFnType>;
};

export const useLotteryCountById = ({id, config }: UseLotteryCountByIdOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['lotteryById', id],
    queryFn: () => getLotteryCountById({ id }),
    ...config,
  });
};

type UseLotteryByIdOptions = {
    id: string | undefined;
  config?: QueryConfig<QueryFnType>;
};

export const useLotteryById = ({id, config }: UseLotteryByIdOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['lotteryById', id],
    queryFn: () => getLotteryByIds({ id }),
    ...config,
  });
};