import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
        background: "rgba(5, 5, 24, 0.2)",
        borderRadius: "10px",
    },
    paperClass: {
        padding: theme.spacing(2),
        textAlign: "center",
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px"
    },
    first: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: 467
    },
    second: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: 150
    },
    parentPaper: {
        padding: theme.spacing(2),
        margin: "auto",
        maxWidth: 1600
    },
    availableTickets: {
        border: "0.5px solid rgba(255, 255, 255, 0.2)",
        borderRadius: "10px",
        height: 110
    },
    noOfTickets: {
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px",
        height: 180
    },
    tableContainer: {
        background: "#050518",
        borderRadius: 8,
    },
    table: {
        minWidth: 650,
    },
    tableHeader: {
        color: '#fff',
    },
    tableData: {
        fontSize: '14px',
        color: '#fff',
        opacity: "0.6"
    },
    logo: {
        width: 50,
    },
    myGridItem: {
        backgroundColor: "rgba(5, 5, 24, 0.8)",
        padding: '30px',
        borderRadius: '20px',
    },
    innerGrid: {
        backgroundColor: "#050518",
        padding: '10px',
        borderRadius: '20px',
    },
    innerGridFirst: {
        backgroundRepeat: "no-repeat",
        height: "auto",
        width: "auto",
        minHeight: "282px",
        minWidth: "282px",
        backgroundPosition: 'left bottom',
    },
    innerGridSecond: {
        backgroundColor: "#050518",
        padding: '10px',
        borderRadius: '20px',
    },
}));
export default function Notes() {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Stack flexDirection={{ xs: 'column', md: 'row' }} sx={{gap:"20px", padding:"0 0 0 20px"}}  >
                        <Typography variant='h6' sx={{ alignSelf:"center"}}>Notes</Typography>
                        <Divider orientation="vertical" sx={{ background: "#3C37FF", height: "auto", border: "1px solid #3C37FF" , margin:"10px 10px", alignSelf:"stretch"}} />
                        <Typography
                            component="h6"
                            variant="caption"
                            sx={{ color : "#FFFFFF" }}
                        >
                            <ul style={{paddingLeft:"10px"}}>
                                <li>We generate your ticket numbers total randomized after the ticket sales are closed. Your numbers will appear on your tickets </li>
                                <li>When you buy multiple tickets, your winchance will increase substantially. Play responsible! </li>
                                <li>Example: If you buy 1 ticket in a 500.000 lottery, your winchance = 1:500.000. If you buy 10 tickets in a 500.000 lottery, your winchance = 1:50.000 
                                </li>
                                <li>This winchance is on all the different Spins in the lottery.</li>
                            </ul>
                        </Typography>

                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}

