// import { useQuery } from 'react-query';

// import { axios } from '@/lib/axios';
// import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

// import { LiveLotteries } from '../types';

// export const getLottery = async (): Promise<LiveLotteries[]> => {
//   const response = await axios.get(`/api/v1/user/livelotteries`, {
//   });
//   const data = response
//   return data.data.replayLotteries;
// };
// //Response.data.upc
// type QueryFnType = typeof getLottery;

// type UseLotteriesOptions = {
//   config?: QueryConfig<QueryFnType>;
// };

// export const useLotteries = ({  config }: UseLotteriesOptions) => {
//   return useQuery<ExtractFnReturnType<QueryFnType>>({
//     queryKey: ['data'],
//     queryFn: () => getLottery(),
//     ...config,
//   });
// };



import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { LiveLotteriesResponse } from '../types';

// Updated getLottery to return the data directly
export const getLottery = async (page = 1, limit = 10) => {
  const response:any = await axios.get(`/api/v1/user/livelotteries`, {
    params: { page, limit },
  });
  return response;  // Return the data object (upcoming, live, replayLotteries)
};

type QueryFnType = typeof getLottery;

type UseLotteriesOptions = {
  page: number;
  limit: number;
  config?: QueryConfig<QueryFnType>;
};

export const useLotteries = ({ page, limit, config }: UseLotteriesOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['lotteries', page],  // Updated queryKey to reflect the data more meaningfully
    queryFn: () => getLottery(page, limit),
    ...config,
  });
};
