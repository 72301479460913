import {
  Box,
  Card,
  CardContent,
  Paper,
  FormControl,
  Stack,
  Typography,
  Divider,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import { usePayment } from "../api/fetchPaymentCurrency";
import storage from "@/utils/storage";
import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface FormValues {
  cryptoType: string;
}
interface CoinRateItem {
  currency: string;
  paymentMethodId: string;
  image?: string;
}
interface ErrorState {
  cryptoType?: string;
  amount?: any;
  walletAddress?: string;
}

interface depositCrypto {
  getDepositAddress: string;
  qrCodeUrl: string;
}

const useStyles = makeStyles({
  kycContent: {
    maxWidth: "700px",
    color: "white",
    textAlign: "start",
    "& h1": {
      color: "#3C37FF",
      fontSize: "23px",
    },
    "& strong": {
      color: "white",
    },
    "& p, & ul, & ol": {
      color: "white",
    },
    "& ul, & ol": {
      margin: "10px 0",
      paddingLeft: "20px",
    },
    "& ul ul, & ul ol, & ol ul, & ol ol": {
      paddingLeft: "20px",
    },
  },
  sizee: {
    fontSize: "0.8rem", // Slightly larger text
    lineHeight: "1.6", // Improved line spacing for readability
  },
});

export const BankAccounts = () => {
  const classes = useStyles();

  const [error, setError] = useState<ErrorState>({});
  const [isAddressAvailable, setIsAddressAvailable] = useState(false);
  const [lodingQrLoding, setLodingQrLoding] = useState(false);
  const [depositCrypto, setDepositCrypto] = useState<depositCrypto | null>(
    null
  );

  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({ cryptoType: "" });
  const [coinRate, setcoinRate] = useState<CoinRateItem[]>([]);
  const depositAddressRef = useRef("");
  const [address, setAddress] = useState("");
  const [copyStatus, setCopyStatus] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "flex",
    transitionDuration: "0.3s",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };
  useEffect(() => {
    // Check if the address is available and set the flag accordingly
    if (depositCrypto?.getDepositAddress) {
      setIsAddressAvailable(true);
    }
  }, [depositCrypto?.getDepositAddress]);
  useEffect(() => {
    const fetchCurrencyData = async () => {
      setLoadingCurrency(true);
      let token = storage.getToken();
      try {
        const currencyResponse = await axios.get(
          `${process.env.API_URL_PREFIX}/api/v1/user/fetchPaymentCurrency`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        const currencyData = currencyResponse?.data?.data;

        setcoinRate(currencyData);
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLoadingCurrency(false);
      }
    };

    fetchCurrencyData();
  }, []);

  useEffect(() => {
    const fetchCoinRateData = async () => {
      setLodingQrLoding(true);
      let token = storage.getToken();

      try {
        const depositApiResponse = await axios.post(
          `${process.env.API_URL_PREFIX}/api/v1/user/depositCrypto`,
          { cryptoType: formValues.cryptoType },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        const qrData = depositApiResponse?.data;
        console.log(qrData, "qrData");
        setDepositCrypto(qrData); // Make sure depositCrypto is set properly
        depositAddressRef.current = qrData?.getDepositAddress || ""; // Optionally store in ref
      } catch (error) {
        console.error("Error fetching coin rate:", error);
      } finally {
        setLodingQrLoding(false); // Only stop loading when data is ready
      }
    };

    fetchCoinRateData();
  }, [formValues.cryptoType]); // Ensure this runs on form value change

  console.log(depositCrypto?.getDepositAddress, "depositCrypto");
  useEffect(() => {
    console.log("Loading state:", lodingQrLoding);
    console.log("Deposit address:", depositCrypto?.getDepositAddress);
  }, [lodingQrLoding, depositCrypto]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "cryptoType") {
      const selectedCoin = coinRate.find((item) => item.currency === value);

      setFormValues({
        ...formValues,
        cryptoType: value,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const checkValidation = (type) => {
    let isFormValid = true;
    let newError = { ...error };

    if (type === "cryptoType" || type === "all") {
      if (!formValues?.cryptoType || formValues?.cryptoType === "select") {
        newError.cryptoType = "Please select a cryptocurrency";
        isFormValid = false;
      } else {
        newError.cryptoType = "";
      }
    }

    setError(newError);
    return isFormValid;
  };

  const handleBlur = () => {
    checkValidation("cryptoType");
  };
  useEffect(() => {
    if (depositCrypto?.getDepositAddress) {
      setAddress(depositCrypto.getDepositAddress);
    }
  }, [depositCrypto]);

  const handleCopyAddress = () => {
    if (address) {
      navigator.clipboard
        .writeText(address)
        .then(() => {
          toast.success("Address copied!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        })
        .catch((err) => {
          toast.error("Failed to copy address", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  };

  return (
    <Grid item xs={6} md={6}sx={{ display: "flex", justifyContent: "center", marginTop: "1%" }}>
      {" "}
      <Card
        style={cardStyle}
        sx={{ p: theme.spacing(0.2), m: theme.spacing(0.2) }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Item sx={{ display: "contents" }}>
              {" "}
              <Typography variant="h5">Your wallet receive address</Typography>
              <Typography
                variant="h6"
                sx={{ color: "#7E7E7E", textTransform: "uppercase" }}
                style={{ fontSize: "12px" }}
              >
                Note: You can only send USDT/USDC on ERC20 network!
              </Typography>
            </Item>
            <Divider sx={{ border: "1px solid rgba(255, 255, 255, 0.2)" }} />
            <Typography variant="h6" sx={{ display: "block", mt: 2 }}>
              Select Crypto-Currency
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                defaultValue="select"
                name="cryptoType"
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "black",
                      "& .MuiMenuItem-root": {
                        padding: 1,
                        fontFamily: "Ubuntu",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "14px",
                      },
                    },
                  },
                }}
              >
                <MenuItem disabled value="select">
                  <em>Select</em>
                </MenuItem>

                {loadingCurrency ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                    <Typography ml={2}>Loading...</Typography>
                  </MenuItem>
                ) : (
                  coinRate.map((item) => (
                    <MenuItem
                      key={item.paymentMethodId}
                      value={item.currency}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          width: "100%",
                        }}
                      >
                        {item.image && (
                          <img
                            src={item?.image}
                            alt={item.currency}
                            style={{ width: 24 }}
                          />
                        )}
                        <Typography textAlign="left" sx={{ fontSize: "20px" }}>
                          {item.currency}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))
                )}
              </Select>
              {error?.cryptoType && (
                <Typography sx={{ color: "red" }}>
                  {error.cryptoType}
                </Typography>
              )}
            </FormControl>
            <Typography variant="h6" sx={{ display: "block", mt: 2 }}>
              Copy your wallet address
            </Typography>
            <Item>
              {" "}
              <TextField
                fullWidth
                value={
                  lodingQrLoding ? "" : depositCrypto?.getDepositAddress || ""
                }
                InputProps={{
                  readOnly: true,
                  sx: { color: "#fff" },

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onMouseDown={(e) => {
                          e.preventDefault();
                        }}
                        onClick={() => {
                          console.log("abhay Pratap singh");

                          handleCopyAddress();
                        }}
                        edge="end"
                        disabled={lodingQrLoding}
                      >
                        {lodingQrLoding ? (
                          <CircularProgress size={24} sx={{ color: "#fff" }} />
                        ) : (
                          <ContentCopyIcon sx={{ color: "#fff" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Item>
            <Grid item xs={12}>
              <h4 style={{ color: "white", textAlign: "center" }}>
                Scan the QR CODE
              </h4>
              <div style={{ textAlign: "center" }}>
                {lodingQrLoding ? (
                  <CircularProgress size={48} sx={{ color: "#fff" }} />
                ) : (
                  <img
                    src={depositCrypto?.qrCodeUrl}
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                )}
              </div>
            </Grid>
            <Box
              sx={{
                backgroundColor: "rgba(5, 5, 24, 0.8)",
                borderRadius: "10px",
              }}
            />
            <Box sx={{ p: { xs: "34px", md: "50px" } }}>
              <Grid item xs={12}>
                <Card sx={{ margin: "0 auto", background: "none" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: "920px",

                      margin: "auto",
                      overflow: "auto",
                    }}
                  >
                    <div className={classes.kycContent}>
                      <p className={classes.sizee}>Please note!:</p>

                      <p className={classes.sizee}>
                        The deposit amount does not include the transfer fee
                        amount. Please make sure you transfer the amount with
                        the transfer fee!
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid>
            </Box>
            {/* <Item>
              <Grid xs={12} md={12}>
                {" "}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Confirm Now
                </Button>
              </Grid>
            </Item> */}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};
