import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './lib/auth';
import '@sweetalert2/theme-material-ui';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useMediaQuery } from '@mui/material';
import './App.css';


function App() {

  // const [open, setOpen] = React.useState(false);
  // const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
  // const isLandscape = useMediaQuery('(orientation: landscape)');

  // const handleOrientationChange = () => {
  //   if (isMobileDevice && !isLandscape) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // useEffect(() => {
  //   handleOrientationChange(); // Call the function on initial load

  //   window.addEventListener('orientationchange', handleOrientationChange);

  //   return () => {
  //     window.removeEventListener('orientationchange', handleOrientationChange);
  //   };
  // }, []);
  useEffect(() => {
    const handleMessage = (event) => {

      if (event && event?.data?.token !== undefined) {

        console.log("Full Message Received:", event?.data);
        localStorage.setItem("crypto_react_token", JSON.stringify(event?.data?.token.replace('Bearer ', '')));
        localStorage.setItem("crypto_react_userid", JSON.stringify(event?.data?.crypto_react_userid));

      }





    };

    // Listen for messages from the iframe
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);


  return (
    <AppProvider>
      <AppRoutes />
      <ToastContainer />
      {/* <Dialog className='modalForScreenIndication' open={open} onClose={handleClose}>
        <DialogContent  sx={{background:"black", overflow:"hidden"}}>
          <DialogContentText sx={{color:"white", textAlign:"center"}}>
          This website is only viewable in landscape mode. 
          <br></br>Please turn your phone and adjust your keyboard settings.
          </DialogContentText>
        </DialogContent>
        <DialogActions  sx={{background:"black" , display:'flex' , justifyContent:'center'}}>
          <Button  variant="contained" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog> */}
    </AppProvider>
  );
}

export default App;



