import React, { useMemo, useState } from 'react';
import { Box, Paper, Typography, Grid, Divider, Stack, Button, Menu, InputLabel } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { MantineProvider } from '@mantine/core';
import { MRT_ColumnDef, MantineReactTable } from 'mantine-react-table';
import "./account.css";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
export type Payment = {
    CreationDate: string,
    Id: string,
    DocumentType: string,
    Status: string,
    Comment: string,
}
const useStyles = makeStyles((theme) => ({
    flexWrap: {
        flexWrap: "wrap",
    },
    dFlex: {
        display: "flex",
    },
    justifyEnd: {
        justifyContent: "end"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    justifyBetween: {
        justifyContent: "space-between"
    },
    container: {
        margin: "20px 82px",
        [theme.breakpoints.down('sm')]: {
            margin: "0px 0px",
        },
    },
    forMedia: {
        [theme.breakpoints.down('md')]: {
            display: "block",
            margin: "auto",
            maxWidth: "282px"
        },
    },
    heading1: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "23px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.38px",
        color: "#FFFFFF"
    },
    para1: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.38px",
        textTransform: "uppercase",
        color: "#FFFFFF",
        opacity: 0.5,
        marginTop: "15px",
        marginBottom: "5px"
    },
    blueColor: {
        color: "#3C37FF !Important",
    },
    heading2: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "25px",
        lineHeight: "140%",
        color: "#FFFFFF"
    },
    para2: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize",
        fontFeatureSettings:
            "'cv05' on, 'cv10' on, 'cv02' on, 'cv03' on, 'cv04' on, 'ss03' on, 'ss04' on, 'ss01' on, 'kern' off, 'calt' off",
        color: "#FFFFFF"
    },
    darkBlue: {
        background: "#050518",
        padding: "60px",
        borderRadius: "10px"
    },
    width50: {
        width: "50%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    width60: {
        width: "60%"
    },
    heading3: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase"
    },
    para3: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
        textTransform: "capitalize",
        color: "rgba(255, 255, 255, 0.6)",
        opacity: 0.9,
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: "2px"
    },
    para4: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        display: "flex",
        alignItems: "center",
        color: "rgba(255, 252, 252, 0.6)",
    },
    myProfile: {
        background: "rgba(5, 5, 24, 0.8)",
        mixBlendMode: "normal",
        borderRadius: "10px",
        // width: "50%",
        padding: "30px"
    },
    profileEditButton: {
        background: "transparent",
        border: "none",
        position: "relative",
        left: "-35px"
    },
    blueButton: {
        padding: "17px 16px",
        background: "#3C37FF",
        borderRadius: "10px",
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        border: "none",
        marginTop: "37px",
        width: "132px"
    },
    ChangePassword: {
        background: "rgba(5, 5, 24, 0.8)",
        mixBlendMode: "normal",
        borderRadius: "10px",
        width: "30%",
        padding: "30px",
        marginLeft: "30px",
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0px",
            marginTop: "30px"
        },
    },
    cancleButton: {
        padding: "17px 16px",
        borderRadius: "10px",
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        marginTop: "37px",
        width: "110px",
        background: "transparent",
        border: "1px solid white",
        marginLeft: "10px"
    }
}));

export const Account = () => {
    const classes = useStyles();
    const [selected, setSelected] = useState("Select Document");

    function handleChange(event) {
        event.preventDefault()
        setSelected(event.target.value);
    }

    const columns = useMemo<MRT_ColumnDef<Payment>[]>(
        () => [
            {
                accessorKey: 'CreationDate',
                header: 'Creation Date',
                size: 100,
                //custom conditional format and styling
            },

            {
                accessorKey: 'Id',
                header: 'Id',
                size: 100,
                //custom conditional format and styling
            },
            {
                accessorKey: 'DocumentType',
                header: 'Last Name',
                size: 100,
                //custom conditional format and styAling
            },
            {
                accessorKey: 'Status', //hey a simple column for once
                header: 'Status',
                size: 100,
                Cell: ({ cell }) => (
                    <Box
                        sx={(theme) => ({
                            color:
                                cell.getValue<string>() === 'Approved'
                                    ? '#95D23D'
                                    : cell.getValue<string>() === 'Pending'
                                        ? '#FF9900'
                                        : '#FF4545',
                            borderRadius: "4px",
                            border: cell.getValue<string>() === 'Approved'
                                ? '1px solid rgba(149, 210, 61, 0.41)'
                                : cell.getValue<string>() === 'Pending'
                                    ? '1px solid rgba(231, 214, 61, 0.5)'
                                    : '1px solid rgba(255, 69, 69, 0.4)',
                            backgroundColor: cell.getValue<string>() === 'Approved'
                                ? 'rgba(149, 210, 61, 0.19)'
                                : cell.getValue<string>() === 'Pending'
                                    ? 'rgba(231, 214, 61, 0.1)'
                                    : 'rgba(255, 69, 69, 0.05)',
                            maxWidth: '9ch',
                            padding: '4px',
                        })}
                    >
                        {cell.getValue<string>()}
                    </Box>
                ),
            },
            {
                accessorKey: 'Comment',
                header: 'Comment',
                size: 100,
                //custom conditional format and styAling
            },
        ],
        [],
    );

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    return (
        <>
            <Grid container spacing={1} className={`${classes.dFlex}`} sx={{ mt: "20px" }}>
                <Box>
                    <Grid container spacing={3} sx={{ mt: "38px" }}>
                        <Grid item xs={12}  >
                            <Typography variant='h6'>Account Verification:</Typography>
                            <Stack flexDirection={{ xs: 'column', md: 'row' }} >

                                <Divider orientation="vertical" sx={{ background: "#3C37FF", height: "auto", border: "1px solid #3C37FF" }} />
                                <Typography
                                    component="h6"
                                    variant="caption"
                                    sx={{ color: "#FFFFFF" }}
                                >
                                    Dear user, in order to validate your account you need to upload a personal identification document, proof of address document (utility bill). While uploading, make sure it corresponds to the following criteria:
                                    <ul>
                                        <li> The file is in JPG, PNG, PDF format and doesn't exceed 700KB. </li>
                                        <li>The document is valid. </li>
                                        <li>The picture should be in colour, not black and white.
                                        </li>
                                        <li>The document picture, personal data, signature, seal and other information should be clearly readable.</li>
                                        <li>If necessary we may ask you to present additional documents.</li>
                                    </ul>
                                </Typography>

                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <br />
            <FormControl style={{ width: "100%" }}>
                <div style={{ width: "100%", display: "flex" }}>

                    <div style={{ width: "50%", display: "flex" }} className='col-6 d-flex'>
                        <div>

                            <h4 style={{ color: "white" }}>Upload KYC Document</h4>
                            <Select  value={selected} onChange={handleChange}>
                                <MenuItem value="Select Document" disabled>Select Document</MenuItem>
                                <MenuItem value="Passport">Passport</MenuItem>
                                <MenuItem value="Driving Licence">Driving Licence</MenuItem>
                                <MenuItem value="ID Card">ID Card</MenuItem>
                                <MenuItem value="Electricity Bill">Electricity Bill</MenuItem>
                            </Select>
                        </div>
                        <Button component="label" className='my-auto' style={{ color: "blueviolet" }} startIcon={<CloudUploadIcon />}>
                            Upload file
                            <VisuallyHiddenInput type="file" />
                        </Button>
                    </div>
                    {/* <div style={{ marginTop: "30px" }}>
                    </div> */}
                    <div style={{ width: "100%" }} className='col-6'>
                        <Button variant="contained">Verify</Button>
                    </div>
                </div>
            </FormControl>
        </>
    )
}