import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import {
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
} from "@mui/material";
import { css } from "@emotion/react";
import footerLogo from "@/assets/footerLogo.png";
import logoCrypto from "@/assets/logoCrypto.svg";
import { theme } from "./theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  footerMid:{
    flexGrow: 1,
    justifyContent: "space-between",
    display: "flex",
    flexFlow:"row wrap",
    mb: 2,
    "& .MuiList-root":{
        [theme.breakpoints.down("sm")]:{
          width: "50%",
        }
    }
  },
}));
export default function Footer() {
  const classes = useStyles();
  return (
    <Paper
      sx={{
        // marginTop: 'calc(10% + 10px)',
        bottom: 0,
        backgroundColor: " #050518",
        // padding: { xs: theme.spacing(0.1) },
        // paddingLeft: { md: theme.spacing(1) },
        padding:"10px 20px"
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Grid container spacing={3} sx={{marginBottom:'20px'}}>
        <Grid item xs={12} sm={12} md={2} xl={2}>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-start",
              display: "flex",
              my: 1,
              [theme.breakpoints.down("sm")]:{
                textAlign:"center"
              }
            }}
          >
            <Stack spacing={2} flex="1 0 1">
              <List sx={{ pt: 0, }}>
                <ListItem sx={{ pt: 0 }}>
                  <Link underline="none" href="#home">
                    <img src={logoCrypto} style={{ height: "76px", margin:"auto" }} />
                  </Link>
                </ListItem>
                <ListItem>
                  <Link underline="none" href="#company">
                    <Typography
                      component="h3"
                      variant="h3"
                      sx={{ opacity: "0.6" }}
                    >
                      ADDRESS
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link underline="none" href="#portfolio">
                    <Typography
                      component="h3"
                      variant="h3"
                      sx={{ opacity: "0.6" }}
                    >
                      Klimentská 46, 110 02 Petrská čtvrť,
                      <br /> Prague Czech Republic
                    </Typography>
                  </Link>
                </ListItem>
              </List>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={7} xl={5}>
          <Box className={`${classes.footerMid}`}>
            <List>
              <ListItem>
                <Link underline="none" href="#home">
                  <Typography className="footerLinkText" component="h3" variant="h3">
                    POLICIES
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/termsofUse">
                  <Typography
                    component="h3"
                    variant="h3"
                    className="footerLinkText"
                    sx={{ opacity: "0.6" }}
                  >
                    Terms of Use
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/privacyNotice">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Privacy Notice
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/KYC">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    KYC
                  </Typography>
                </Link>
              </ListItem>

              <ListItem>
                <Link underline="none" href="/responsibleGaming">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Responsible Gaming
                  </Typography>
                </Link>
              </ListItem>
            </List>

            <List>
              <ListItem>
                <Link underline="none" href="#home">
                  <Typography className="footerLinkText" component="h3" variant="h3">
                    ABOUT US
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/aboutus">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    About Us
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/contactUs">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Contact Us
                  </Typography>
                </Link>
              </ListItem>
            </List>

            <List>
              <ListItem>
                <Link underline="none" href="#home">
                  <Typography className="footerLinkText" component="h3" variant="h3">
                    QUICK LINKS
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/rules">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Rules
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/Licensing">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Licensing
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/faq">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    FAQs
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none" href="/donations">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Donations
                  </Typography>
                </Link>
              </ListItem>
            </List>

            <List>
              <ListItem>
                <Link underline="none" href="#home">
                  <Typography component="h3" className="footerLinkText" variant="h3">
                    PAYMENTS
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link underline="none"  href="/app/wallet/MyLottery?option=deposit">
                  <Typography
                    component="h3"
                    variant="h3"
                    sx={{ opacity: "0.6" }} className="footerLinkText"
                  >
                    Crypto Currency
                  </Typography>
                </Link>
              </ListItem>
              <ListItem>
              <Link underline="none"  href="/app/wallet/MyLottery?option=buyCrypto">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} className="footerLinkText">
              Fiat Payments</Typography>
              </Link>
            </ListItem>
            <ListItem>
              <Link underline="none"  href="/app/SWAP">
              <Typography component="h3" variant="h3" sx={{opacity:"0.6"}} className="footerLinkText">
              Buy G2F Tokens</Typography>
              </Link>
            </ListItem>
            {/*  */}
            </List>

          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3} xl={5} className="footerLogoBootom" 
          sx={{ 
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={footerLogo}
            alt="footerLogo"
            style={{ textAlign: "center", maxWidth: "188px", maxHeight:"126px", width:"auto",padding:"10px 0 0 16px" 
            }}
            
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
