import * as React from "react";
import * as z from "zod";
import { useAuth } from "@/lib/auth";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import logoCrypto from "@/assets/logoCrypto.svg";
import {
  TextField,
  Checkbox,
  Link,
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  useTheme,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import inActiveEye from "@/assets/inActiveEye.svg";
import blueEye from "@/assets/blueEye.svg";
import inActiveBlueEye from "@/assets/inActiveBlueEye.svg";
import inActiveMail from "@/assets/InActiveMail.png";
import activeMail from "@/assets/activeMail.svg";
import { useState } from "react";
import { verifyOTPWithOTP } from "../api/verifyOTP";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

const formSchema = z.object({
  firstName: z.string().min(1, "firstName is required").max(100),
  lastName: z.string().min(1, "lastName is required").max(100),
  nickName: z.string().min(1, "lastName is required").max(100),
  email: z.string().email("Invalid email").min(1, "Email is required"),
  password: z
    .string()
    .min(1, "Password is required")
    .min(8, "Password must have more than 8 characters"),
  terms: z.literal(true, {
    errorMap: () => ({ message: "You must accept the terms and conditions" }),
  }),
});

type RegisterValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  nickName: string;
  promocode: any;
};

const initialValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  nickName: "",
  promocode: "",
};

type FormSchemaType = z.infer<typeof formSchema>;

type RegisterFormProps = {
  onSuccess: (email: any) => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  const theme = useTheme();
  const [Icon, setIcon] = React.useState(inActiveMail);
  const [passwordIcon, setPasswordIcon] = React.useState(inActiveEye);
  const [isOpen, setisOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const promoCode = searchParams.get("couponCode");
  console.log("promoCode", promoCode);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    nickName: "",
    promocode: promoCode,
  });
  const [errors, setErrors] = useState<z.ZodIssue[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
    if (showPassword === true) setPasswordIcon(inActiveBlueEye);
    else setPasswordIcon(blueEye);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let newFormValues = Object.assign(formValues, { terms: terms });
    const result = formSchema.safeParse(formValues);
    let { email, password } = newFormValues;
    if (result.success) {
      onSubmit(formValues);
    } else {
      setErrors(result.error.issues);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await verifyOTPWithOTP(data);
      onSuccess(formValues.email);
      setisOpen(true);
    } catch (err: any) {
      const message = err.response?.data?.message || err.message;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        className="mervlogo"
        sx={{ paddingRight: "10px", paddingTop: "14px" }}
      >
        <Link variant="h3" href="/">
          <img src={logoCrypto} className="logoImage" />
        </Link>
      </Box>
      <Typography component="h1" variant="h1" sx={{ mt: 1 }}>
        Create your Account
      </Typography>
      <Box sx={{ mt: 3.25 }}>
        <form onSubmit={handleFormSubmit}>
          <Grid container>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="firstName"
                    shrink={true}
                    sx={{ color: "#FFFFFF" }}
                  >
                    First Name
                  </InputLabel>
                  <TextField
                    margin="normal"
                    required
                    autoFocus
                    id="firstName"
                    name="firstName"
                    autoComplete="firstName"
                    onChange={handleChange}
                    value={formValues.firstName}
                    placeholder="Enter First Name"
                    inputProps={{
                      inputMode: "text", // Ensures the text keyboard is displayed on mobile
                    }}
                  />
                  {errors.find((err) => err.path[0] === "firstName") && (
                    <div style={{ color: "#FF0000", fontSize: "14px" }}>
                      {
                        errors.find((err) => err.path[0] === "firstName")
                          ?.message
                      }
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="lastName"
                    shrink={true}
                    sx={{ color: "#FFFFFF" }}
                  >
                    Last Name
                  </InputLabel>
                  <TextField
                    margin="normal"
                    required
                    id="lastName"
                    name="lastName"
                    autoComplete="lastName"
                    onChange={handleChange}
                    value={formValues.lastName}
                    placeholder="Enter Last Name"
                    inputProps={{
                      inputMode: "text", // Ensures the text keyboard is displayed on mobile
                    }}
                  />
                  {errors.find((err) => err.path[0] === "lastName") && (
                    <div style={{ color: "#FF0000", fontSize: "14px" }}>
                      {
                        errors.find((err) => err.path[0] === "lastName")
                          ?.message
                      }
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth={true}>
                  <InputLabel
                    htmlFor="nickname"
                    shrink={true}
                    sx={{ color: "#FFFFFF" }}
                  >
                    Nick Name
                  </InputLabel>
                  <TextField
                    margin="normal"
                    required
                    id="nickName"
                    name="nickName"
                    autoComplete="nickName"
                    onChange={handleChange}
                    value={formValues.nickName}
                    placeholder="Enter Nick Name"
                    inputProps={{
                      inputMode: "text", // Ensures the text keyboard is displayed on mobile
                    }}
                  />
                  {errors.find((err) => err.path[0] === "nickName") && (
                    <div style={{ color: "#FF0000", fontSize: "14px" }}>
                      {
                        errors.find((err) => err.path[0] === "nickName")
                          ?.message
                      }
                    </div>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ pt: 2 }}>
              <FormControl fullWidth={true}>
                <InputLabel
                  htmlFor="email"
                  shrink={true}
                  sx={{ color: "#FFFFFF" }}
                >
                  Email Address
                </InputLabel>
                <TextField
                  margin="normal"
                  required
                  id="email"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  value={formValues.email}
                  placeholder="Enter your Mail Id"
                  onFocus={(e) => {
                    setIcon(activeMail);
                  }}
                  onBlur={(e) => {
                    setIcon(inActiveMail);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment disableTypography position="end">
                        <img src={Icon} alt="inactiveEye" />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    inputMode: "text", // Ensures the text keyboard is displayed on mobile
                  }}
                />
                {errors.find((err) => err.path[0] === "email") && (
                  <div style={{ color: "#FF0000", fontSize: "14px" }}>
                    {errors.find((err) => err.path[0] === "email")?.message}
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ pt: 2 }}>
              <FormControl fullWidth={true}>
                <InputLabel
                  htmlFor="password"
                  shrink={true}
                  sx={{ color: "#FFFFFF" }}
                >
                  Password
                </InputLabel>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  autoComplete="password"
                  onChange={handleChange}
                  value={formValues.password}
                  placeholder="Enter your Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        disableTypography
                        position="end"
                        onClick={handlePassword}
                      >
                        <img src={passwordIcon} alt="inactiveEye" />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    inputMode: 'text', // Ensures a text keyboard is displayed on mobile
                  }}
                  onFocus={(e) => {
                    setPasswordIcon(blueEye);
                  }}
                  onBlur={(e) => {
                    setPasswordIcon(inActiveEye);
                  }}
                />
                {errors.find((err) => err.path[0] === "password") && (
                  <div style={{ color: "#FF0000", fontSize: "14px" }}>
                    {errors.find((err) => err.path[0] === "password")?.message}
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
              <FormControl fullWidth={true}>
                <InputLabel
                  htmlFor="promocode"
                  shrink={true}
                  sx={{ color: "#FFFFFF" }}
                >
                  Promocode
                </InputLabel>
                <TextField
                  margin="normal"
                  id="promocode"
                  name="promocode"
                  autoComplete="promocode"
                  aria-required="false"
                  onChange={handleChange}
                  value={formValues.promocode}
                  placeholder="Enter Promocode"
                  inputProps={{
                    inputMode: 'text', // Ensures a text keyboard is displayed on mobile
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs sx={{ p: 0, fontSize: "14px" }}>
              <Checkbox
                value={terms}
                onChange={() => setTerms(!terms)}
                disableFocusRipple={true}
                disableTouchRipple={true}
                sx={{ px: 0, color: "rgba(255, 254, 236, 0.6)" }}
                icon={<CheckBoxOutlineBlankIcon htmlColor="#FFFEEC99" />}
                checkedIcon={<CheckBoxIcon htmlColor="#3C37FF" />}
              />
              <span
                style={{
                  color: "rgba(255, 254, 236, 0.6)",
                  paddingLeft: "6px",
                }}
              >
                I confirm that I’ve read and agree to all
              </span>
              <Link href="#" variant="h3" underline="none" sx={{ pl: "4px" }}>
                Terms and conditions & Privacy Policy
              </Link>
              {errors.find((err) => err.path[0] === "terms") && (
                <div style={{ color: "#FF0000", fontSize: "14px" }}>
                  {errors.find((err) => err.path[0] === "terms")?.message}
                </div>
              )}
            </Grid>
          </Grid>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Register
            </Button>
          )}

          <Grid container>
            <Grid
              item
              xs
              sx={{ p: 0, fontSize: "14px", color: "rgba(255, 255, 255, 0.7)" }}
            >
              Already have an account?
              <Link
                href="/auth/login"
                variant="h3"
                sx={{ pl: "4px" }}
                underline="none"
              >
                Sign In Now
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default RegisterForm;
