/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
//import { MutationConfig } from ';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from 'react-query';

export type resetPasswordCredentialsDTO = {
  newPassword:string;
  confirmPassword: string;
};

export const resetPassword = (data: resetPasswordCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/api/v1/user/resetpassword', data);
};

