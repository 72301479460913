import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { termsList } from '../types';

export const getRulesList = async (): Promise<termsList[]> => {
  const response = await axios.get(`/api/v1/user/userRules`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getRulesList;

type UseRulesListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useRulesList = ({  config }: UseRulesListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getRulesList(),
    ...config,
  });
};