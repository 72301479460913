import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import logoCrypto from "@/assets/logoCrypto.svg";
import SearchIcon from "@mui/icons-material/Search";
import slogan from "@/assets/sloganCrypto.png";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { AppBar, Box, Grid } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import notificationlogo from "@/assets/inactiveNotification.svg";
import { AccountCircle } from "@material-ui/icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import loginIcon from "@/assets/loginIcon.svg";
import dropdownIcon from "@/assets/dropdownIcon.svg";
import world from "@/assets/world.svg";
import AdbIcon from "@mui/icons-material/Adb";
import MenuIcon from "@mui/icons-material/Menu";
import "./CSS/style.css";
import Drawer from "@mui/material/Drawer";
import DehazeRoundedIcon from "@mui/icons-material/DehazeRounded";
import { theme } from "./theme";
declare global {
  interface Window {
    // ⚠️ notice that "Window" is capitalized here
    google: any;
    googleTranslateElementInit: any;
  }
}
const pages = ["Live Lottery", "Lotteries", "Promotions"];
export default function Header() {
  const sections = [
    { title: "ICO", url: "/app/ICO", id: "0" },
    { title: "Promotions", url: "/app/Promotions", id: "0" },
  ];
  const googleTranslateElementInit = () => {
    if (window.google && window.google.translate) {
      window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true,
        },
        "google_translate_element"
      );
    } else {
      console.error("Google Translate API not loaded.");
    }
  };
  React.useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const open = Boolean(anchorEl);
  const openLang = Boolean(anchorElLang);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };
  const [sideBarOpen, setSideBarOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setSideBarOpen(newOpen);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          background: "rgba(19, 20, 32, 0.4)",
          position: "fixed",
          zIndex: "2",
          [theme.breakpoints.down('sm')]:{
            position:"relative"
          },
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap", my: "10px" }}>
          <Box display="flex" alignItems= "center" flexGrow={1} sx={{ background: "transparent" }}>
            <Button
              onClick={toggleDrawer(true)}
              sx={{boxSizing:"border-box",width:"35px",minWidth:"fit-content", marginInlineStart:"10px", "@media screen and (min-width:992px)": { display: "none" } }}
            >
              <DehazeRoundedIcon sx={{ color: "#fff", fontSize: "35px" }} />
            </Button>
            <Link variant="h3" href="/">
              <img src={logoCrypto} className="logoImage" />
            </Link>
            <Box
              sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
            >
              {sections.map((section) => (
                <Link
                  variant="button"
                  color="text.primary"
                  key={section.title}
                  href={section.url}
                  textAlign="center"
                  underline="none"
                  sx={{ my: 1, mx: 1.5, color: "#ffff" }}
                >
                  {section.title}
                </Link>
              ))}
            </Box>
          </Box>
          <Box
            className="img-landscape-mid-logo"
            sx={{ display: { sm: "block", xs: "none" } , "& img":{ "@media screen and (max-width:992px)":{transform: "translate(-70%, -50%) !important", }}}}
          >
            <img
              src={slogan}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)", 
              }}
              alt="slogan"
            />
          </Box> 
          <Box sx={{ display: "flex !important" }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              <img src={loginIcon} alt="login icon"></img>
            </IconButton>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400px",
                  mr: "9px",
                  textTransform: "capitalize",
                }}
              >
                Login
              </Typography>{" "}
              <img src={dropdownIcon} alt="login icon"></img>
            </Button>
            <Menu
              id="basic-menu "
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link href="/auth/login" underline="none">
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "400px", fontStyle: "normal" }}
                  >
                    Login
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link href="/auth/register" underline="none">
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: "400px", fontStyle: "normal" }}
                  >
                    Sign up
                  </Typography>
                </Link>
              </MenuItem>
            </Menu>
            <div className="languageChangeBtn">
              <div id="google_translate_element"></div>
            </div> 
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        open={sideBarOpen} // Use `open` instead of `sideBarOpen`
        onClose={toggleDrawer(false)} // `onClose` will toggle the drawer closed
      >
        <Box className="sideTray">
          <Box className="sideTarayHeader">
            <Link variant="h3" href="/app">
              <img src={logoCrypto} className="logoImage" />
            </Link>
          </Box>
          <Box className="mobileMenu" sx={{height:"100%"}}>
              <ul className="mobileMenuList">
                {sections.map((section, i) =>
                  section.id != "5" ? (
                    <li>
                      <Link
                  variant="button"
                  color="text.primary"
                  key={section.title}
                  href={section.url}
                  textAlign="center"
                  underline="none"
                  sx={{ my: 1, mx: 1.5, color: "#ffff" }}
                >
                  {section.title}
                </Link>
                    </li>
                  ) : (
                    <></>
                  )
                )}
                <li>
                 
                </li>
              </ul>
          </Box>
           
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
