import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { adminContact } from '../types';

export const getAdminContact = async (): Promise<adminContact> => {
    const response = await axios.get(`/api/v1/user/contactus`)
    const data = response.data;
    return data;
};
type QueryFnType = typeof getAdminContact;
type UseContactListOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useAdminContact = ({ config }: UseContactListOptions) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['data'],
        queryFn: () => getAdminContact(),
        ...config,
    });
};