import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { LoginForm } from "../components/LoginForm";
import { Layout } from "../components/Layout";
import { useAuth } from "@/lib/auth";
import { useEffect } from "react";
import { getTicketStatus } from "@/features/Tickets/api/getTicketStatus";
import storage from "@/utils/storage";
import { ticketListData } from "@/features/Tickets/types";
import VerifyOTP from "@/components/Modals/verifyLoginOTP";

export const Login = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isOpen, setIsopen] = useState(false);
  const [email, setEmail]= useState('')
  let dataValue = false;

  let valueData: Promise<ticketListData[]>;
  const handleLogin = async () => {
    if (storage.getToken()) {
      valueData = getTicketStatus();
      if (await valueData) navigate("/app");
      else navigate("/app/wallet/MyLottery");
    }
  };

  const handleClose = () => {
    setIsopen(false);
  };

  return (
    <Layout title="Log in to your account">
      {/* <LoginForm onSuccess={handleLogin} /> */}
      <LoginForm onSuccess={(email) => {setIsopen(true); setEmail(email)}} />
      <VerifyOTP
        open={isOpen}
        onClose={handleClose}
        email={email}
        // onSuccess={handleLogin}
      />
    </Layout>
  );
};
