import {
  Box,
  Card,
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import storage from "@/utils/storage";
import axios from "axios";

const useStyles = makeStyles({
  swapCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#1c1c2b",
    borderRadius: "20px",
    padding: "30px",
    width: "100%",
    maxWidth: "500px",
    color: "white",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px",
  },
  tokenDropdown: {
    flex: 1,
    marginRight: "10px",
  },
  amountInput: {
    flex: 1,
    marginLeft: "10px",
    "& input[type='number']": {
      "-moz-appearance": "textfield",
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "& .MuiInputBase-input": {
        color: "white",
      },
    },
  },
  swapButton: {
    backgroundColor: "#3C37FF",
    color: "white",
    borderRadius: "10px",
    padding: "15px",
    fontSize: "18px",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#d4ac0d",
      color: "#000",
    },
  },

  balanceText: {
    color: "#9b9b9b",
    fontSize: "12px",
  },
  conversionRate: {
    color: "#9b9b9b",
    fontSize: "12px",
    textAlign: "center",
    marginTop: "10px",
  },
  loaderContainer: {
    position: "relative",
    width: "100%",
  },
  loader: {
    position: "absolute",
    left: "45%",
    top: "30%",
    transform: "translate(-50%, -50%)",
  },
});
// #3C37FF;
export const Swap = () => {
  const [amount, setAmount] = useState("");
  const [g2fAmount, setG2fAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [swapLoading, setSwapLoading] = useState(false);
  const [swapG2fAmount, setSwapG2fAmount] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  console.log("swapG2fAmount", swapG2fAmount);
  const classes = useStyles();
  const handleSwap = () => {
    swapG2famount(amount);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value.trim() !== "") {
      setAmount(value);
    } else if (value === "") {
      setAmount(value);
    }
  };
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // const fetchG2fAmount = async (amount) => {
  //   let token = storage.getToken();
  //   setLoading(true);
  //   try {
  //     let response = await axios.post(
  //       `${process.env.API_URL_PREFIX}/api/v1/user/initiateSwap`,
  //       { tokensToConvert: amount },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Access-Control-Allow-Origin": "*",
  //         },
  //       }
  //     );

  //     const g2fAmountValue = response.data.data.toGetG2F;
  //     setG2fAmount(g2fAmountValue);
  //   } catch (error) {
  //     console.error("Error fetching G2F amount:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchG2fAmount = async (amount) => {
    let token = storage.getToken();
    setLoading(true);
    setErrorMessage(""); // Clear previous error before new request
    try {
      let response = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/initiateSwap`,
        { tokensToConvert: amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const g2fAmountValue = response.data.data.toGetG2F;
      setG2fAmount(g2fAmountValue);
    } catch (error: any) {
      console.error("Error fetching G2F amount:", error);
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message); // Set error message from response
        setG2fAmount(null); // Reset the G2F amount on error
      }
    } finally {
      setLoading(false);
    }
  };

  const swapG2famount = async (amount) => {
    let token = storage.getToken();
    setSwapLoading(true);
    try {
      let response = await axios.post(
        `${process.env.API_URL_PREFIX}/api/v1/user/swapTokensWithG2F`,
        { tokensToConvert: amount },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const swapG2FamountValue = response.data.data.toGetG2F;
      setSwapG2fAmount(swapG2FamountValue);
    } catch (error) {
      console.error("Error fetching G2F amount:", error);
    } finally {
      setSwapLoading(false);
      window.location.reload();
    }
  };

  const debouncedFetch = debounce(fetchG2fAmount, 500);
  useEffect(() => {
    if (amount) {
      debouncedFetch(amount);
    } else {
      setG2fAmount(null);
    }
  }, [amount]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh", backgroundColor: "#050518" }}
    >
      <Card className={classes.swapCard}>
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
          Swap with G2F
        </Typography>

        <Box className={classes.rowContainer}>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              sx={{ display: "block", margin: "10px", marginLeft: "10px" }}
            >
              Enter Amount
            </Typography>
            <TextField
              value={amount}
              variant="outlined"
              className={classes.amountInput}
              InputProps={{
                style: { color: "white" },
              }}
              fullWidth
              type="number"
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box className={classes.rowContainer}>
          <Box sx={{ flex: 1 }}>
            <Typography
              variant="h6"
              sx={{ display: "block", margin: "10px", marginLeft: "10px" }}
            >
              G2F you get
            </Typography>
            <Box className={classes.loaderContainer}>
              <TextField
                variant="outlined"
                className={classes.amountInput}
                fullWidth
                value={g2fAmount || ""}
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#1c1c2b",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "white",
                  },
                }}
              />
              {loading && (
                <CircularProgress size={24} className={classes.loader} />
              )}
              {errorMessage && (
                <Typography color="error" variant="h6" sx={{ margin: "10px" }}>
                  {errorMessage}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>

        <Button
          onClick={handleSwap}
          className={classes.swapButton}
          fullWidth
          disabled={swapLoading}
        >
          {swapLoading ? (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography
                variant="h6"
                sx={{ color: "white", marginBottom: "5px" }}
              >
                Transaction in progress, please wait
              </Typography>
              <LinearProgress color="primary" />
            </Box>
          ) : (
            "Swap"
          )}
        </Button>
      </Card>
    </Grid>
  );
};
