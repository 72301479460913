/* eslint-disable react-hooks/rules-of-hooks */
import { axios } from '@/lib/axios';

import { lotteryData } from '../types';
import { useNotificationStore } from '@/stores/notification';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useMutation } from 'react-query';
import { any } from 'zod';
import { toast } from 'react-toastify';

export type lotteryDataDTO = {
  data:{
  speed:number,
  deceleration:number,
  numOfToken:number,
  type:string;
  }
  message:string,
  idValue:string,
  }




export const lotteryDataValue = ({data, idValue }: lotteryDataDTO): Promise<lotteryDataDTO> => {
  return axios.post('/api/v1/user/buyLottery/' + idValue, data);
};




type lotteryDataReferOptions = {
  config?: MutationConfig<typeof lotteryDataValue>;
};


export const lotteryDataFnData = ({ config }: lotteryDataReferOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onSuccess: () => {
      //queryClient.invalidateQueries([]);
     
    },
  
    ...config,
    mutationFn: lotteryDataValue,
  });
};
