export const DEFAULT_BACKGROUND_COLORS = ["darkgrey", "lightgrey"];
export const DEFAULT_TEXT_COLORS = ["black"];
export const DEFAULT_OUTER_BORDER_COLOR = "white";
export const DEFAULT_OUTER_BORDER_WIDTH = 1;
export const DEFAULT_INNER_RADIUS = 0;
export const DEFAULT_INNER_BORDER_COLOR = "white";
export const DEFAULT_INNER_BORDER_WIDTH = 0;
export const DEFAULT_RADIUS_LINE_COLOR = "white";
export const DEFAULT_RADIUS_LINE_WIDTH = 0;
export const DEFAULT_FONT_FAMILY = "Nunito";
export const DEFAULT_FONT_SIZE = 20;
export const DEFAULT_FONT_WEIGHT = "bold";
export const DEFAULT_FONT_STYLE = "normal";
export const DEFAULT_TEXT_DISTANCE = 88;
export const DEFAULT_SPIN_DURATION = 1.0;
export const DISABLE_INITIAL_ANIMATION = false;
export const WEB_FONTS = [
  "arial",
  "verdana",
  "tahoma",
  "trebuchet ms",
  "times",
  "garamond",
  "brush script mt",
  "courier new",
  "georgia",
  "helvetica",
  "times new roman",
  "serif",
  "sans-serif",
  "monospace",
  "cursive",
  "fantasy",
];
