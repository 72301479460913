import { axios } from '@/lib/axios';

import { UserResponse } from '../types';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notification';
import { useMutation } from 'react-query';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

// export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
//   return axios.post('/api/v1/user/login', data)
// }


export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/api/v1/user/userLogin', data)
}



