import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid } from "@mui/material";
import clsx from "clsx";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import promotionSecond from "@/assets/promotionSecond.png";
import { theme } from "@/components/Elements/theme";
import HomeScreenImg from "@/assets/earth.jpg";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
import { useTermsList } from "../api/getTerms";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
  kycContent: {
    // maxWidth: "700px",
    color: "white",
    textAlign: "start",
    "& h1": {
      color: "#3C37FF",
      fontSize: "23px"
    },
    "& strong": {
      color: "white",
    },
    "& p, & ul, & ol": {
      color: "white",
    },
    "& ul, & ol": {
      margin: "10px 0",
      paddingLeft: "20px",
    },
    "& ul ul, & ul ol, & ol ul, & ol ol": {
      paddingLeft: "20px",
    },
  },
  sizee: {
    fontSize: "14px",
    opacity: "0.7",
    fontWeight: 400,
    lineHeight: "150%",
    color: "white",
  }
}));

export default function TermsOfUse() {
  const classes = useStyles();
  const DataValue = useTermsList({});
  const auth = useAuth();

  return (
    <>
      {auth.user ? <Header /> : <HeaderValue />}
      <Box
        sx={{
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          padding: 0,
          pt: "70px",
          pb:"40px",
        }}
      >
        <Box
          sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
        />
        <Box sx={{ p: { xs: "0 15px",sm:"0 30px", md: "50px" } }}>
          <Box
            sx={{
              backgroundColor: "rgba(5, 5, 24, 0.8)",
              borderRadius: "10px",
              display:"flex",
              flexFlow:"column",
              marginTop: "40px", 
              p: {xs: "15px",sm:"30px",md:"64px"}
            }}
          >
            <h1 style={{ fontSize: "23px", fontWeight: 500,color: "#3C37FF", marginTop:"0px" }}>KYC Policy of G2F LIMITED</h1>
            <Grid
                container
                display={"flex"}
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"} 
              >
                <Grid item xs={12}>
                  <Card sx={{ margin: "0 auto", background: "none" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // maxWidth: "920px",
                        height: "500px",
                        margin: "auto",
                        overflow: "auto",
                      }}
                    >
                      <div className={classes.kycContent}>
                        
                        <p><strong>Last updated: 07-18-2024</strong></p>

                        <p className={classes.sizee}>
                          The Company adheres to and complies with “Know your customer” principles, which aim to prevent financial crime and money laundering through client identification and due diligence.
                        </p>

                        <p className={classes.sizee}>
                          The Company reserves the right, at any time, to ask for any KYC documentation it deems necessary to determine the identity and location of a user in <a href="https://mervcasino.com/" style={{ color: "#3C37FF" }}>https://mervcasino.com/</a>. We reserve the right to restrict the service, payment, or withdrawal until identity is sufficiently determined, or for any other reason in our sole discretion based on the legal framework.
                        </p>

                        <p className={classes.sizee}>
                          We take a risk-based approach and perform strict due diligence checks and ongoing monitoring of all clients, customers, and transactions. As per the money laundering regulations, we utilize three stages of due diligence checks, depending on the risk, transaction, and customer type:
                        </p>
                        <ul>
                          <li className={classes.sizee}><strong>SDD</strong> — simplified due diligence is used in instances of extremely low-risk transactions that do not meet the required thresholds</li>
                          <li className={classes.sizee}><strong>CDD</strong> — customer due diligence is the standard for due diligence checks, used in most cases for verification and identification</li>
                          <li className={classes.sizee}><strong>EDD</strong> — Enhanced Due Diligence is used for high-risk customers, large transactions or special cases</li>
                        </ul>

                        <p className={classes.sizee}>
                          Separately and in addition to the above, when a user makes an aggregate lifetime total of deposits exceeding EUR 5,000 or requests a withdrawal of any amount inside the <a href="https://mervcasino.com/" style={{ color: "#3C37FF" }}>https://mervcasino.com/</a>, or attempts to or completes a transaction that is deemed suspicious, then it is compulsory for them to complete the full KYC process. During this process, the user will have to input some basic details about themselves and then upload:
                        </p>
                        <ol>
                          <li className={classes.sizee}>A copy of Government Issued Photo ID (in some cases front and back depending on the ID document)</li>
                          <li className={classes.sizee}>A selfie of themselves holding the ID doc</li>
                          <li className={classes.sizee}>A bank statement/Utility Bill</li>
                        </ol>

                        <h2 className={classes.sizee}>Guideline for the “KYC Process”</h2>
                        <h3 className={classes.sizee}>1) Proof of ID</h3>
                        <ul>
                          <li className={classes.sizee}>Signature is there</li>
                          <li className={classes.sizee}>Country is not one of the following Restricted Countries:
                            <ul>
                              <li>Austria</li>
                              <li>France and its territories</li>
                              <li>Germany</li>
                              <li>Netherlands and its territories</li>
                              <li>Spain</li>
                              <li>Union of Comoros</li>
                              <li>United Kingdom</li>
                              <li>USA and its territories</li>
                              <li>All FATF Blacklisted countries</li>
                              <li>Any other jurisdictions deemed prohibited by Anjouan Offshore Financial Authority</li>
                            </ul>
                          </li>
                          <li className={classes.sizee}>Full Name matches client’s name</li>
                          <li className={classes.sizee}>Document does not expire in the next 3 months</li>
                          <li className={classes.sizee}>Owner is over 18 years of age</li>
                        </ul>

                        <h3 className={classes.sizee}>2) Proof of Residence</h3>
                        <ul>
                          <li className={classes.sizee}>Bank Statement or Utility Bill</li>
                          <li className={classes.sizee}>Country is not one of the following Restricted Countries:
                            <ul>
                              <li>Austria</li>
                              <li>France and its territories</li>
                              <li>Germany</li>
                              <li>Netherlands and its territories</li>
                              <li>Spain</li>
                              <li>Union of Comoros</li>
                              <li>United Kingdom</li>
                              <li>USA and its territories</li>
                              <li>All FATF Blacklisted countries</li>
                              <li>Any other jurisdictions deemed prohibited by Anjouan Offshore Financial Authority</li>
                            </ul>
                          </li>
                          <li className={classes.sizee}>Full Name matches client’s name and is same as in proof of ID</li>
                          <li className={classes.sizee}>Date of Issue: In the last 3 months</li>
                        </ul>

                        <h3 className={classes.sizee}>3) Selfie with ID</h3>
                        <ul>
                          <li className={classes.sizee}>Holder is the same as in the ID document above</li>
                          <li className={classes.sizee}>ID document is the same as in “1”. Make sure photo/ID number is the same</li>
                        </ul>

                        <div className={classes.sizee}>
                          <h2>Notes on the “KYC Process”</h2>
                          <ol>
                            <li>When the KYC process is unsuccessful then the reason is documented and a support ticket is created in the system. The ticket number along with an explanation is communicated back to the user.</li>
                            <li>Once all proper documents are in our possession then the account gets approved.</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid> 
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
