import {
  Box,
  Card,
  CardContent,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { Grid } from "@mui/material";
import { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";

import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { MantineProvider } from "@mantine/core";

import { DatePickerInput } from "@mantine/dates";
import CurrencyFormat from "react-currency-format";
import { useAuth } from "@/lib/auth";
import { getTicketList, useTicketList } from "../api/getTicketList";
import { useInvestedCoins } from "../../History/api/getInvestedCoins";
import { useWalletAmount } from "@/components/Elements/api/getWalletamount";
// import "./table.css";

import { Payment } from "../types";
import yellowt from "@/assets/yellow-t.png";
import MervIcon from "@/assets/mervIcon.svg";
import { useTicketStatus } from "../api/getTicketStatus";
import Pagination from "rc-pagination";

import { CircularProgress } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import moment from "moment";
type letterValues = {
  email: string;
};

const initialValues: letterValues = {
  email: "",
};

export const Ticket = () => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading, isError, isFetching }: any = useTicketList({
    page: currentPage,
    limit: pageSize,
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const { data: dataWallet, isLoading: isLoadingWallet } = useWalletAmount({});
  const { data: coins, isLoading: isLoadingCoins } = useInvestedCoins({});
  const { data: ticketStatus, isLoading: isLoadingTicketStatus } =
    useTicketStatus({}) || { data: [] };

  const isLoadingAll =
    isLoading ||
    isLoadingWallet ||
    isLoadingCoins ||
    isLoadingTicketStatus ||
    isFetching;
  console.log(isLoadingAll, "isLoadingAll");
  const [formValues, setFormValues] = useState<letterValues>(initialValues);
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  const columns = useMemo<MRT_ColumnDef<Payment>[]>(
    () => [
      {
        accessorKey: "userName",
        header: "User Name",
        size: 100,
      },
      // {
      //   accessorFn: (row) => new Date(row.createdAt),
      //   id: 'Date',
      //   header: 'Date',
      //   filterFn: 'lessThanOrEqualTo',
      //   sortingFn: 'datetime',
      //   Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
      //   Header: ({ column }) => <em>{column.columnDef.header}</em>,

      //   Filter: ({ column }) => (
      //     <DatePickerInput
      //       // placeholder="Filter by Start Date"
      //       clearable
      //       onChange={(newValue: Date) => {
      //         column.setFilterValue(newValue);
      //       }}
      //       value={column.getFilterValue() as Date}
      //       modalProps={{ withinPortal: true }}
      //     />
      //   ),
      // },

      {
        accessorKey: "createdAt",

        header: "Date",
        size: 20,
        Cell: ({ cell }) => {
          const formattedDate = moment(cell.getValue() as string).format(
            "DD/MM/YYYY"
          ); // Change this format to your desired one
          return <Box>{formattedDate}</Box>;
        },
      },

      {
        accessorKey: "ticketNft",
        header: "NFT Id",
        size: 100,
      },
      {
        accessorKey: "ticketAmt",
        header: "Amount",
        size: 100,
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue<number>()?.toLocaleString?.("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </Box>
        ),
      },
      {
        accessorKey: "nftStatus",
        header: "Status",
        size: 100,
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              color:
                cell.getValue<string>() === "Success"
                  ? "#95D23D"
                  : cell.getValue<string>() === "Pending"
                  ? "#FF9900"
                  : "#FF4545",
              borderRadius: "4px",
              border:
                cell.getValue<string>() === "Success"
                  ? "1px solid rgba(149, 210, 61, 0.41)"
                  : cell.getValue<string>() === "Pending"
                  ? "1px solid rgba(231, 214, 61, 0.5)"
                  : "1px solid rgba(255, 69, 69, 0.4)",
              backgroundColor:
                cell.getValue<string>() === "Approved"
                  ? "rgba(149, 210, 61, 0.19)"
                  : cell.getValue<string>() === "Pending"
                  ? "rgba(231, 214, 61, 0.1)"
                  : "rgba(255, 69, 69, 0.05)",
              maxWidth: "9ch",
              padding: "4px",
            })}
          >
            {cell.getValue<string>()}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container sx={{ mt: { md: useTheme().spacing(0.5), xs: "10px" } }}>
        <Grid item xs={12} md={12}>
          {" "}
          <Card
            style={cardStyle}
            sx={{
              p: { md: useTheme().spacing(0.3), xs: "0px" },
              m: useTheme().spacing(0.2),
            }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Item sx={{alignItems:"center", justifyContent:"space-between"}}>
                  {" "}
                  <Typography
                    component="h4"
                    sx={{
                      fontSize: { xs: "20px", md: "22px" },
                    }}
                  >
                    My Tickets
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      position: "absolute",
                      right: "10%",
                      margin: "-18px",
                      pt: { xs: "5px", md: "0" },
                    }}
                  >
                    <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                      <img
                          src={MervIcon}
                          className="log-merv-wallet"
                          style={{
                            height: "22px",
                            width: "22px", 
                          }}
                        />{" "}
                        {coins?.data} 
                    </Typography>
                    <Typography sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                    <img
                      src={yellowt}
                      className="log-merv-wallet"
                      style={{
                        height: "22px",
                        width: "22px", 
                      }}
                    />
                    <CurrencyFormat
                      value={dataWallet?.data?.walletAmount}
                      thousandSeparator={","}
                      displayType={"text"}
                      decimalSeparator={"."}
                    />
                    </Typography>
                  </Typography>
                </Item>

                <Item>
                  <Grid item sx={{ width: "100%", overflowX: "auto" }}>
                    <MantineProvider
                      withGlobalStyles
                      withNormalizeCSS
                      theme={{
                        breakpoints: {
                          xs: "30em",
                          sm: "48em",
                          md: "64em",
                          lg: "74em",
                          xl: "90em",
                        },
                        fontSizes: { xs: "12px", md: "14px" },
                        colorScheme: "dark",
                      }}
                    >
                      {isLoadingAll ? ( // Show loader while loading
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "300px", // Adjust as per your requirement
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <MantineReactTable
                          columns={columns}
                          data={data?.data || []}
                          initialState={{ showColumnFilters: true }}
                          enablePagination={false}
                        />
                      )}
                    </MantineProvider>
                  </Grid>
                </Item>
                {isFetching ? (
                  <Box className="paginationBox">""</Box>
                ) : (
                  <Box className="paginationBox">
                    <Pagination
                      pageSize={pageSize}
                      onChange={handlePageChange}
                      current={currentPage}
                      total={data?.meta?.totalCount}
                    />
                  </Box>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
