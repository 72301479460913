import Carousel from "react-material-ui-carousel";
import blockchain from "@/assets/blockchain.png";
import startImg from "@/assets/startImg.png";
import fairPlay from "@/assets/fairPlay.png";
import authSideImg from "@/assets/authSideImg.svg";
import logoCrypto from "@/assets/logoCrypto.svg";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import "./CSS/style.css";
import {
  Paper,
  Box,
  Typography,
  Stack,
  Grid,
  Avatar,
  useTheme,
  Slide,
  Link,
} from "@mui/material";
import { useState } from "react";

var items = [
  {
    name: `Start your \n Journey with us`,
    description:
      "Play in the only lottery that gives you an actual big chance of winning a big prize.",
    image: startImg,
  },
  {
    name: "Based on \n Blockchain Technology, \n completely safe and  transparent",
    description: "Mathematical security, completely safe and transparent. ",
    image: blockchain,
  },
  {
    name: "Fair play",
    description:
      "Fully equal chances of winning because the players determine the criteria of the draw.",
    image: fairPlay,
  },
];

export const CrouselComponent = () => {
  const [imageUrl, setImageUrl] = useState(startImg);
  const theme = useTheme();
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
      xl={5}
      sx={{
        backgroundImage: `url(${authSideImg})`,
        backgroundColor: `(${theme.palette.primary})`,
        borderRadius: "10px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          paddingTop: { md: theme.spacing(2), xs: theme.spacing(0.5) },
          px: 6,
          flex: 1,
        }}
      >
        <Box
          className="mervlogo"
          sx={{
            paddingRight: "10px",
            paddingTop: "14px",
            display: { xs: "block", md: "none", sm: "block" },
          }}
        >
          <Link variant="h3" href="/">
            <img src={logoCrypto} className="logoImage" alt="logo crypto" />
          </Link>
        </Box>
        <Carousel
          className="cstm-crousel loginSignUp"
          next={(next, active) => setImageUrl(items[Number(next)].image)}
          animation="slide"
          indicatorContainerProps={{
            style: {
              color: "#FFFF",
              zIndex: 1,
              display: "flex",
              justifyContent: "flex-start",
              fontSize: "5px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: "#FFFFF",
              fill: "#ffff",
            },
          }}
        >
          {items.map((item, i) => (
            <Box
              sx={{
                backgroundColor: "transparent",
              }}
              key={i}
            >
              <Paper
                component={Stack}
                direction="column"
                justifyContent="center"
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  mt: "8",
                }}
              >
                <Typography component="h2" variant="h2">
                  {item.name.split("\n").map((it, i) => (
                    <div key={"x" + i}>{it}</div>
                  ))}
                </Typography>
                <Typography
                  component="h6"
                  variant="h6"
                  sx={{ maxWidth: "300px" }}
                >
                  {item.description}
                </Typography>
              </Paper>
            </Box>
          ))}
        </Carousel>
      </Box>
      <Box
        sx={{
          flex: "none",
          margin: "auto",
        }}
      >
        <Avatar
          alt="Example Alt"
          src={imageUrl}
          sx={{ width: "60%", height: "auto", margin:"0 auto 30px" }}
        />
      </Box>
    </Grid>
  );
};
