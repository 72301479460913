import { axios } from '@/lib/axios';

import { AuthUser, UserResponseDetail } from '../types';

import storage from '@/utils/storage';


var token = storage.getToken();
export const getUser = (): Promise<AuthUser> => {
  return axios.get('/api/v1/user/userdetail' );
};

export const getUserDetail = (): Promise<UserResponseDetail> => {
  return axios.get('/api/v1/user/userdetail' );
};

