import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { ticketListData } from '../types';

export const getTicketStatus = async (): Promise<ticketListData[]> => {
  const response = await axios.get(`/api/v1/user/walletPageShow`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getTicketStatus;

type UseTicketStatusOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTicketStatus = ({  config }: UseTicketStatusOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getTicketStatus(),
    ...config,
  });
};