import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { friendsList } from '../types';

export const getFriendsList = async (): Promise<friendsList[]> => {
  const response = await axios.get(`/api/v1/user/referfriend`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getFriendsList;

type UseFriendsListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useFriendsList = ({  config }: UseFriendsListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getFriendsList(),
    ...config,
  });
};