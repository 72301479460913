import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
// import { allData } from "./constants";
import storage from "@/utils/storage";
import axios from "axios";
import "./myWinning.css";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import { Opacity } from "@material-ui/icons";

const WithdrawReqStatus = () => {
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState(10);
  const [data, setData] = useState([
    {
      _id: 0,
      paymentStatus: "",
      userName: 0,
      walletAddress: "",
      amount: "",
      walletNetwork: "",
      paymentType: 0,
      withdrawPartialAmtPaid: "",
      withdrawRemainingAmtPaid: "",
      withdrawTotalAmtPaid: "",
      createdAt: "",
    },
  ]);
  const [collection, setCollection] = React.useState(
    // cloneDeep(allData.slice(0, countPerPage))
  );
  const [isLoading, setIsLoading] = useState(false);

//   const searchData = React.useRef(
//     throttle((val) => {
//       const query = val.toLowerCase();
//       setCurrentPage(1);
//       const data = cloneDeep(
//         allData
//           .filter((item) => item.name.toLowerCase().indexOf(query) > -1)
//           .slice(0, countPerPage)
//       );
//       setCollection(data);
//     }, 400)
//   );
//   const column = Object.keys(data[0]);
// const [showLoader,setShowLoader]=useState(false)
  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
    //   searchData.current(value);
    }
  }, [value]);

  useEffect(() => {
    getData(value, 1);
  }, [value]);

  const getData = async (value, p) => {
    let token = storage.getToken();
    let pageLimit;
    pageLimit = value != "" ? totalRecords : 10;
    const payable = {
      page: p,
      limit: pageLimit,
    };
    await axios
      .post(
        `${process.env.API_URL_PREFIX}/api/v1/user/fetchUserWithdrawReq?search=${value}`,
        payable,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setData(res?.data?.data)
        setTotalRecords(res?.data?.totalExternalWallAddCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    // setCollection(cloneDeep(allData.slice(from, to)));
    getData(value, p);
  };
  console.log("datalll",data)
  return (
    <>
      <div style={{overflow:"auto"}}>
        <input
          placeholder="Search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="search"
        />
      </div>
      <div className="table-t1">
        <table style={{borderCollapse:"collapse", width:"100%"}}>
          <tr className="table-th">
            <th>Payment Request Id</th>
            <th>User Name</th>
            <th>Wallet Address</th>
            <th>Payment Req Amount</th>
            <th>Payment Status</th>
            <th>Wallet Network</th>
            <th>Payment Type</th>
            <th>Withdraw Partial Paid Amt</th>
            <th>Withdraw Remaining Paid Amt</th>
            <th>Withdraw Total Paid Amt</th>
            <th>Created Date</th>
          </tr>
          {data?.map((item, i) => {
            return (
              <tr key={i} className="table-th2">
                <td>{item?._id}</td>
                <td>{item?.userName}</td>
                <td>{item?.walletAddress}</td>
                <td>{item?.amount}</td>
                <td>{item?.paymentStatus}</td>
                {/* <td>{item?.surpriceAmt}</td> */}
                <td>{item?.walletNetwork}</td>
                <td>{item?.paymentType}</td>
                <td>{item?.withdrawPartialAmtPaid}</td>
                <td>{item?.withdrawRemainingAmtPaid}</td>
                <td>{item?.withdrawTotalAmtPaid}</td>
                <td>{item?.createdAt}</td>
              </tr>
            );
          })}
        </table>
      </div>

      {value == "" ? (
        <Pagination
          pageSize={countPerPage}
          onChange={updatePage}
          current={currentPage}
          total={totalRecords}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default WithdrawReqStatus;
