const storagePrefix = 'crypto_react_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: any) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setUserId: (name: any) => {
    window.localStorage.setItem(`${storagePrefix}userid`, JSON.stringify(name));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  clearUserId: () => {
    window.localStorage.removeItem(`${storagePrefix}userid`);
  },
  clearRemeberMe: () => {
    window.localStorage.removeItem(`${storagePrefix}setCheckBox`);
  },
  cleartUserNameAndPassword: () => {
    window.localStorage.removeItem(`${storagePrefix}UserName`);
    window.localStorage.removeItem(`${storagePrefix}Password`);
  },
  cleartUserPassword: () => {
    window.localStorage.removeItem(`${storagePrefix}Password`);
  },
  setResetToken: (tokenReset: any) => {
    window.localStorage.setItem(`${storagePrefix}Resettoken`, JSON.stringify(tokenReset));
  },
  getResetToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}Resettoken`) as string);
  },

  setUserNameAndPassword: (dataValue: any) => {
    window.localStorage.setItem(`${storagePrefix}UserName`, JSON.stringify(dataValue.email));
    // window.localStorage.setItem(`${storagePrefix}Password`, JSON.stringify(dataValue.password));
  },
  getUserName: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}UserName`) as string);
  },
  getUserId: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}userid`) as string);
  },
  getPassword: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}Password`) as string);
  },
  getRemeberMe: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}setCheckBox`)  as string);
  },
  setCheckBox: (isChecked: any) => {
    window.localStorage.setItem(`${storagePrefix}setCheckBox`, JSON.stringify(isChecked));
  },
};

export default storage;