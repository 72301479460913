import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { userResponse } from '../types';
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notification';
import { useMutation } from 'react-query';


export const getProfileData = async (): Promise<userResponse> => {
  const response = await axios.get(`/api/v1/user/userdetail`, {
  })
    //return response?.data;
     const data = response.data;
    return data;
}



type QueryFnType = typeof getProfileData;

type UseProfileListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useProfile = ({  config }: UseProfileListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getProfileData(),
    ...config,
  });
};