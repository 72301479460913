import * as React from 'react';
import HeaderMain from '@/components/Elements/headerMain';
import Footer from '@/components/Elements/footer';
import MainAbout from '../Components/MainAbout';

export const About = () => {
    return (
        <React.Fragment>
            {/* <HeaderMain /> */}
            <MainAbout/>
            <Footer />
        </React.Fragment>
    )
}
