import { Box, Fade, Modal } from "@mui/material";
import React from "react";
import { theme } from "./theme";
import { Opacity } from "@material-ui/icons";
import "./CSS/style.css"
const style = {
    maxWidth: { xs: '281px', sm: '281px', md: '281px', lg: '281px' },
    outline: "none",
    width: '100%',
    height: 'auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: '-150px',
    marginTop: '-150px',
    bgcolor: "#212121",
    p: 0,
    my: 2,
    borderRadius: "8px",
    overflow: 'auto',
    border: '1px solid black',
    zIndex: "2147483647"
};

interface iModalProps {
    open: boolean;
    onClose: () => void;
    childComponent: any;
};

export const CustomModelLotteryPool = ({
    open,
    onClose,
    childComponent
}: iModalProps) => {
    return (
        <Modal className="alltimepopup" closeAfterTransition open={open}
            onClose={() => !open}
            hideBackdrop={true}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", transform: `translate(0%, -17%)`, zIndex: "2147483647", outline: "none", background: "transparent" }}>
            <Fade in={open}>
                <Box sx={style} className="wheel-cstm">
                    {childComponent}
                </Box>
            </Fade>
        </Modal>
    )
}