import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import OwlCarousel from "react-owl-carousel";
import { makeStyles } from "@material-ui/core/styles";
import "./owl-crousel.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import storage from "@/utils/storage";
import axios from "axios";
import powerBoll from "../../../assets/powerball.png";
import powerBallJackpot from "../../../assets/Powerball Jackpot.gif"
import { theme } from "@/components/Elements/theme";
interface JackpotResponse {
  message: string;

  jackpotAccumulatedAmt: string;
}

const useStyles = makeStyles(
  (theme: { breakpoints: { down: (arg0: string) => any } }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      backgroundColor: "#FFFFFF1A",
      borderRadius: "15px",
    },
    media: {
      width: "45% !important",
      marginRight: "1rem",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginRight: "1rem",
      },
    },
  })
);

function RecentActivities({ dataRecent }) {
  const [lastLotteryData, setLastLotteryData] =
    useState<JackpotResponse | null>(null);

  console.log(lastLotteryData, "lastLotteryData");
  const classes = useStyles();
  const options = {
    loop: false,
    margin: 10,
    dots: false,
    nav: true,
    navText: [
      '<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.4584 21.6418C14.1174 21.6418 16.6676 20.5778 18.5478 18.6837C20.428 16.7897 21.4843 14.2209 21.4843 11.5423C21.4843 8.86377 20.428 6.29493 18.5478 4.40091C16.6676 2.50689 14.1174 1.44284 11.4584 1.44284C8.79933 1.44284 6.24919 2.50689 4.36896 4.40091C2.48873 6.29493 1.43243 8.86377 1.43243 11.5423C1.43243 14.2209 2.48873 16.7897 4.36896 18.6837C6.24919 20.5778 8.79933 21.6418 11.4584 21.6418ZM11.4584 5.34058e-05C14.4973 5.34058e-05 17.4117 1.21611 19.5606 3.3807C21.7094 5.5453 22.9166 8.48112 22.9166 11.5423C22.9166 14.6035 21.7094 17.5393 19.5606 19.7039C17.4117 21.8685 14.4973 23.0846 11.4584 23.0846C8.41947 23.0846 5.50502 21.8685 3.35619 19.7039C1.20736 17.5393 0.000152588 14.6035 0.000152588 11.5423C0.000152588 8.48112 1.20736 5.5453 3.35619 3.3807C5.50502 1.21611 8.41947 5.34058e-05 11.4584 5.34058e-05ZM16.4713 10.8209C16.6613 10.8209 16.8434 10.8969 16.9777 11.0322C17.112 11.1675 17.1875 11.351 17.1875 11.5423C17.1875 11.7336 17.112 11.9171 16.9777 12.0524C16.8434 12.1877 16.6613 12.2637 16.4713 12.2637L8.17416 12.2637L11.2493 15.3599C11.3837 15.4954 11.4593 15.6791 11.4593 15.8707C11.4593 16.0622 11.3837 16.246 11.2493 16.3814C11.1148 16.5169 10.9324 16.593 10.7422 16.593C10.5521 16.593 10.3697 16.5169 10.2352 16.3814L5.93838 12.0531C5.87169 11.9861 5.81877 11.9065 5.78267 11.8188C5.74657 11.7312 5.72798 11.6372 5.72798 11.5423C5.72798 11.4474 5.74657 11.3535 5.78267 11.2658C5.81877 11.1782 5.87169 11.0986 5.93838 11.0316L10.2352 6.70323C10.3697 6.56777 10.5521 6.49167 10.7422 6.49167C10.9324 6.49167 11.1148 6.56777 11.2493 6.70323C11.3837 6.83869 11.4593 7.02241 11.4593 7.21397C11.4593 7.40554 11.3837 7.58926 11.2493 7.72472L8.17416 10.8209L16.4713 10.8209Z" fill="white"/></svg>',
      '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.4554 21.6417C9.7963 21.6417 7.24617 20.5777 5.36594 18.6837C3.48571 16.7897 2.42941 14.2208 2.42941 11.5423C2.42941 8.86371 3.48571 6.29487 5.36594 4.40085C7.24617 2.50683 9.7963 1.44278 12.4554 1.44278C15.1144 1.44278 17.6645 2.50683 19.5448 4.40085C21.425 6.29487 22.4813 8.86371 22.4813 11.5423C22.4813 14.2208 21.425 16.7897 19.5448 18.6837C17.6645 20.5777 15.1144 21.6417 12.4554 21.6417ZM12.4554 -7.62939e-06C9.41644 -7.62939e-06 6.502 1.21605 4.35316 3.38064C2.20433 5.54524 0.997128 8.48106 0.997128 11.5423C0.997128 14.6035 2.20433 17.5393 4.35316 19.7039C6.502 21.8685 9.41644 23.0845 12.4554 23.0845C15.4943 23.0845 18.4087 21.8685 20.5575 19.7039C22.7064 17.5393 23.9136 14.6035 23.9136 11.5423C23.9136 8.48106 22.7064 5.54524 20.5575 3.38064C18.4087 1.21605 15.4943 -7.62939e-06 12.4554 -7.62939e-06ZM7.44238 10.8209C7.25245 10.8209 7.07029 10.8969 6.93599 11.0322C6.80169 11.1674 6.72624 11.3509 6.72624 11.5423C6.72624 11.7336 6.80169 11.9171 6.93599 12.0524C7.07029 12.1877 7.25245 12.2637 7.44238 12.2637L15.7396 12.2637L12.6645 15.3599C12.53 15.4953 12.4544 15.679 12.4544 15.8706C12.4544 16.0622 12.53 16.2459 12.6645 16.3814C12.7989 16.5168 12.9813 16.5929 13.1715 16.5929C13.3617 16.5929 13.544 16.5168 13.6785 16.3814L17.9753 12.053C18.042 11.986 18.095 11.9064 18.1311 11.8187C18.1672 11.7311 18.1857 11.6372 18.1857 11.5423C18.1857 11.4474 18.1672 11.3534 18.1311 11.2658C18.095 11.1781 18.042 11.0985 17.9753 11.0315L13.6785 6.70317C13.544 6.56771 13.3617 6.49161 13.1715 6.49161C12.9813 6.49161 12.7989 6.56771 12.6645 6.70317C12.53 6.83862 12.4544 7.02234 12.4544 7.21391C12.4544 7.40548 12.53 7.5892 12.6645 7.72466L15.7396 10.8209L7.44238 10.8209Z" fill="white"/></svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      let token = storage.getToken();
      await axios
        .get(
          `${process.env.API_URL_PREFIX}/api/v1/admin/getLastlotteryIdAccAmt`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          setLastLotteryData(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* {lastLotteryData && (
        <Typography
          sx={{
            marginTop: "20px",
            marginLeft: "38%",
            fontSize: "25px",
            color: "yellow",
            fontWeight: "bold",
          }}
        >
         
          {`Powerball Jackpot : ($) ${lastLotteryData?.jackpotAccumulatedAmt}`}
        </Typography>
      )} */}
      {lastLotteryData && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", }}>
          {/* <Box
            sx={{  
              padding: "0px 25px", 
              borderRadius: "5px",
              background:"#12131b",
              display: "flex",
              flexFlow:"row wrap",
              alignItems:"center",
              justifyContent:"center",
              gap:"20px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
              [theme.breakpoints.down('lg')]:{
                gap:"0px 20px",
                padding:"10px 25px",
              }
            }}
          >
            <Typography component="p" 
              sx={{
                display:"flex", 
                alignItems:"center", 
                fontSize:"40px", 
                gap:"1px" ,
                "& .MuiTypography-body1":{
                    fontSize:"50px ",
                    fontFamily:"serif", 
                    fontWeight:"bold",
                    [theme.breakpoints.down('md')]:{
                      fontSize:"30px !important"
                    }
                  },
                }}>
              <Typography sx={{ color:" #d32f2f", }}>P</Typography>
              <Typography sx={{ color:"#7b1fa2 "}}>o</Typography>
              <Typography sx={{ color:"#388e3c "}}>w</Typography>
              <Typography sx={{ color:"#f57c00 "}}>e</Typography>
              <Typography sx={{ color:"#880e4f "}}>r</Typography>
              <Typography sx={{ color:"#1976d2 "}}>b</Typography>
              <Typography sx={{ 
                  alignSelf:"bottom", 
                  marginTop:"12px", 
                  marginInline:"3px",  
                  "& img":{
                    [theme.breakpoints.down('md')]:{
                      maxWidth:"20px !important"
                    },
                  },
                  [theme.breakpoints.down('md')]:{
                    marginTop:"8px"
                  },
                }}><img style={{width:"auto", maxWidth:"30px"}} src={powerBoll} alt="powerBoll"/></Typography>
              <Typography sx={{ color:"#fbc02d "}}>l</Typography>
              <Typography sx={{ color:"#fbc02d "}}>l</Typography>
              <Typography sx={{minWidth:"10px"}}> </Typography>
              <Typography sx={{ color:" #d32f2f"}}>J</Typography>
              <Typography sx={{ color:"#7b1fa2 "}}>a</Typography>
              <Typography sx={{ color:"#388e3c "}}>c</Typography>
              <Typography sx={{ color:"#f57c00 "}}>k</Typography>
              <Typography sx={{ color:"#880e4f "}}>p</Typography>
              <Typography sx={{ color:"#1976d2 "}} > o</Typography>
              <Typography sx={{ color:"#fbc02d "}}>tkkkk</Typography>
            </Typography>
            
            <Typography
              sx={{
                fontSize: "45px",
                color: "#3c37ff",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily:"sarif",
                [theme.breakpoints.down('md')]:{
                  fontSize:"30px"
                }
              }}
            >
              {` ($) ${lastLotteryData?.jackpotAccumulatedAmt}`}
            </Typography>
          </Box> */}


          <Box
            sx={{
              padding: "0px 25px",
              borderRadius: "5px",
              // background: "#12131b",
              border: "1px solid #3c37ff",
              display: "flex",
              flexFlow: "row wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              // boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
              [theme.breakpoints.down('lg')]: {
                gap: "0px 20px",
                padding: "10px 25px",
              }
            }}
          >
            <img
              src={powerBallJackpot}
              alt="Powerball Jackpot"
              loading="lazy"
              style={{
                width: "100%",
                maxWidth: "400px",
                objectFit: "contain"
              }}
            />
            <Typography
              sx={{
                fontSize: "45px",
                color: "#3c37ff",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "serif",
                [theme.breakpoints.down('md')]: {
                  fontSize: "30px"
                }
              }}
            >
              {`($) ${lastLotteryData?.jackpotAccumulatedAmt}`}
            </Typography>
          </Box>

        </Box>
      )}

      {dataRecent?.recentActivity?.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <Box>
              <Typography
                component="h4"
                variant="h4"
                sx={{
                  marginBlock: { xs: "15px", sm: "20px" },
                  fontSize: { xs: "20px", sm: "26px", md: "32px" },
                  marginInlineStart: { xs: "10px", sm: "15px", md: "0px" },
                }}
              >
                Recent Activity.
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  display: { xs: "none", md: "block" },
                  mb: "20px",
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                The last tickets you bought
              </Typography>
            </Box>
          </Box>

          <>
            <Stack
              display="flex"
              flexDirection="column"
              sx={{
                position: "absolute",
                zIndex: "999999999",
                ml: { xs: "10px", md: "50px" },
                mt: { xs: "21px", md: "41px" },
              }}
            ></Stack>
            <OwlCarousel className="owl-theme" {...options}>
              {dataRecent?.recentActivity.map(
                (
                  card: {
                    lotteryId: string;
                    lotteryDrawDate: any;
                    _id: any;
                    lotteryImage: any;
                    numOfPurchaseTicket: number;
                    lotteryName: string;
                    firstName:
                    | boolean
                    | ReactChild
                    | ReactFragment
                    | ReactPortal
                    | null
                    | undefined;
                  },
                  index: any
                ) => (
                  <Card
                    className={classes.root}
                    onClick={() => {
                      window.location.assign(
                        "/app/liveLotteries/" + card.lotteryId
                      );
                    }}
                    sx={{ cursor: "pointer", marginTop: "25px" }}
                  >
                    <CardMedia
                      id="RecentActivity"
                      className={classes.media}
                      component="img"
                      width="45%"
                      sx={{
                        height: {
                          xs: "230px",
                          md: "230px",
                          lg: "230px",
                          xl: "auto",
                        },
                      }}
                      image={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${card.lotteryImage}`}
                      title="Example image"
                    />
                    <CardContent sx={{ p: "0 !important" }}>
                      <Grid container sx={{ p: "25" }}>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            sx={{ color: "#FFFFFF", opacity: 0.5 }}
                          >
                            Player Name
                          </Typography>
                          <Typography variant="h3" component="h3">
                            {card.firstName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            gutterBottom
                            sx={{ color: "#FFFFFF", opacity: 0.5 }}
                          >
                            Draw Date
                          </Typography>
                          <Typography variant="h3" component="h3">
                            {
                              new Date(card.lotteryDrawDate)
                                .toISOString()
                                .split("T")[0]
                            }
                            <br />
                            {new Date(card.lotteryDrawDate)
                              .toISOString()
                              .split("T")[1]
                              .substring(0, 5) + "UTC"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            sx={{ color: "#FFFFFF", opacity: 0.5 }}
                          >
                            Jackpot name
                          </Typography>
                          <Typography variant="h3" component="h3">
                            {card.lotteryName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            sx={{ color: "#FFFFFF", opacity: 0.5 }}
                          >
                            No. of tickets
                          </Typography>
                          <Typography
                            variant="h3"
                            component="h3"
                            sx={{
                              p: "5.74px ",
                              borderRadius: "14px",
                              background: "#3C37FF",
                              textAlign: "center",
                              width: "20px",
                              marginLeft: "10px",
                            }}
                          >
                            {card.numOfPurchaseTicket}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )
              )}
            </OwlCarousel>
          </>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default RecentActivities;
