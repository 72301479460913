import { Navigate, Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Register } from './Register';
import { ForgetPassword } from './ForgetPassword';
import { ResetPassword } from './ResetPassword';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="forgetPassword" element={<ForgetPassword />} />
      <Route path="resetPassword/:token" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};
