import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import "./wallet.css";
import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Payment } from "./payment";
import { WithDraw } from "./withdraw";
import { BankAccounts } from "./bankAccounts";
import { WalletImport } from "./walletImport";
import { useParams } from "react-router-dom";
import { CreateWalletDTO, useCreateWallet } from "../api/createWallet";
import MyPayments from "@/features/History/Components/MyPayments";
import { MyTransaction } from "@/features/History/Components/MyTransaction";
import MyTransactionList from "@/features/History/Components/MyTransactionList";
import { Friends } from "@/features/Friends/Component/Friends";
import { Ticket } from "@/features/Tickets/component/ticket";
import { MyLottery } from "@/features/History/Components/MyLottery";
import inactivedropdown from "@/assets/inactivedropdown.svg";
import activedropdown from "@/assets/activedropdown.svg";
import React from "react";
import { theme } from "@/components/Elements/theme";
import HomeScreenImg from "@/assets/earth.jpg";
import { Account } from "@/features/AccountVerification/component/accountVerification";
import { getWalletAmount } from "@/components/Elements/api/getWalletamount";
import { profile } from "console";
import { BuyCoin } from "./buyCoin";
import Swal from "sweetalert2";
import { MyWinnings } from "@/features/History/Components/MyWinnings";
import KycFaceVerification from "@/features/AccountVerification/component/KycFaceVerification";
import MyWinningList from "@/features/History/Components/MyWinningList";
import WithdrawReqStatus from "./WithdrawReqStatus";
import { BuyCrypto } from "./buyCrypto";
import { useLocation } from "react-router-dom";
import LandscapeModeComponent from "@/components/Elements/landscapeComponent";
import MyAccount from "@/features/MyProfile/components/MyAccount";

type CreateWalletProps = {
  walletPassword: string;
};

export const WalletMain = ({ walletPassword }: CreateWalletProps) => {
  const [Finance, setFinance] = useState("select");
  const [HistoryData, setHistoryData] = useState("select");
  const valueUrl = useParams<{ valueUrl: string }>();
  const [Profile, setProfile] = useState("select");
  const [update, setUpdate] = useState(false);

  const [loading, setLoading] = useState(false);
  const createWalletMutation = useCreateWallet({});
  // let dataWallet = await getWalletAmount()
  let dataWallet: any;
  const [childComponent, setChildComponent] = useState(<></>);
  const [componentIndex, setComponentIndex] = useState(0);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const option = params.get("option");
  const checkOrientation = () => {
    setIsPortrait(window.innerHeight > window.innerWidth);
  };

  useEffect(() => {
    checkOrientation();
    window.addEventListener("resize", checkOrientation);
    return () => {
      window.removeEventListener("resize", checkOrientation);
    };
  }, []);

  
  useEffect(() => {
    setLoading(true);
    if (option === "buyCrypto") {
      setFinance("select");
      setProfile("select");
      setHistoryData("select");
      setChildComponent(<BuyCrypto />);
    }
    if (option === "deposit") {
      setFinance("select");
      setProfile("select");
      setHistoryData("select");
      setChildComponent(<BankAccounts />);
    }

    async function checkBalance() {
      dataWallet = await getWalletAmount();
      console.log(dataWallet?.data?.walletAmount);
      if (dataWallet?.data?.walletAmount === 0) {
        setFinance("Deposit");
      } else {
        setHistoryData("MyWinnings");
      }
      setUpdate(!update);
    }
    checkBalance();
    setTimeout(() => setLoading(false), 500);
  }, [location]);

  const handleChangeValue = (value) => {
    setLoading(true);
    switch (value) {
      case "Deposit":
        setFinance("Deposit");
        setChildComponent(<Payment />);
        break;
      case "MyLottery":
        setHistoryData("MyLottery");
        setChildComponent(<MyLottery />);
        break;
      case "MyWinnings":
        setHistoryData("MyWinnings");
        setChildComponent(<MyWinningList />);
        break;
      case "Friends":
        setChildComponent(<Friends />);
        setProfile("select");
        setHistoryData("select");
        setFinance("select");
        break;
      case "Buy Coins":
        setChildComponent(<BuyCoin />);
        setProfile("select");
        setHistoryData("select");
        setFinance("select");
        break;
      case "Ticket":
        setChildComponent(<Ticket />);
        break;
      case "WithdrawReqStatus":
        setChildComponent(<WithdrawReqStatus />);
        break;
      case "buyCrypto":
        setChildComponent(<BuyCrypto />);
        break;
    }
    setTimeout(() => setLoading(false), 500);
  };

  const handleChangeFinance = (event) => {
    setLoading(true);
    console.log(event.target.value);
    setFinance(event.target.value);
    setProfile("select");
    setHistoryData("select");
    if (event.target.value === "Withdraw") {
      setChildComponent(<WithDraw />);
    } else if (event.target.value === "Deposit") {
      setChildComponent(<Payment />);
    }
    setTimeout(() => setLoading(false), 500);
  };

  //   const InputLabelDemo = styled(Paper)(({ theme }) => ({
  //     background: "rgba(60, 55, 255, 0.03)",
  // border: "1.5px solid #3C37FF",
  // borderRadius: "10px"
  //   }));

  const createWalletImport = async (event) => {
    return;
  };
  const handleChangeProfile = (event) => {
    setLoading(true);
    console.log(event.target.value);
    setProfile(event.target.value);
    setHistoryData("select");
    setFinance("select");
    if (event.target.value === "Profile") {
      setChildComponent(<MyAccount />);
    } else if (event.target.value === "kycFaceVerifications") {
      setChildComponent(<KycFaceVerification />);
    } else if (event.target.value === "kycFaceVerifications") {
      setChildComponent(<KycFaceVerification />);
    }
    setTimeout(() => setLoading(false), 500);
  };
  const handleChangeHistory = (event) => {
    setLoading(true);

    console.log(event.target.value);
    setHistoryData(event.target.value);
    setProfile("select");
    setFinance("select");
    if (event.target.value === "MyTransaction") {
      setChildComponent(<MyTransactionList />);
    } else if (event.target.value === "MyPayments") {
      setChildComponent(<MyPayments />);
    } else if (event.target.value === "MyLottery") {
      setChildComponent(<MyLottery />);
    } else if (event.target.value === "MyWinnings") {
      setChildComponent(<MyWinningList />);
      // setChildComponent(<MyWinnings />)
    } else if (event.target.value === "MyTicket") {
      setChildComponent(<Ticket />);
    }
  };

  return (
    <>
      {isPortrait ? (
        <LandscapeModeComponent />
      ) : (
        <Box
          sx={{
            padding: { md: "130px 40px 40px 40px", xs: "90px 10px 10px 10px" },
            background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100%",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={2} sm={3} md={2}>
              <FormControl fullWidth>
                <Select
                  labelId="finance-Data"
                  IconComponent={() => (
                    <img
                      src={inactivedropdown}
                      alt="dropdownIcon"
                      style={{ position: "relative", right: "22px" }}
                    />
                  )}
                  value={Finance}
                  input={
                    <OutlinedInput
                      className={"disabledValue"}
                      notched
                      label={"Finance"}
                    />
                  }
                  label="Finance"
                  onChange={handleChangeFinance}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "black",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                          fontFamily: "Ubuntu",
                          "font-style": "normal",
                          "font-weight": 400,
                          fontSize: "14px",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="select" disabled>
                    Select
                  </MenuItem>
                  <MenuItem
                    value="Deposit"
                    onClick={() => handleChangeValue("Deposit")}
                  >
                    Deposit
                  </MenuItem>
                  <MenuItem value="Withdraw">Withdraw</MenuItem>
                  <MenuItem
                    value="WithdrawReqStatus"
                    onClick={() => handleChangeValue("WithdrawReqStatus")}
                  >
                    Withdrawal Status
                  </MenuItem>
                  <MenuItem
                    value="buyCrypto"
                    onClick={() => handleChangeValue("buyCrypto")}
                  >
                    Buy Crypto
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={2} lg={2}>
              <FormControl fullWidth>
                <Select
                  IconComponent={() => (
                    <img
                      src={inactivedropdown}
                      alt="dropdownIcon"
                      style={{ position: "relative", right: "22px" }}
                    />
                  )}
                  onChange={handleChangeHistory}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "black",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                          fontFamily: "Ubuntu",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                      },
                    },
                  }}
                  input={
                    <OutlinedInput
                      notched
                      className={"disabledValue"}
                      label={"History"}
                      sx={{ color: "#fffff" }}
                    />
                  }
                  value={HistoryData}
                  label="Profile"
                  inputProps={{
                    name: "history",
                    id: "history",
                  }}
                >
                  <MenuItem value="select" disabled>
                    Select
                  </MenuItem>
                  {/* <MenuItem value="MyWinnings" onClick={handleChangeHistory}>My Winnings</MenuItem> */}
                  <MenuItem value="MyWinnings" onClick={handleChangeHistory}>
                    My Winnings
                  </MenuItem>
                  <MenuItem value="MyLottery" onClick={handleChangeHistory}>
                    My Lotteries
                  </MenuItem>
                  <MenuItem value="MyTransaction">My Transactions</MenuItem>
                  <MenuItem value="MyPayments"> My Payments</MenuItem>
                  <MenuItem value="MyTicket"> My Tickets</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={3} md={2} lg={2}>
              <FormControl fullWidth>
                <Select
                  IconComponent={() => (
                    <img
                      src={inactivedropdown}
                      alt="dropdownIcon"
                      style={{ position: "relative", right: "22px" }}
                    />
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "black",
                        "& .MuiMenuItem-root": {
                          padding: 1,
                          fontFamily: "Ubuntu",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                        },
                      },
                    },
                  }}
                  input={
                    <OutlinedInput
                      notched
                      className={"disabledValue"}
                      label={"Profile"}
                      sx={{ color: "#fffff" }}
                    />
                  }
                  label="Profile"
                  value={Profile}
                  inputProps={{
                    name: "profile",
                    id: "profile",
                  }}
                  placeholder="Select option"
                  onChange={handleChangeProfile}
                >
                  <MenuItem value="select" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="Profile">Profile</MenuItem>

                  <MenuItem value="kycFaceVerifications">
                    KYC Face Verfication
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={2}
              sm={3}
              md={2}
              lg={2}
              sx={{ marginLeft: { md: "14px" } }}
            >
              <Button
                onClick={() => handleChangeValue("Friends")}
                variant="contained"
                fullWidth
                sx={{ p: "10px 50px", mt: "2px", height: "48px" }}
              >
                Friends
              </Button>
            </Grid>
          </Grid>
          {update ? (
            childComponent.type == React.Fragment ? (
              Finance && Finance === "Deposit" ? (
                <Payment />
              ) : (
                <MyWinningList />
              )
            ) : (
              childComponent
            )
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
