import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Divider, Grid, LinearProgress, Link, Paper, Slider, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import clsx from 'clsx';
import { TopHeadingLayout } from '@/components/layout/TopHeadingLayout';
import promotionsBackground from "@/assets/promotionsBackground.png";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from '@/assets/promotionFirst.png'
import promotionSecond from '@/assets/promotionSecond.png'
import promotionimg from '@/assets/promotionimg.png'
import { theme } from '@/components/Elements/theme';
import { url } from 'inspector';
import HomeScreenImg from "@/assets/earth.jpg";
import { useNavigate } from "react-router-dom";
import Countdown from 'react-countdown';
import storage from '@/utils/storage';
import Swal from 'sweetalert2';
import Header from '@/components/Elements/header';
import HeaderValue from "@/components/Elements/headerMain";
import { useAuth } from '@/lib/auth';
import Footer from '@/components/Elements/footer';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1)
    },
    paperClass: {
        padding: theme.spacing(2),
        textAlign: "center",
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px"
    },
    
    myGridItem: {
        backgroundColor: "rgba(5, 5, 24, 0.8)",
        padding: '30px',
        borderRadius: '20px',
    },
    innerGrid: {
        backgroundColor: "#050518",
        padding: '10px',
        borderRadius: '20px',
    },
    innerGridFirst: {
        background: `url(${promotionFirst})`,
        backgroundRepeat: "no-repeat",
        height: "auto",
        width: "auto",
        minHeight: "282px",
        minWidth: "282px",
        backgroundPosition: 'left bottom',
    },
    innerGridSecond: {
        backgroundColor: "#050518",
        padding: '10px',
        borderRadius: '20px',
    },
    innerGridThird:{
        background: `url(${promotionSecond})`,
        backgroundRepeat: "no-repeat",
        height: "auto",
        width: "auto",
        minHeight: "282px",
        minWidth: "282px",
        backgroundPosition:"center"
    },
    cardRoot:{
        background: "rgba(5, 5, 24, 0.25)", 
        boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
        borderRadius: "10px",
    }
}));
export default function PromotionDtail() {
    const classes = useStyles();
    let navigate=useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const promotionSubmit = async (event) => {
        console.log("File name")
        let token = storage.getToken();
        try {
          if(token!=null)
          {
          }
          else{
            console.log("else part")
            navigate("/auth/login")
          }
        } catch (error: any) {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            background: "black",
            color: "#ffff",
            text: "Please login and buy lottery ticket",
          });
        }
      };
      const auth = useAuth();
    return (
        <>
            {/* <Box 
            sx={{
             background: `url(${promotionsBackground})`,
             backgroundRepeat: "no-repeat",
             backgroundSize: "cover",
             minHeight: "100%",
             backgroundAttachment: "fixed",
             backgroundPosition: "center",
             padding: 0
            }}
            >
                    <Grid container justifyContent="space-around">
                        <Grid item xs={12} md={12} className={classes.innerGrid} sx={{p:theme.spacing(1)}}>
                            <Grid container>
                                <Grid item xs={12} md={3}><Box className={classes.innerGridFirst}></Box></Grid>
                                <Grid item xs={12} md={6} className={classes.innerGridSecond}>
                                    <Grid container>
                                        <Grid item xs={12} justifyContent={"center"}>
                                            <Typography variant="h2" align='center' sx={{mt:{md:"50px"}}}>Promotions</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Typography variant="h6" align='center' sx={{textTransform:"capitalize",mt:{md:"15px"}}}>Play Casino And Earn Crypto In Online. The Ultimate Online Casnio Platform.</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} display={'flex'} justifyContent={"center"} >
                                            <Button
                                                sx={{
                                                    px: "19px",
                                                    py: "7.73px",
                                                    marginBottom: "19px !important",
                                                    background: "#FFFFFF",
                                                    borderRadius: "10px",
                                                    mt:{xs:"30px"}
                                                }}
                                            >
                                                <Link href="/app/buyNow" underline='none' color='#27272F' sx={{textTransform:"capitalize"}}>Lottery</Link>
                                            </Button>
                                        </Grid>
                                        </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}><Box className={classes.innerGridThird}></Box></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </Box>
            <Box sx={{p:theme.spacing(1)}}>
            <Grid container spacing={3}>
      {cardData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
             <Card sx={{ maxWidth: 345 }} className={classes.cardRoot}>
             <CardMedia
               sx={{ height: 140 }}
               component="img"
                image={card.media}
        title="green iguana"
             />
             <CardContent>
               <Typography gutterBottom variant="h6" sx={{fontWeight:"700"}} >
               {card.title}
               </Typography>
               <Typography variant="h3" sx={{opacity:"0.6"}} >
               {card.description}
               </Typography>
             </CardContent>
             <CardActions sx={{display:"block",p:"0"}}> <LinearProgress variant="determinate" value={10} /> </CardActions>
           </Card></Grid>
           ))}
           </Grid>
           );
           </Box> */}
                 {auth.user ? <Header /> : <HeaderValue />}
              <Box sx={{
            background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100%",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            padding: 0
        }}><Box sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }} />
        <Box sx={{p:{xs:"34px", md:"50px"}}}>
        <Grid
  container
  display={"flex"}
  direction={"column"}
  // marginTop={"60px"}
  sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
>
            <Grid item xs={12} sm={10} md={8} lg={6} >
    <Grid
      container
      display={"flex"}
      direction={"row"}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{    marginBottom: "40px", p: theme.spacing(1)}}
    >
       
    
        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card 
           sx={{ margin: "0 auto", background:"none"}}>
            <div style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>
                <img src={promotionimg} style={{maxWidth:"700px", width:"100%"}} />
                <Button  onClick={promotionSubmit} variant="contained" sx={{ p:"10px 39px" , width:"fit-content", marginTop:"30px"}}>
                Buy Tickets
                </Button>
                <div style={{maxWidth:"700px" , color:"white", textAlign:"center"}} >
                    <p style={{fontSize:"35px"  , fontWeight:700 , lineHeight:"130%" }}>Play more and save up to <span style={{color:"#3C37FF"}}>25%</span> with a Multi-Draw Package</p>
                    <p style={{fontSize:"16px"  , fontWeight:500 }}>Lorem Ipsum is simply dummy text of the printing</p>
                    <p style={{fontSize:"14px" , opacity:"0.7" , fontWeight:400 , lineHeight:"150%" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p style={{fontSize:"14px" , opacity:"0.7" , fontWeight:400 , lineHeight:"150%" }}>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>
            </div>
          </Card>
        </Grid>
    </Grid>
  </Grid>
  </Grid>
  </Box>
        </Box>
           </>
    );
}
