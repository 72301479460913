import { useNavigate } from 'react-router-dom';
import { ForgetPasswordForm } from '../components/ForgetPasswordForm';
import { Layout } from '../components/Layout';
import ForgetPasswordModal from '@/components/Modals/forgetPasswordModal';
import React from 'react';


export const ForgetPassword = () => {
  const navigate = useNavigate();

  const [isOpen,setIsopen]= React.useState(false);

  const handleClose = () => {
    setIsopen(false);
    navigate("/auth/login")
  };

  return (
    <Layout title="Log in to your account">
      <ForgetPasswordForm onSuccess={() => setIsopen(true)} />
      <ForgetPasswordModal open={isOpen} onClose={handleClose} />
    </Layout>
  );
};