import { Box, Paper, Typography, Grid, Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';
import aboutLotteryImage1 from "../../../assets/aboutLotteryImage1.png";
import DollarInHand from "../../../assets/DollarInHand.png";
import RightArrow from "../../../assets/RightArrow.png";
import transparentPattern from "../../../assets/transparentPattern.png";
import HomeScreenImg from "@/assets/earth.jpg";
import handPointer from "../../../assets/handPointer.png";
import Lottery from "../../../assets/2Lottery.png";
import eyeOnTime from "../../../assets/eyeOnTime.png";
import cup from "../../../assets/cup.png";
import gamblingTable2 from "../../../assets/gamblingTable2.png";
import gamblingTable from "../../../assets/gamblingTable.png";
import gamblingTable3 from "../../../assets/gamblingTable3.png";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";
import { getAboutUs, useAboutUsValue } from "../api/aboutus";
import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useEffect } from "react";
import React from "react";
import { Flex } from "@mantine/core";
import { theme } from "@/components/Elements/theme";

const useStyles = makeStyles((theme) => ({
    box1: {
        padding: "15px",
        paddingBottom: "50px",
        // backgroundImage: `url(${HomeScreenImg})`,
        backgroundRepeat: "no-repeat, repeat",
        backgroundPosition: "center",
    },
    flexWrap: {
        flexWrap: "wrap"
    },
    number: {
        fontFamily: 'Ubuntu',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "38.0759px",
        lineHeight: "44px",
        textTransform: "capitalize",
        marginRight: "26px"
    },
    dFlex: {
        display: "flex",
    },
    justifyEnd: {
        justifyContent: "end"
    },
    justifyCenter: {
        justifyContent: "center"
    },
    heading1: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "35px",
        lineHeight: "140%",
        textAlign: "center",
        color: "#FFFFFF",
    },
    para1: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "140%",
        textAlign: "center",
        textTransform: "uppercase",
        color: "#FFFFFF",
        opacity: 0.7,
        margin: "10px 0px"
    },
    playNow: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#27272F",
        flex: "none",
        order: 0,
        flexGrow: 0,
        padding: "8px 16px",
        background: "#FFFFFF",
        borderRadius: "10px",
        border: "1px solid white",
        margin: "10px 5px"
    },
    getStarted: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "rgba(255, 255, 255, 0.8)",
        flex: "none",
        order: 1,
        flexGrow: 0,
        background: "transparent",
        border: "1px solid white",
        borderRadius: "10px",
        padding: "5px 20px",
        margin: "10px 5px"
    },
    bgBlack: {
        background: "rgba(5, 5, 24, 0.8)",
        // color: "white",
        [theme.breakpoints.down('sm')]: {
            padding: "10px"
        },
    },
    whoWeAre: {
        margin: "auto auto",
        borderRadius: "10px",
        marginTop: "50px",
        [theme.breakpoints.down('sm')]: {
            width : "100%"
        },
    },
    heading2: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "25px",
        lineHeight: "140%",
        color: "#FFFFFF"
    },
    para2: {
        fontFamily: "'Ubuntu'",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "150%",
        color: "#FFFFFF",
        opacity: 0.7,
        maginTop: "10px",
        [theme.breakpoints.down('sm')]:{
            fontSize:"14px"
        },
    },
    howItsWork: { 
        marginTop: "50px",
        gap:"30px", 
        [theme.breakpoints.down('sm')]:{
            gap:"10px",
            justifyContent:"space-evenly"
        },
    },
    arrowIcon: {
        height: "15px",
        marginTop: "55px",
        [theme.breakpoints.down('sm')]: {
            minHeight: "10px",
            marginTop: "30px",
        },
    },
    width100 : {
        width : "100%",
        [theme.breakpoints.down('sm')]: {
            minWidth: "64px",
        },
    },
    paddingRemove : {
        padding:"80px",
        [theme.breakpoints.down('sm')]: {
            padding: "40px 10px"
        },
    },
    imgSize:{
        borderRadius: "12px",
        // width: "170px",
        // height: "181px"
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

 function MainAbout() {
    const classes = useStyles();
    const auth = useAuth();
    const dataValue =  useAboutUsValue({});
    
    
    
          
    return (
        <>
       {auth.user ? <Header /> : <HeaderValue />}
        <Box sx={{pt: "100px" , backgroundImage: `url(${HomeScreenImg})`, ackgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          color:"#FFFFFF",
          backgroundPosition: "center" }} >
            <Box className={classes.box1} sx={{pt:"0px !important",pb:"0px !important"}}>
                {/* <Box className={`${classes.dFlex} ${classes.justifyEnd}`}>
                    <Typography className={classes.number} >06</Typography>
                </Box> */}
                <Typography className={`${classes.heading1}`}>About Us</Typography>
                {/* <Typography className={`${classes.para1}`}>Play Online Casino & Win Money Unlimited</Typography>
                <Box className={`${classes.dFlex} ${classes.justifyCenter}`}>
                    <button className={classes.playNow}>Play Now</button>
                    <button className={classes.getStarted}>Get Started</button>
                </Box> */}
            </Box>
            <Grid sx={{mt:"20px !important", alignItems:"center"}} className={`${classes.bgBlack} ${classes.dFlex} ${classes.justifyCenter} ${classes.whoWeAre} ${classes.flexWrap}`} container spacing={1} xs={12}>
                <Grid item xs={10} md={6}>
                    <Typography style={{ paddingTop: "40px" }} className={classes.heading2}>{dataValue?.data?.[0]?.whoHeading}</Typography>
                    {dataValue?.data?.[0]?.whoDescription!== undefined && (
                          <div className={classes.para2} dangerouslySetInnerHTML={{ __html: dataValue?.data && dataValue?.data?.[0]?.whoDescription}} />
                          )}
                    {/* <Typography style={{ marginTop: "20px" }} className={classes.para2}>{dataValue?.data?.[0]?.whoDescription} </Typography> */}
                </Grid>
                <Grid className={`${classes.dFlex} ${classes.justifyCenter}  `} item xs={10} md={5} >
                        <img src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataValue?.data?.[0]?.whoImage?.[0]}`}   alt=""  
                        style={{
                            borderRadius: "12px",
                            maxWidth: "250px",
                            maxHeight: "250px",
                            width:"100%",
                            height:"100%"
                                // margin: "16px"
                        }}
                        
                        />
                </Grid>
            </Grid>
            <Box sx={{background: "#1B1C2D", padding:{xs:"40px", md:"70px"}}}>
            <Typography style={{ textAlign: "center" }} className={`${classes.heading2}`}>How It Works</Typography>
            <Typography style={{ marginTop: "20px", textAlign: "center" }} className={classes.para2}>Lorem ipsum dolor sit amet. Est exercitationem consequuntur et esse quidem </Typography>
            <Grid className={` ${classes.howItsWork}`} justifyContent={"center"} container spacing={0} >
                <Grid item xs={3} sm={2.5}  md={2} lg={"auto"} className={`${classes.dFlex} ${classes.imgSize}`} >
                    <Box sx={{display:"flex", flexFlow:"column", alignItems:"center", gap:"15px"}}>
                        <img className={`${classes.width100} ${classes.imgSize}`} src={DollarInHand} alt="" 
                        style={{maxWidth:"120px", maxHeight:"120px", width:"100%", height:"100%"}}
                        />
                        <Typography style={{ textAlign: "center", whiteSpace:"nowrap" }} className={classes.para2}>Deposit Funds</Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} sm={2}  md={"auto"} className={`${classes.dFlex} ${classes.justifyCenter} ${classes.imgSize}`}>
                    <img className={classes.arrowIcon} src={RightArrow} alt="" 
                       
                    />
                </Grid>
                <Grid item xs={3} sm={2.5}  md={2} lg={"auto"} className={`${classes.dFlex} ${classes.imgSize}`}>
                    <Box sx={{display:"flex", flexFlow:"column", alignItems:"center", gap:"15px"}}>
                        <img className={classes.width100} src={handPointer} alt="" 
                          style={{maxWidth:"120px", maxHeight:"120px"}}  
                        />
                        <Typography style={{ textAlign: "center" , whiteSpace:"nowrap" }} className={classes.para2}>Choose Lottery</Typography>
                    </Box>

                </Grid>
                <Grid item xs={3} sm={2}  md={2} lg={"auto"} className={`${classes.dFlex} ${classes.justifyCenter}`}>
                    <img className={classes.arrowIcon} src={RightArrow} alt="" 
             
                    />
                </Grid>
                <Grid item xs={3} sm={2.5}  md={2} lg={"auto"} className={classes.dFlex}>
                    <Box sx={{display:"flex", flexFlow:"column", alignItems:"center", gap:"15px"}}>
                        <img className={classes.width100} src={Lottery} alt="" 
                            style={{maxWidth:"120px", maxHeight:"120px"}}
                        />
                        <Typography style={{ textAlign: "center", whiteSpace:"nowrap" }} className={classes.para2}>Buy Tickets</Typography>
                    </Box>

                </Grid>
                <Grid item xs={3} sm={2}  md={2} lg={"auto"} className={`${classes.dFlex} ${classes.justifyCenter}`}>
                    <img className={classes.arrowIcon} src={RightArrow} alt="" 
               
                    />
                </Grid>
                <Grid item xs={3} sm={2.5} md={2} lg={"auto"} className={classes.dFlex}>
                    <Box sx={{display:"flex", flexFlow:"column", alignItems:"center", gap:"15px"}}>
                        <img className={classes.width100} src={eyeOnTime} alt=""
                            style={{maxWidth:"120px", maxHeight:"120px"}}
                        />
                        <Typography style={{ textAlign: "center", whiteSpace:"nowrap" }} className={classes.para2}>Watch the Draw</Typography>
                    </Box>

                </Grid>
                <Grid item xs={3} sm={2}  md={2} lg={"auto"} className={`${classes.dFlex} ${classes.justifyCenter}`}>
                    <img className={classes.arrowIcon} src={RightArrow} alt="" 
          
                    />
                </Grid>
                <Grid item  xs={3}  sm={2.5}  md={2} lg={"auto"} className={classes.dFlex}>
                    <Box sx={{display:"flex", flexFlow:"column", alignItems:"center", gap:"15px"}}>
                        <img className={classes.width100} src={cup} alt="" 
                            style={{maxWidth:"120px", maxHeight:"120px"}}
                        />
                        <Typography sx={{ textAlign: "center", whiteSpace:"nowrap" }} className={classes.para2}>Claim your winnings</Typography>
                    </Box>
                </Grid>
            </Grid>
            </Box>
            <Box className={classes.bgBlack} p={10}>
                <Grid className={`${classes.flexWrap} ${classes.justifyCenter}`} container spacing={1}>
                    <Grid xs={10} md={6} mt={5}>
                    <Typography className={classes.heading2}>{dataValue?.data?.[0]?.choiceHeading}</Typography>
                        {dataValue?.data?.[0]?.choiceDescription!== undefined && (
                          <div className={classes.para2} dangerouslySetInnerHTML={{ __html: dataValue?.data && dataValue?.data?.[0]?.choiceDescription}} />
                          )}
                        {/* <Typography className={classes.para2} mt={5}>{dataValue?.data?.[0]?.choiceDescription}</Typography> */}
                    </Grid>
                    <Grid xs={10} md={6} className={`${classes.dFlex} ${classes.justifyCenter}`}>
                            <img src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataValue?.data?.[0]?.choiceImage?.[0]}`} height="404px" width="407px"  alt="" 
                             style={{
                                borderRadius: "12px",
                                width: "253px",
                                height: "250px",
                                margin: "16px"
                            }}
                            />
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.paddingRemove} p={10}>
                <Grid className={`${classes.flexWrap} ${classes.justifyCenter}`} container spacing={3}>
                    <Grid item xs={10} md={6} 
                        className={`${classes.dFlex} ${classes.justifyCenter} `} 
                        sx={{
                            alignItems:"center",
                            [theme.breakpoints.down("sm")]:{
                                order:"1"
                            }
                        }}
                    >
                            <img src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataValue?.data?.[0]?.businessImage?.[0]}`} height="404px" width="407px" alt="" 
                             style={{
                                borderRadius: "12px",
                                width: "253px",
                                height: "250px",
                                margin: "16px"
                            }}
                            />
                    </Grid>
                    <Grid item xs={10} md={6} >
                        <Typography className={classes.heading2}>{dataValue?.data?.[0]?.businessHeading}</Typography>
                        {dataValue?.data?.[0]?.businessDescription!== undefined && (
                          <div className={classes.para2} dangerouslySetInnerHTML={{ __html: dataValue?.data && dataValue?.data?.[0]?.businessDescription}} />
                          )}
                        {/* <Typography className={classes.para2} mt={5}>{dataValue?.data?.[0]?.businessDescription}</Typography> */}
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.bgBlack} p={10} >
                <Grid className={`${classes.flexWrap} ${classes.justifyCenter}`} container spacing={1}>
                    <Grid xs={10} md={6} mt={5}>
                        <Typography className={classes.heading2}>{dataValue?.data?.[0]?.secureHeading}</Typography>
                        {dataValue?.data?.[0]?.secureDescription!== undefined && (
                          <div className={classes.para2} dangerouslySetInnerHTML={{ __html: dataValue?.data && dataValue?.data?.[0]?.secureDescription}} />
                          )}
                        {/* <Typography className={classes.para2} mt={5}>{dataValue?.data?.[0]?.secureDescription}</Typography> */}
                    </Grid>
                    <Grid xs={10} md={6} className={`${classes.dFlex} ${classes.justifyCenter}`}>
                            <img src={`${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${dataValue?.data?.[0]?.secureImage?.[0]}`} height="404px" width="407px" alt=""
                             style={{
                                borderRadius: "12px",
                                width: "253px",
                                height: "250px",
                                margin: "16px"
                            }}
                            />
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Footer />
       
        </>
    )
}

export default MainAbout