import { Box, Fade, Modal } from "@mui/material";
import React from "react";
import { theme } from "./theme";
import { Opacity } from "@material-ui/icons";
import "./CSS/style.css"
const style = {
    position: 'absolute',
    left: '50%',
    top: '50%',  
    transform: "translate(-50%, -50%) !important",
    maxWidth: { xs: '241px', sm: '241px', md: '241px', lg: '241px' },
    outline:"none",
    width: '100%',
    height: 'auto', 
    bgcolor: "#050518",
    p: 0, 
    borderRadius: "8px",
    overflow: 'auto',
    border: '1px solid black',
    zIndex:"2147483647"
};

interface iModalProps {
    open: boolean;
    onClose: () => void;
    childComponent: any;
};

export const CustomModel = ({
    open,
    onClose,
    childComponent
}: iModalProps) => {
    return (
        <Modal closeAfterTransition open={open}
        onClose={() => !open}
        hideBackdrop={true}
        sx={{display:"flex", alignItems:"center", justifyContent:"center",  transform: `translate(0%, 0%)`, zIndex:"2147483647", outline:"none", backgroundColor: "rgb(143 143 143 / 50%) !important"}}>
            <Fade in={open}>
                <Box sx={style} className="wheel-cstm">
                    {childComponent}
                </Box>
            </Fade>
        </Modal>
    )
}
