import { useNavigate } from 'react-router-dom';

import { FAQData } from '../components/faq';


export const FaqMain = () => {
  const navigate = useNavigate();

  return (
   <FAQData/>
  );
};