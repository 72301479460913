import { Close } from '@mui/icons-material';
import { Box, Stack, IconButton, Typography, Paper, Divider, Button, CircularProgress } from '@mui/material';
import { CustomModelLotteryPool } from "@/components/Elements/custom-modalLotteryPool";
import lostLogo from "@/assets/lostLoogo.svg";
import closeModal from "@/assets/closeModal.svg";
import { makeStyles } from "@material-ui/core/styles";
import arrow from "@/assets/arrow.png";
import { useEffect, useState } from 'react';
interface lotteryPoolModalProps {
    open: boolean;
    onClose: () => void;
    noOfTicketsSold: number;
    percentageOfticketsSold: number;
    totalPrizeofTickets: number;
    handleStartDraw: () => void;
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    paperClass: {
        padding: theme.spacing(2),
        textAlign: "center",
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px",
    },
    first: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: 467,
    },
    second: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        height: 150,
    },
    parentPaper: {
        padding: theme.spacing(2),
        margin: "auto",
        maxWidth: 1600,
    },
    availableTickets: {
        border: "0.5px solid rgba(255, 255, 255, 0.2)",
        borderRadius: "10px",
        height: 110,
    },
    noOfTickets: {
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px",
        height: 180,
    },
    tableContainer: {
        background: "#050518",
        borderRadius: 8,
    },
    table: {
        minWidth: 280,
    },
    winnerModalImg: {
        [theme.breakpoints.down('sm')]: {
            height: "60px ! important",
            width: "60px !important"
        },
    },
    tableHeader: {
        color: "#fff",
    },
    tableData: {
        fontSize: "14px",
        color: "#fff",
        opacity: "0.6",
        borderBottom: "1px solid #0D0D39",
    },
    minimumTicket: {
        background: "#3C37FF",
        borderRadius: "10px",
        color: "#fffff",
    },
    logo: {
        width: 50,
    },
    myGridItem: {
        backgroundColor: "rgba(5, 5, 24, 0.8)",
        padding: "30px",
        borderRadius: "20px",
    },
    innerGrid: {
        backgroundColor: "#050518",
        padding: "10px",
        borderRadius: "20px",
    },
    innerGridFirst: {
        backgroundRepeat: "no-repeat",
        height: "auto",
        width: "auto",
        minHeight: "282px",
        minWidth: "282px",
        backgroundPosition: "left bottom",
        [theme.breakpoints.down("sm")]: {
            minHeight: "200px",
            minWidth: "200px",
        },
    },
    substract: {
        border: "1px solid #ffff !important",
        borderRadius: "10px !important",
        color: "#ffff",
    },
    innerGridSecond: {
        backgroundColor: "#050518",
        padding: "10px",
        borderRadius: "20px",
    },
    ticketPrize: {
        background: "rgba(5, 5, 24, 0.8)",
        borderRadius: "10px",
        padding: "30px",
    },
    ticketContainer: {
        width: '134px',
        height: '60px',
        background: '#FFFF',
        borderRadius: '10px',

        position: 'relative',
    },
    detailContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '14px',
        padding: "12.86px"
    },
    detailLabel: {
        height: '11px',
        fontFamily: 'Ubuntu',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '11px',
        color: 'rgba(27, 28, 45, 0.6)',
        margin: '0',
    },
    detailNumber: {
        height: '23px',
        fontFamily: 'Ubuntu',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20.3636px',
        lineHeight: '23px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: '#27272F',
        margin: '0',
    },
    detailTime: {
        width: '82px',
        height: '16px',
        fontFamily: 'Ubuntu',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#1B1C2D',
        margin: '0',
    },
    list: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 2px 2px 5px 0 rgba(#fff, 0.5)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundImage: "linear-gradient(180deg, #3b97bb 0%, #aed1db 99%)",
            boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
            borderRadius: "100px",
        }
    },
    jackpotContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
        height: '38px',
        background: 'rgba(60, 55, 255, 0.15)',
        marginBottom: '14px',
    },
    jackpotText: {
        width: '99px',
        height: '23px',
        fontFamily: 'Ubuntu',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20.3636px',
        lineHeight: '23px',
        letterSpacing: '0.2em',
        textTransform: 'uppercase',
        color: '#27272F',
        margin: 'auto',
    },
    arrowImage: {
        position: 'absolute',
        width: '30px',
        height: '30px',
        left: '74%',
        transform: 'translateX(-50%)',
    },
    polyup: {
        top: '-15px',
        left: "86%"
    },
    polydown: {
        bottom: '-15px',
        transform: 'rotate(180deg)',
    },
}));

const LotteryPoolModal = ({ open, onClose, noOfTicketsSold, percentageOfticketsSold, totalPrizeofTickets, handleStartDraw }: lotteryPoolModalProps) => {
    const [countdown, setCountdown] = useState(5);
    const [isCountingDown, setIsCountingDown] = useState(false);
 
  
    useEffect(() => {
        let timer;
        if (isCountingDown && countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsCountingDown(false);
            setCountdown(5); // Reset countdown for next time
            handleStartDraw(); // Call the start draw function
          
        }
        return () => clearTimeout(timer);
    }, [countdown, isCountingDown, handleStartDraw]);

    const handleButtonClick = () => {
        setIsCountingDown(true);
    };
    const classes = useStyles();
    return (
        // <CustomModelLotteryPool
        //     open={open}
        //     onClose={onClose}
        //     childComponent={
        //         <>
        //             <Stack
        //                 direction='row'
        //                 height='100%'
        //                 sx={{ p: 0 }}
        //             >
        //                 <Box sx={{ width: '100%', overflow: 'auto', p: "12px" }} display='flex' flexDirection='column' alignItems='center' >
        //                     <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", color: "#ccebef" }}>
        //                         Competing Tickets
        //                     </Typography>
        //                     <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", color: '#3b36fb' }}>
        //                         {noOfTicketsSold} &nbsp;&nbsp;{percentageOfticketsSold}%
        //                     </Typography>
        //                     <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", color: "#ffffff" }}>
        //                         Total Prize Money
        //                     </Typography>
        //                     <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", color: "#d6932f" }}>
        //                         {totalPrizeofTickets}
        //                     </Typography>
        //                     <IconButton
        //                         sx={{
        //                             textAlign: "center",
        //                             fontWeight: "bold",
        //                             fontSize: "24px",
        //                             color: "#c8e8ed",
        //                             backgroundColor: '#3c97bb',
        //                             borderRadius: '5px',
        //                             '&:hover': {
        //                                 backgroundColor: '#3c97bb',
        //                             }
        //                         }}
        //                         onClick={handleButtonClick}
        //                         disabled={isCountingDown}
        //                     >
        //                         <span style={{ color: isCountingDown ? 'white' : '#c8e8ed' }}>
        //                             {isCountingDown ? countdown : "START DRAW"}
        //                         </span>
        //                     </IconButton>
        //                 </Box>
        //                 <Box>

        //                 </Box>
        //             </Stack>
        //         </>
        //     }
        // />

        <CustomModelLotteryPool
        open={open}
        onClose={onClose}
        childComponent={
            <Stack
                direction='row'
                height='100%'
                sx={{ p: 0 }}
            >
                <Box sx={{ width: '100%', overflow: 'auto', p: "12px" }} display='flex' flexDirection='column' alignItems='center' >
                    <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", color: "#ccebef" }}>
                        Competing Tickets
                    </Typography>
                    <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", color: '#3b36fb' }}>
                        {noOfTicketsSold} 
                    </Typography>
                    <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", color: '#3b36fb' }}>
                      { parseFloat(percentageOfticketsSold.toFixed(2))}%
                    </Typography>
                    <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "26px", color: "#ffffff" }}>
                        Total Prize Money
                    </Typography>
                    <Typography component="h6" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", fontSize: "24px", color: "#d6932f" }}>
                        {totalPrizeofTickets}
                    </Typography>
                    <IconButton
                        sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "24px",
                            color: "#c8e8ed",
                            backgroundColor: '#3c97bb',
                            borderRadius: '5px',
                            '&:hover': {
                                backgroundColor: '#3c97bb',
                            }
                        }}
                        onClick={handleButtonClick}
                        disabled={isCountingDown}
                    >
                        <span style={{ color: isCountingDown ? 'white' : '#c8e8ed' }}>
                            {isCountingDown ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "START DRAW"}
                        </span>
                    </IconButton>
                </Box>
            </Stack>
        }
    />
    );
};

export default LotteryPoolModal;
