import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  LinearProgress,
  Pagination,
  Typography,
} from "@mui/material";

import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import { useNavigate } from "react-router-dom";
import promotionSecond from "@/assets/promotionSecond.png";
import HomeScreenImg from "@/assets/earth.jpg";
import { UsePromotionByReferList } from "../api/getPromotionByRefer";
import { UsePromotionByBuyList } from "../api/getPromotionByBuy";
import { useAuth } from "@/lib/auth";
import storage from "@/utils/storage";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridThird: {
    background: `url(${promotionSecond})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "center",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 1)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
}));
interface Promotion {
  _id: string;
  rewardBy: string;
  rewardAmount: number;
  couponCode: string;
  startDate: string;
  endDate: string;
  targetUser: number;
  sentNumTargetUser: number;
  repeatReward: boolean;
  usesPerCoupon: string;
  comments: string;
  status: boolean;
  minimumCartValue: string;
  discountType: string;
  usesPerPlayer: number;
  promotionName: string;
  promotionDetails: string;
  image: string;
  lotteryId: string;
  usedPromotionUser: any[];
  createdAt: string;
  updatedAt: string;
}

interface PromotionResponse {
  message: string;
  data: Promotion[]; // Array of promotion objects
  pagination: {
    totalItems: number;
    totalPages: number;
    itemsPerPage: number;
  };
}

export default function PromotionFreeTicket() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [buyPromotion, setPromotion] = useState<PromotionResponse | null>(null);

  console.log(buyPromotion, "buyPromotion");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const limit = 10;
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getData();
  }, [page]);
  const getData = async () => {
    setLoading(true);
    let token = storage.getToken();
    try {
      let response = await axios.get(
        `${process.env.API_URL_PREFIX}/api/v1/user/buyPromotionList`,
        {
          params: { page, limit },
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      console.log(response, "response");
      setPromotion(response?.data?.data);
    } catch (error) {
      console.log("token error", error);
    } finally {
      setLoading(false);
    }
  };

  const gridItem = {};
  return (
    <>
      {/* {auth.user ? <Header /> : <HeaderValue />} */}
      <Box
        sx={{
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          paddingBlock: "100px 70px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "rgba(5, 5, 24, 0.8)",
            borderRadius: "10px",
            marginTop: "0px",
          }}
        />
        <Box
          sx={{
            width: "100%",
            maxWidth: "768px",
            margin: { xs: "50px auto", md: "60px auto" },
            textAlign: "center",
            fontFamily: '"Ubuntu", sans-serif',
            fontSize: "25px",
            lineHeight: "1.5",
          }}
        >
          <Typography
            component="h4"
            variant="h4"
            sx={{
              textTransform: "capitalize",
              fontWeight: "500 !important",
              marginBottom: { xs: "18px", md: "25px" },
            }}
          >
            Promotions
          </Typography>
          <Typography
            component={"p"}
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          >
            Buy lottery tickets online for the top international lotteries.
            Safely and securely playfor the biggest jackpots in the entire world
            from the comfort of home.
          </Typography>
        </Box>
        <Box
          sx={{
            p: { xs: "30px", md: "40px" },
            backgroundColor: "rgb(95 95 95 / 80%)",
            borderRadius: "10px",
            mt: "0px",
            maxWidth: "1700px",
            marginInline: "auto",
          }}
        >
          <Grid container  justifyContent="start" spacing={3}>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  height="100vh"
                >
                  <CircularProgress />
                </Box>
              ) : (
                buyPromotion?.data?.map((card, index) => (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    className="inner-grid"
                    style={gridItem} 
                  >
                    <Card
                      sx={{
                        width: "100%",
                        maxWidth:" 100%",
                        height: "100%",
                        alignItems: "center",
                      }}
                      className={classes.cardRoot}
                      onClick={() =>
                        navigate(
                          `/app/BuyFreeTicket/${card?.lotteryId}?redeemCode=${card?.couponCode}&freeTicketAmt=${card?.rewardAmount}`
                        )
                      }
                    >
                      <CardMedia
                        sx={{ height: 200, objectFit: "cover" }}
                        component="img"
                        image={
                          card?.image
                            ? `${process.env.API_URL_PREFIX}/api/v1/admin/uploads/${card.image}`
                            : promotionCard
                        }
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              variant="caption"
                              sx={{ color: "#FFFFFF", opacity: 0.5 }}
                            >
                              Reward amount:
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 700 }}>
                              {card?.rewardAmount}
                            </Typography>
                          </Grid>
                          <Grid item xs={6} sx={{ textAlign: "right" }}>
                            <Typography
                              variant="caption"
                              sx={{ color: "#FFFFFF", opacity: 0.5 }}
                            >
                              Coupon code:
                            </Typography>
                            <Typography variant="h3" sx={{ opacity: 0.6 }}>
                              {card?.couponCode}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          variant="caption"
                          sx={{ color: "#FFFFFF", opacity: 0.5 }}
                        >
                          Description:
                        </Typography>
                        <Typography variant="h3" sx={{ opacity: 0.6 }}>
                          {card?.comments}
                        </Typography>
                      </CardContent>

                      <CardActions sx={{ p: 0 }}>
                        <LinearProgress variant="determinate" value={10} />
                      </CardActions>
                    </Card>
                  </Grid>
                ))
              )}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" marginTop="40px">
                  <Pagination
                    count={buyPromotion?.pagination?.totalPages ?? 0}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "white",
                        fontSize: "18px",
                      },
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: "primary",
                        color: "white",
                        fontSize: "25px",
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          
        </Box>
      </Box>
    </>
  );
}
