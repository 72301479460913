import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { homeResponse } from '../types';
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '@/lib/react-query';

export const getHomeData = async () => {
  const response = await axios.get(`/api/v1/user/homeSection`, {
  })
    //return response?.data;
     const data = response.data;
    return data;
}

type QueryFnType = typeof getHomeData;
type UseHomeScreenListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useHomeScreenList = ({  config }: UseHomeScreenListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getHomeData(),
    ...config,
  });
};