import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
// import { contactList } from '../types';

export const getAboutUs = async (payload={})=> {
  const response = await axios.get(`/api/v1/user/aboutus`, payload)
   const data = response.data;
  return data;
};
type QueryFnType = typeof getAboutUs;
type useAboutUsValueOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAboutUsValue = ({  config }: useAboutUsValueOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getAboutUs(),
    ...config,
  });
};


