import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';




export const getTicketList = async (page: number, limit: number) => {
  const response = await axios.get(`/api/v1/user/ticketNFTList`, {
    params: { page, limit }, 
  });
  return response; 
};

type QueryFnType = typeof getTicketList;


type UseTicketListOptions = {
  page: number;
  limit: number;
  config?: QueryConfig<QueryFnType>;
};

export const useTicketList = ({ page, limit, config }: UseTicketListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['ticketList', page, limit] as const,
    queryFn: () => getTicketList(page, limit),
    keepPreviousData: true,
  });
 
};
