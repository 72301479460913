import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  FormControl,
  InputLabel,
  Stack,
  Typography,
  Divider,
  CardMedia,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Menu,
  OutlinedInput,
} from "@mui/material";

import { Grid, Select, MenuItem } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { theme } from "@/components/Elements/theme";
import { MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { MantineProvider } from "@mantine/core";
import { Title } from "@material-ui/icons";
import { DatePickerInput } from "@mantine/dates";
import { useAuth } from "@/lib/auth";
import { getFriendsList, useFriendsList } from "../api/getFriendList";
import { getPromotionList } from "../api/getPromotionList";
import { postFriendsRefer } from "../api/postReferfriend";
import "./table.css";
import { friendsList, Payment } from "../types";
import "./table.css";
import Swal from "sweetalert2";

//nested data is ok, see accessorKeys in ColumnDef below

type letterValues = {
  email: string;
  coupanId: string,
  couponCode: any
};

const initialValues = {
  email: "",
  coupanId: "",
  couponCode: ""
};

export const Friends = () => {
  const { user } = useAuth();
  const FriendsQuery = useFriendsList({});
  const [promotionList, setPromotionList] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState("select");
  const [data, setData] = useState("")
  const [formValues, setFormValues] = useState(initialValues);
  const Item = styled(Paper)(({ theme }) => ({
    background: "transparent",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  }));

  var cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "auto",
    background: "#050518",
    boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
    borderRadius: "20px",
  };

  useEffect(() => {
    promotionList1();
  }, [data])
  const promotionList1 = async () => {
    const PromotionsQuery = await getPromotionList();
    setPromotionList(PromotionsQuery);

  }
  const handleSimple = (e: any) => {
    let p: any;
    p = promotionList.find((o: any) => o.coupanId === e.target.value);
    if (p) {
      setFormValues({
        ...formValues,
        "coupanId": e.target.value,
        "couponCode": p.couponCode
      });
    }
    setSelectedCoupon(e.target.value);
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let data = { email: formValues.email };

    try {
      postFriendsRefer(formValues).then((res) => {
        setData(res)
      });
      setFormValues({
        email: "",
        coupanId: "",
        couponCode: ""
      });
      setSelectedCoupon("select");
    } catch {
      Swal.fire({
        icon: "error",
        title: "Friend Refer not sent!",
      });
    }
  };

  var center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  var content = {
    color: "#ffff",
    backgroundColor: "#050518",
    borderRadius: "12px",
  };
  const columns = useMemo<MRT_ColumnDef<friendsList>[]>(
    () => [
      {
        accessorKey: "email",
        header: "Email",
        size: 100,
        //custom conditional format and styAling
      },

      {
        accessorKey: "amount",
        header: "Amount",
        size: 100,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) =>
          row?.createdAt
            ? new Date(row?.createdAt)?.toISOString().split("T")[0] +
            " " +
            new Date(row?.createdAt)
              ?.toISOString()
              .split("T")[1]
              .substring(0, 5)
            : "",
        header: "Registration",
        size: 100,
        //custom conditional format and styling
      },

      {
        accessorKey: "status", //hey a simple column for once
        header: "Status",
        size: 100,
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              color:
                cell.getValue<string>() === "Approved"
                  ? "#95D23D"
                  : cell.getValue<string>() === "Pending"
                    ? "#FF9900"
                    : "#FF4545",
              borderRadius: "4px",
              border:
                cell.getValue<string>() === "Approved"
                  ? "1px solid rgba(149, 210, 61, 0.41)"
                  : cell.getValue<string>() === "Pending"
                    ? "1px solid rgba(231, 214, 61, 0.5)"
                    : "1px solid rgba(255, 69, 69, 0.4)",
              backgroundColor:
                cell.getValue<string>() === "Approved"
                  ? "rgba(149, 210, 61, 0.19)"
                  : cell.getValue<string>() === "Pending"
                    ? "rgba(231, 214, 61, 0.1)"
                    : "rgba(255, 69, 69, 0.05)",
              maxWidth: "9ch",
              padding: "4px",
            })}
          >
            {cell.getValue<string>()}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Box sx={{ height: "auto" }}>
      <Grid container sx={{ mt: theme.spacing(0.5) }}>
        <Grid item xs={12} md={12}>
          {" "}
          <Card
            style={cardStyle}
            sx={{ p: theme.spacing(0.3), m: theme.spacing(0.2) }}
          >
            <CardContent>
              <Stack spacing={2}>
                <Item>
                  {" "}
                  <Typography variant="h5">Friends Referral</Typography>
                </Item>
                <Item sx={{ width: "100%" }}>
                  <Grid item xs={12} md={12}>
                    <Item
                      sx={{
                        display: { xs: "flex" },
                        flexDirection: { xs: "column", md: "row" },
                      }}
                    >
                      {" "}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={{ pt: "26px", width: { xs: "100%" } }}
                      >
                        <FormControl fullWidth={true}>
                          <InputLabel
                            htmlFor="email"
                            shrink={true}
                            sx={{ color: "#FFFFFF" }}
                          >
                            Email Address
                          </InputLabel>
                          <TextField
                            margin="normal"
                            required
                            id="email"
                            autoFocus
                            name="email"
                            onChange={handleChange}
                            value={formValues.email}
                            placeholder="Enter Friend Email Id"
                            inputProps={{
                              inputMode: "text", // Ensures the text keyboard is displayed on mobile
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} sm={4} md={3} sx={{ pt: "40px" }}>
                        {/* {selectedState && (
                          <Typography item xs={12} md={12}>
                            City
                          </Typography>
                      )} */}
                        {/* {selectedState && ( */}
                        <FormControl fullWidth sx={{ minWidth: "256px" }}>
                          <Select
                            id="couponId"
                            name="couponId"
                            label="Select Coupon"
                            input={<OutlinedInput className={"disabledValue"} notched label={"Select Coupon"} />}
                            value={selectedCoupon}
                            onChange={handleSimple}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  bgcolor: "black",
                                  "& .MuiMenuItem-root": {
                                    padding: 1,
                                    fontFamily: "Ubuntu",
                                    "font-style": "normal",
                                    "font-weight": 400,
                                    fontSize: "14px",
                                    minWidth: "256px",
                                  },
                                },
                              },
                            }}
                          >
                            {/* <MenuItem value="select" disabled>Select</MenuItem> */}
                            {promotionList && promotionList.length > 0 ? (
                              promotionList.map((coupon: any) => (
                                <MenuItem key={coupon.coupanId} value={coupon.coupanId}>
                                  {coupon.couponCode}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No offer available</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                        {/* )} */}
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} sx={{ pt: 5 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{ p: "10px 39px" }}
                          onClick={handleFormSubmit}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Item>
                  </Grid>
                </Item>
                <Item>
                  <Grid item sx={{ width: "100%", overflowX: "auto" }}>
                    <MantineProvider
                      withGlobalStyles
                      withNormalizeCSS
                      theme={{
                        breakpoints: {
                          xs: "30em",
                          sm: "48em",
                          md: "64em",
                          lg: "74em",
                          xl: "90em",
                        },
                        fontSizes: { xs: "12px", md: "14px" },
                        colorScheme: "dark",
                      }}
                    >
                      {FriendsQuery?.data ?
                        <MantineReactTable
                          columns={columns}
                          data={FriendsQuery.data || []}
                          positionPagination="top"
                          initialState={{ showColumnFilters: true }}
                          positionToolbarAlertBanner="bottom"
                        /> :
                        <div className="circular-progress1"><div className="circle"></div><div className="mask full"><div className="fill"></div></div><div className="mask half"><div className="fill"></div><div className="fill fix"></div></div></div>}
                    </MantineProvider>
                  </Grid>
                </Item>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
