import {
  Box,
  Stack,
  IconButton,
  Typography,
  Paper,
  Divider,
  Button,
} from "@mui/material";
import { CustomModelLotteryPool } from "@/components/Elements/custom-modalLotteryPool";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { theme } from "@/components/Elements/theme";
import powerBoll from "../../../assets/powerball.png";
import powerBallJackpot from "../../../assets/Powerball Jackpot.gif"
interface jackpotModalProps {
  open: boolean;
  onClose: () => void;
  handleStartJackpotDraw: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(4),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.9)",
    borderRadius: "20px",
    maxWidth: 1200,
    margin: "auto",
  },
  first: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 467,
  },
  second: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 150,
  },
  availableTickets: {
    border: "0.5px solid rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
    height: 110,
  },
  noOfTickets: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    height: 180,
  },
  winnerModalImg: {
    [theme.breakpoints.down("sm")]: {
      height: "60px ! important",
      width: "60px !important",
    },
  },
  tableHeader: {
    color: "#fff",
  },
  tableData: {
    fontSize: "14px",
    color: "#fff",
    opacity: "0.6",
    borderBottom: "1px solid #0D0D39",
  },
  minimumTicket: {
    background: "#3C37FF",
    borderRadius: "10px",
    color: "#fffff",
  },
  logo: {
    width: 50,
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
    [theme.breakpoints.down("sm")]: {
      minHeight: "200px",
      minWidth: "200px",
    },
  },
  substract: {
    border: "1px solid #ffff !important",
    borderRadius: "10px !important",
    color: "#ffff",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  ticketPrize: {
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
    padding: "30px",
  },
  ticketContainer: {
    width: "134px",
    height: "60px",
    background: "#FFFF",
    borderRadius: "10px",
    position: "relative",
  },
  detailContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "14px",
    padding: "12.86px",
  },
  detailLabel: {
    height: "11px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "11px",
    color: "rgba(27, 28, 45, 0.6)",
    margin: "0",
  },
  detailNumber: {
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "0",
  },
  detailTime: {
    width: "82px",
    height: "16px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#1B1C2D",
    margin: "0",
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundImage: "linear-gradient(180deg, #3b97bb 0%, #aed1db 99%)",
      boxShadow: "inset 2px 2px 5px 0 rgba(#fff, 0.5)",
      borderRadius: "100px",
    },
  },
  jackpotContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    height: "38px",
    background: "rgba(60, 55, 255, 0.15)",
    marginBottom: "14px",
  },
  jackpotText: {
    width: "99px",
    height: "23px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.3636px",
    lineHeight: "23px",
    letterSpacing: "0.2em",
    textTransform: "uppercase",
    color: "#27272F",
    margin: "auto",
  },
  arrowImage: {
    position: "absolute",
    width: "30px",
    height: "30px",
    left: "74%",
    transform: "translateX(-50%)",
  },
  polyup: {
    top: "-15px",
    left: "86%",
  },
  polydown: {
    bottom: "-15px",
    transform: "rotate(180deg)",
  },
  modalTitle: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#ccebef",
    marginBottom: "20px",
    "@media screen and (max-width:992px)": {
      fontSize: "28px",
    },
    "@media screen and (max-width:767px)": {
      fontSize: "22px",
    },
  },
  modalSubTitle: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#ffffff",
    marginBottom: "30px",
  },
  startButton: {
    whiteSpace: "nowrap",
    padding: "22px 35px",
    fontSize: "22px",
    color: "#fff",
    backgroundColor: "#3c97bb",
    borderRadius: "10px",
    transition: "all 0.3s ease",
    "@media screen and (max-width:992px)": {
      padding: "18px 25px",
      fontSize: "18px",
    },
    "&:hover": {
      backgroundColor: "#3297bb",
      transform: "scale(1.05)",
    },
  },
}));

const JackpotModal = ({
  open,
  onClose,
  handleStartJackpotDraw,
}: jackpotModalProps) => {
  const classes = useStyles();

  return (
    <CustomModelLotteryPool
      open={open}
      onClose={onClose}
      childComponent={
        <>
          <Paper className={classes.paperClass}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ p: 0 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                {/* <Box
                  sx={{
                    padding: "0px 25px",
                    borderRadius: "5px",
                    background: "#12131b",
                    display: "flex",
                    flexFlow: "row wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                    [theme.breakpoints.down("lg")]: {
                      gap: "0px 20px",
                      padding: "10px 25px",
                    },
                  }}
                >
                  <Typography
                    component="p"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "40px",
                      gap: "1px",
                      "& .MuiTypography-body1": {
                        fontSize: "50px ",
                        fontFamily: "serif",
                        fontWeight: "bold",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "30px !important",
                        },
                      },
                    }}
                  >
                    <Typography sx={{ color: " #d32f2f" }}>J</Typography>
                    <Typography sx={{ color: "#7b1fa2 " }}>a</Typography>
                    <Typography sx={{ color: "#388e3c " }}>c</Typography>
                    <Typography sx={{ color: "#f57c00 " }}>k</Typography>
                    <Typography sx={{ color: "#880e4f " }}>p</Typography>
                    <Typography
                      sx={{
                        alignSelf: "bottom",
                        marginTop: "12px",
                        marginInline: "3px",
                        "& img": {
                          [theme.breakpoints.down("md")]: {
                            maxWidth: "20px !important",
                          },
                        },
                        [theme.breakpoints.down("md")]: {
                          marginTop: "8px",
                        },
                      }}
                    >
                      <img
                        style={{ width: "auto", maxWidth: "30px" }}
                        src={powerBoll}
                        alt="powerBoll"
                      />
                    </Typography>
                    <Typography sx={{ color: "#fbc02d " }}>t</Typography>
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "40px",
                      gap: "1px",
                      "& .MuiTypography-body1": {
                        fontSize: "50px ",
                        lineHeight: "1",
                        fontFamily: "serif",
                        fontWeight: "bold",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "30px !important",
                        },
                      },
                    }}
                  >
                    <Typography sx={{ color: " #d32f2f" }}>R</Typography>
                    <Typography sx={{ color: "#7b1fa2 " }}>o</Typography>
                    <Typography sx={{ color: "#388e3c " }}>u</Typography>
                    <Typography sx={{ color: "#f57c00 " }}>n</Typography>
                    <Typography sx={{ color: "#880e4f " }}>d</Typography>
                  </Typography>
                </Box> */}
                <img
              src={powerBallJackpot}
              alt="Powerball Jackpot "
              loading="lazy"
              style={{
                width: "100%",
                maxWidth: "400px",
                objectFit: "contain"
              }}
            />

              </Box>

              <IconButton
                className={classes.startButton}
                onClick={() => handleStartJackpotDraw()}
              >
                <span>"START"</span>
              </IconButton>
            </Stack>
          </Paper>
        </>
      }
    />
  );
};

export default JackpotModal;
