import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

import { Wallet } from '../types';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export type DepositCryptoPaymentDTO = {
  data: {
        amount:string
  };
};

export const DepositCryptoPayment= ({ data }: DepositCryptoPaymentDTO): Promise<Wallet> => {
  return axios.post('/api/v1/user/depositBalanceInWallet', data);
};

type UseDepositCryptoPaymentOptions = {
    amount: string;
  config?: MutationConfig<typeof DepositCryptoPayment>;
};

export const useDepositCryptoPayment = ({ config, amount }: UseDepositCryptoPaymentOptions) => {

  return useMutation({
    onMutate: async (newWallet) => {
      await queryClient.cancelQueries(['DepositCryptoAmount', amount]);

      const previousWallet = queryClient.getQueryData<Comment[]>(['DepositCryptoAmount', amount]);

      queryClient.setQueryData(
        ['DepositCryptoAmount', amount],
        [...(previousWallet || []), newWallet.data]
      );

      return { previousWallet };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWallet) {
        queryClient.setQueryData(['DepositCryptoAmount', amount], context.previousWallet);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['DepositCryptoAmount', amount]);
      Swal.fire({
        icon:"success",
        background:"black",
        color:"#ffff",
        text:"Amout deposited",
      });
    },
    ...config,
    mutationFn: DepositCryptoPayment,
  });
};