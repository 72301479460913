import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    FormControl,
    InputLabel,
    Stack,
    Typography,
    Divider,
    CardMedia,
    Button,
    Icon,
    InputAdornment,
    TextField,
    OutlinedInput,
  } from "@mui/material";
  
  import { Grid, Select, MenuItem } from "@mui/material";
  import { useState } from "react";
  import { styled } from "@mui/material/styles";
  import { theme } from "@/components/Elements/theme";
  import cryptoPayments from "@/assets/cryptoPayments.svg";
  import allPayments from "@/assets/allPayments.svg";
import { MyLottery } from "./MyLottery";
import MyPayments from "./MyPayments";
import '../../wallet/components/wallet.css';
  
  export const HistoryMain = () => {
    const [HistoryData, setHistoryData] = useState("MyLottery");
    const [Finance, setFinance] = useState("Deposit");
    const [Profile, setProfile] = useState("Setting");
  
    const handleChangeFinance = (event) => {
      setFinance(event.target.value);
    };
  
    const handleChangeProfile = (event) => {

      setProfile(event.target.value);
    };
    const handleChangeHistory = (event) => {
    
      setHistoryData(event.target.value);
    };
  
    const Item = styled(Paper)(({ theme }) => ({
      background: "transparent",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    }));
  
    var cardStyle = {
      display: "block",
      transitionDuration: "0.3s",
      height: "492px",
      background: "#050518",
      boxShadow: "0px 4px 50px 30px rgba(181, 181, 181, 0.05)",
      borderRadius: "20px",
    };
  
    var center = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    var content = {
      color: "#ffff",
      backgroundColor: "#050518",
      borderRadius: "12px",
    };
  
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <Select
              labelId="finance-Data"
              disabled
              value={Finance}
              input={<OutlinedInput className={"disabledValue"} notched label={"Finance"} />} 
              label="Finance"
              onChange={handleChangeFinance}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: "black",
                    "& .MuiMenuItem-root": {
                      padding: 1,
                      fontFamily: "Ubuntu",
                      "font-style": "normal",
                      "font-weight": 400,
                      fontSize: "14px",
                    },
                  },
                },
              }}
            >
                <MenuItem value="Deposit">Deposit</MenuItem>
                <MenuItem value="Withdraw">Withdraw</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <Select
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: "black",
                  "& .MuiMenuItem-root": {
                    padding: 1,
                    fontFamily: "Ubuntu",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                  },
                },
              },
            }}
            input={<OutlinedInput notched className={"ActiveValue"} label={"History"} sx={{color:"#fffff"}}/>} 
            value={HistoryData}
            label="History"
            inputProps={{
              name: "history",
              id: "history",
            }}
            onChange={handleChangeHistory}
          >
                
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
            <Select
          disabled
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: "black",
                  "& .MuiMenuItem-root": {
                    padding: 1,
                    fontFamily: "Ubuntu",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                  },
                },
              },
            }}
            input={<OutlinedInput notched className={"disabledValue"} label={"Profile"} sx={{color:"#fffff"}}/>} 
            value={Profile}
            label="Profile"
            inputProps={{
              name: "profile",
              id: "profile",
            }}
            onChange={handleChangeProfile}
          >
            <MenuItem value="Setting">Setting</MenuItem>
            <MenuItem value="Account Verifications">
              Account Verifications
            </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        {HistoryData === "MyLottery"?<MyLottery/>:""}
        {HistoryData === "MyTransaction"?<MyLottery/>:""}
        {HistoryData === "MyPayments"?<MyPayments/>:""}
      </Box>
    );
  };
  