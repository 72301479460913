import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid } from "@mui/material";
import { theme } from "@/components/Elements/theme";
import HomeScreenImg from "@/assets/earth.jpg";
import Header from "@/components/Elements/header";
import HeaderValue from "@/components/Elements/headerMain";
import Footer from "@/components/Elements/footer";
import { useAuth } from "@/lib/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },
  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
  content: {
    // maxWidth: "700px",
    color: "white",
    textAlign: "start",
    "& h1, & h2": {
      color: "#3C37FF",
    },
    "& p, & ul, & ol": {
      color: "white",
    },
    "& ul, & ol": {
      margin: "10px 0",
      paddingLeft: "20px",
    },
    "& ul ul, & ul ol, & ol ul, & ol ol": {
      paddingLeft: "20px",
    },
  },
  sizee: {
    fontSize: "14px",
    opacity: "0.7",
    fontWeight: 400,
    lineHeight: "150%",
    color: "white",
  },
}));

export default function ResponsibleGaming() {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      {auth.user ? <Header /> : <HeaderValue />}
      <Box
        sx={{
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          padding: 0,
          pt: "70px",
          pb:"40px",
        }}
      >
        <Box
          sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
        />
        <Box sx={{ p: { xs: "0 15px",sm:"0 30px", md: "50px" } }}>
          <Box
            sx={{
              backgroundColor: "rgba(5, 5, 24, 0.8)",
              borderRadius: "10px",
              display:"flex",
              flexFlow:"column",
              marginTop: "40px", 
              p: {xs: "15px",sm:"30px",md:"64px"}
            }}
          >
             <h1 style={{ fontSize: "23px", fontWeight: 500,color: "#3C37FF", marginTop:"0px" }}>Responsible Gaming</h1>
            <Grid
                container
                display={"flex"}
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <Card sx={{ margin: "0 auto", background: "none" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // maxWidth: "920px",
                        height: "500px",
                        margin: "auto",
                        overflow: "auto",
                      }}
                    >
                      <div className={classes.content}>
                       
                        <p className={classes.sizee}>
                          Site Name is here to provide an excellent and enjoyable gaming experience and recognize our responsibility in preventing problematic activity. We advise all players to take into account the following, and not game irresponsibly:
                        </p>
                        <ul>
                          <li className={classes.sizee}>Play for entertainment, not to make money.</li>
                          <li className={classes.sizee}>Avoid chasing losses.</li>
                          <li className={classes.sizee}>Establish limits for yourself.</li>
                          <li className={classes.sizee}>Do not let gambling interfere with your daily responsibilities.</li>
                          <li className={classes.sizee}>Never gamble unless you can cover losses.</li>
                          <li className={classes.sizee}>Take breaks.</li>
                        </ul>

                        <p className={classes.sizee}>
                          See the below questions. If your answer to the majority of them is “YES”, we advise you take action to prevent gambling from negatively impacting your life:
                        </p>
                        <ul>
                          <li className={classes.sizee}>Does gambling affect your work?</li>
                          <li className={classes.sizee}>Has gambling caused arguments with family/friends?</li>
                          <li className={classes.sizee}>Do you always return to win back your losses?</li>
                          <li className={classes.sizee}>Have you borrowed money to gamble?</li>
                          <li className={classes.sizee}>Do you see gambling as a source of income?</li>
                          <li className={classes.sizee}>Do you find it difficult to limit your gambling?</li>
                        </ul>

                        <h2 className={classes.sizee}>What to do?</h2>
                        <p className={classes.sizee}>
                          Listed below are reputed organizations committed to helping those who struggle with gambling problems, and can be contacted at any time:
                        </p>
                        <ul>
                          <li className={classes.sizee}> <a href="https://www.gamblersanonymous.org/ga/" style={{ color: "#3C37FF",textDecoration: "none"  }}>Gamblers Anonymous</a></li>
                          <li className={classes.sizee}> <a href="https://www.gamblingtherapy.org/" style={{ color: "#3C37FF",textDecoration: "none"  }}>Gambling Therapy</a></li>
                          <li className={classes.sizee}> <a href="https://www.gamcare.org.uk/" style={{ color: "#3C37FF",textDecoration: "none"  }}>GamCare</a></li>
                         
                        </ul>

                        <h2 className={classes.sizee}>How we can help</h2>
                        <p className={classes.sizee}>
                          We advise all players who are concerned about their gambling behavior to take a break by excluding themselves from their gaming account. Self-exclusion will lock your account for a minimum of 6 months and no promotional material will be sent.
                        </p>
                        <p className={classes.sizee}>
                          Contact our experienced Customer Support team at any time to request this and they will kindly assist you. A 7 day cooling off period is also available. We recommend that you contact all other gambling sites where you have an account and request self-exclusion there also.
                        </p>

                        <h2 className={classes.sizee}>Underage gambling</h2>
                        <p className={classes.sizee}>
                          Players must be of legal gambling age in their jurisdiction (at least 18+) in order to play at Site Name. It is their responsibility to be aware of the age restriction where they reside and play, and to confirm their legitimacy when creating an account at Site Name. We also advise parents to do the following:
                        </p>
                        <ul>
                          <li className={classes.sizee}>Password protect computer, mobile, and/or tablet.</li>
                          <li className={classes.sizee}>Do not leave device unattended when logged into your account.</li>
                          <li className={classes.sizee}>Make sure all account details and credit cards are inaccessible to children.</li>
                          <li className={classes.sizee}>Do not save passwords on your computer, write them down and keep somewhere out of reach.</li>
                          <li className={classes.sizee}>Download filtering software (e.g.<a href="https://www.netnanny.com/" style={{ color: "#3C37FF",textDecoration: "none"  }}> Net Nanny</a>) to prevent minors from accessing inappropriate sites.</li>
                        </ul>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
