import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { termsList } from '../types';

export const getLicenseList = async (): Promise<termsList[]> => {
  const response = await axios.get(`/api/v1/user/userLicencing`, {
})
  //return response?.data;
   const data = response.data;
  return data;
};

type QueryFnType = typeof getLicenseList;

type UseLicenseListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLicenseList = ({  config }: UseLicenseListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getLicenseList(),
    ...config,
  });
};