import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { TopHeadingLayout } from "@/components/layout/TopHeadingLayout";
import promotionsBackground from "@/assets/promotionsBackground.png";
import promotionCard from "@/assets/promotionCard.svg";
import promotionFirst from "@/assets/promotionFirst.png";
import promotionSecond from "@/assets/promotionSecond.png";
import promotionimg from "@/assets/promotionimg.png";
import { theme } from "@/components/Elements/theme";
import { url } from "inspector";
import HomeScreenImg from "@/assets/earth.jpg";

import Countdown from "react-countdown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  paperClass: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "rgba(5, 5, 24, 0.8)",
    borderRadius: "10px",
  },

  myGridItem: {
    backgroundColor: "rgba(5, 5, 24, 0.8)",
    padding: "30px",
    borderRadius: "20px",
  },
  innerGrid: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridFirst: {
    background: `url(${promotionFirst})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "left bottom",
  },
  innerGridSecond: {
    backgroundColor: "#050518",
    padding: "10px",
    borderRadius: "20px",
  },
  innerGridThird: {
    background: `url(${promotionSecond})`,
    backgroundRepeat: "no-repeat",
    height: "auto",
    width: "auto",
    minHeight: "282px",
    minWidth: "282px",
    backgroundPosition: "center",
  },
  cardRoot: {
    background: "rgba(5, 5, 24, 0.25)",
    boxShadow: "0px 2.81226px 28.1226px 7.03066px rgba(0, 0, 0, 0.03)",
    borderRadius: "10px",
  },
}));

const cardData = [
  {
    title: "Biggest Welcome Cashback2Go!",
    description:
      "Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!",
    media: promotionCard,
  },
  {
    title: "Biggest Welcome Cashback2Go!",
    description:
      "Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!",
    media: promotionCard,
  },
  {
    title: "Biggest Welcome Cashback2Go!",
    description:
      "Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!",
    media: promotionCard,
  },
  {
    title: "Biggest Welcome Cashback2Go!",
    description:
      "Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!",
    media: promotionCard,
  },
  {
    title: "Biggest Welcome Cashback2Go!",
    description:
      "Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!",
    media: promotionCard,
  },
  {
    title: "Biggest Welcome Cashback2Go!",
    description:
      "Claim up to 35% and 1000$/€ in No Rules Cashback on your first deposit. No Wagering - No Rules!",
    media: promotionCard,
  },
];

export default function PromotionsView() {
  const classes = useStyles();

  return (
    <>
      <Box
        sx={{
          background: `linear-gradient(89.41deg, #1B1C2D 7.81%, rgba(27, 28, 45, 0) 38.09%), linear-gradient(1.53deg, #1B1C2D 13.32%, rgba(27, 28, 45, 0) 50.41%), linear-gradient(46.49deg, #1B1C2D 14.7%, rgba(27, 28, 45, 0) 43.39%), url(${HomeScreenImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100%",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          padding: 0,
        }}
      >
        <Box
          sx={{ backgroundColor: "rgba(5, 5, 24, 0.8)", borderRadius: "10px" }}
        />
        <Box sx={{ p: { xs: "34px", md: "50px" } }}>
          <Grid
            container
            display={"flex"}
            direction={"column"}
            // marginTop={"60px"}
            sx={{
              backgroundColor: "rgba(5, 5, 24, 0.8)",
              borderRadius: "10px",
            }}
          >
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Grid
                container
                display={"flex"}
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ marginBottom: "40px", p: theme.spacing(1) }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card sx={{ margin: "0 auto", background: "none" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth:"920px",
                        height:"500px",
                        margin:"auto",
                        overflow:"auto",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: "700px",
                          color: "white",
                          textAlign: "start",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "35px",
                            fontWeight: 700,
                            lineHeight: "130%",
                            color: "#3C37FF",
                            textAlign:"center",
                            textDecoration:"underline",
                          }}
                        >
                          Terms and Conditions
                        </p>
                        <h1 style={{ fontSize: "23px", fontWeight: 500,color: "#3C37FF" }}>
                        Conditions : 1
                        </h1>
                        <p
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                          <h1 style={{ fontSize: "16px", fontWeight: 600, }}>
                          Label
                        </h1>
                          <ul>
                            <li>
                                Term 1
                            </li>
                            <li>
                                Term 2
                            </li>
                            <li>
                                Term 3
                            </li>
                            <li>
                                Term 4
                            </li>
                          </ul>
                        </p>
                   
                        <h1 style={{ fontSize: "23px", fontWeight: 500,color: "#3C37FF", }}>
                        Conditions : 2
                        </h1>
                        <p
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                        <h1 style={{ fontSize: "23px", fontWeight: 500,color: "#3C37FF", }}>
                        Conditions : 3
                        </h1>
                        <p
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged.
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            opacity: "0.7",
                            fontWeight: 400,
                            lineHeight: "150%",
                          }}
                        >
                          It was popularised in the 1960s with the release of
                          Letraset sheets containing Lorem Ipsum passages, and
                          more recently with desktop publishing software like
                          Aldus PageMaker including versions of Lorem Ipsum.
                        </p>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
