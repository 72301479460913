import { axios } from '@/lib/axios';
import { useQuery } from 'react-query';
import { promotionResponse } from '../types';
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '@/lib/react-query';


export const getPromotionByBuy = async (): Promise<promotionResponse> => {
  const response = await axios.get(`/api/v1/user/buyPromotionList`, {
  })
    //return response?.data;
     const data = response.data;
    return data;
}



type QueryFnType = typeof getPromotionByBuy;

type UsePromotionByBuyListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const UsePromotionByBuyList = ({  config }: UsePromotionByBuyListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['data'],
    queryFn: () => getPromotionByBuy(),
    ...config,
  });
};