import * as React from "react";
import * as z from "zod";
import { useAuth } from "@/lib/auth";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  TextField,
  Checkbox,
  Box,
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import inActiveEye from "@/assets/inActiveEye.svg";
import blueEye from "@/assets/blueEye.svg";
import { resetPasswordCredentialsDTO, resetPassword } from "../api/resetPassword"
import { InputAdornment } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import storage from '@/utils/storage';
import Swal from "sweetalert2";

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type ResetPasswordValues = {
  newPassword: string;
  confirmPassword: string;
};

type ResetPasswordProps = {
  onSuccess: () => void;
};

const initialValues: ResetPasswordValues = {
  newPassword: "",
  confirmPassword: ""
};

export const ResetPasswordForm = ({ onSuccess }: ResetPasswordProps) => {
  const { register, isRegistering } = useAuth();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  storage.setResetToken(token);
  const [values, setValues] = React.useState({
    inActivePassword: false
  });
  const [Icon, setIcon] = React.useState("");
  const [DownIcon, setDownIcon] = React.useState("");
  const [formValues, setFormValues] = useState<ResetPasswordValues>(initialValues);
  const [currentPassword, setCurrentPassword] = useState("");


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleContinue = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const passData = await resetPassword(formValues)
    Swal.fire(
      'Success',
      passData.message,
      'success'
    )
    navigate('/auth/login')
    //setisOpen(true);
  };


  return (
    <>
      <Typography component="h1" variant="h1" sx={{ mt: 5 }}>
        Reset Password
      </Typography>
      <Typography component="h6" variant="h6">
        Please enter new password.
      </Typography>
      <Divider sx={{ mt: 2, border: "1px solid rgba(255, 255, 255, 0.2)" }} />
      <Box component="form" noValidate sx={{ mt: 3.25 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
            <FormControl fullWidth={true}>
              <InputLabel
                htmlFor="password"
                shrink={true}
                sx={{ color: "#FFFFFF" }}
              >
                New Password
              </InputLabel>

              <TextField
                margin="normal"
                required
                fullWidth
                id="newPassword"
                name="newPassword"
                onChange={handleChange}
                value={formValues.newPassword}
                autoComplete="password"
                type="password"
                autoFocus
                placeholder="Enter your Password"
                onFocus={(e) => { setIcon(blueEye) }}
                onBlur={(e) => { setIcon(inActiveEye) }}
                InputProps={{
                  endAdornment:
                    <InputAdornment disableTypography position="end">
                      <img src={Icon} alt="inactiveEye" /></InputAdornment>,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ pt: 2 }}>
            <FormControl fullWidth={true}>
              <InputLabel
                htmlFor="password"
                shrink={true}
                sx={{ color: "#FFFFFF" }}
              >
                Confirm Password
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="password"
                onChange={handleChange}
                value={formValues.confirmPassword}
                onFocus={(e) => { setDownIcon(blueEye) }}
                onBlur={(e) => { setDownIcon(inActiveEye) }}
                InputProps={{
                  endAdornment:
                    <InputAdornment disableTypography position="end">
                      <img src={DownIcon} alt="inactiveEye" /></InputAdornment>,
                }}
                autoFocus={false}
                placeholder="Re-enter your Password"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <Checkbox value="remember" disableFocusRipple={true} disableTouchRipple={true} sx={{ px: 0 }}
              icon={<CheckBoxOutlineBlankIcon htmlColor="#FFFEEC99" />}
              checkedIcon={<CheckBoxIcon htmlColor="#3C37FF" />} />Remember me
          </Grid>
        </Grid>
        <Button variant="contained" onClick={(e) => handleContinue(e)} sx={{ mt: 3, mb: 2 }}>
          Reset
        </Button>
      </Box>
    </>
  );
};

