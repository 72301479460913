import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { wallet } from './types/index';

export const getWalletAmount = (): Promise<wallet> => {
  return axios.get(`/api/v1/user/fetchUserWalletBalance`, {
  });
};

type QueryFnType = typeof getWalletAmount;

type UseLotteryByIdOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useWalletAmount = ({ config }: UseLotteryByIdOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryFn: () => getWalletAmount(),
    ...config,
  });
};