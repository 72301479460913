import { Close } from '@mui/icons-material';
import { Box, Stack, IconButton, Typography } from '@mui/material';
import { CustomModel } from "@/components/Elements/custom-modal";
import openMail from "@/assets/closedMail.svg";
import closeModal from "@/assets/closeModal.svg";
interface forgotPasswordProps {
    open: boolean;
    onClose: () => void;
  };



const ForgetPasswordModal = ({ open, onClose }:forgotPasswordProps) => {
  return (
    <CustomModel
        open={open}
        onClose={onClose}
        childComponent={
          <>
            <Stack
              direction='row'
              height='100%'
              sx={{p:0}}
            >
              <Box sx={{ width: '100%', overflow: 'auto', mt:"50px"}} display='flex' flexDirection='column' alignItems='center'>
              <img src={openMail} alt="mailIcon" style={{textAlign:"center"}} width="60px" height="60px"/>
                <Typography component="h6" variant="h6" sx={{mt:"20px", mb:"50px", textAlign:"center" }}>
                A verification link will be sent to your registered <br/>
                    email address.
      </Typography>
              </Box>
              <Box>
              <IconButton sx={{padding:"25px"}} onClick={onClose}>
                <img src ={closeModal}/>
              </IconButton>
              </Box>
            </Stack>
          </>
        }
    />
  );
};

export default ForgetPasswordModal;
