import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { investedCoins } from '../types';

export const getInvestedCoins = async (): Promise<investedCoins> => {
  return await axios.get(`/api/v1/user/getInvestmentMervCoin`, {
  });
};

type QueryFnType = typeof getInvestedCoins;

type UseLotteryByIdOptions = {
    config?: QueryConfig<QueryFnType>;
  };
  
export const useInvestedCoins = ({  config }: UseLotteryByIdOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryFn: () => getInvestedCoins(),
    queryKey: ['count'],
    ...config,
  });
};