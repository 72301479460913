export const allData = [
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale2",
    parentId: "123",
    campaignType: "Inapp",
    status: "Failed",
    channel: "iOS",
    action: ":",
  },
  {
    name: "Sale34",
    parentId: "45",
    campaignType: "Email",
    status: "sent",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
  {
    name: "Sale",
    parentId: "12",
    campaignType: "Push",
    status: "Failed",
    channel: "android",
    action: ":",
  },
];
