import { useNavigate } from 'react-router-dom';
import { DashboardMain } from '../components/dashboardMain';




export const Dashboard = () => {
  const navigate = useNavigate();

  return (
      <DashboardMain />
  );
};