import styled from 'styled-components';


interface WheelCanvasStyleProps {
  widthPercentage: number
  heightPercentage: number
  fontSize:number
}

export const WheelCanvasStyle = styled.canvas<WheelCanvasStyleProps>`
  width: ${({ widthPercentage }) => `${widthPercentage}%`};
  height: ${({ heightPercentage }) => `${heightPercentage}%`};
  font-size: ${({ fontSize }) => `${fontSize}`}
`;
