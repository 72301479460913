import { Box, Fade, Modal } from "@mui/material";
import React from "react";
import { theme } from "./theme";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: { xs:  "translate(-50%, -50%)", sm:  "translate(-50%, -50%)", md:  "translate(-50%, -50%)", lg:  "translate(-14%, -64%)" },
    maxWidth: { xs: '90%', sm: '383px', md: '529px', lg: '529px' },
    width: '100%',
    height: 'auto',
    bgcolor: "#050518",
    p: 0,
    my: 2,
    borderRadius: "8px",
    overflow: 'auto',
    border: '1px solid black'
};

interface iModalProps {
    open: boolean;
    onClose: () => void;
    childComponent: any;
};

export const CustomModel = ({
    open,
    onClose,
    childComponent
}: iModalProps) => {
    return (
        <Modal closeAfterTransition open={open}>
            <Fade in={open}>
                <Box sx={style}>
                    {childComponent}
                </Box>
            </Fade>
        </Modal>
    )
}
